const firstGoalsSection = {
  name: 'Vision, resources and impact - Continued p3',
  elements: [
    {
      type: 'radiogroup',
      name: 'Budget',
      title: '1. Do you know your L&D budget for the current financial year?',
      hideNumber: true,
      choices: [
        'I know it exactly',
        'I know it approximately',
        'I don\'t know it'
      ]
    },
    {
      type: 'radiogroup',
      name: 'Budget Organisation',
      visibleIf: '{Budget} anyof ["I know it exactly", "I know it approximately"] and {Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      title: '1a. What is your organisation’s budget for L&D? (in {currency})',
      hideNumber: true,
      choices: [
        'We don\'t have a budget',
        '1K-10K',
        '10K-50K',
        '50K-100K',
        '100K-500K',
        '500K-1M',
        '1M+'
      ]
    },
    {
      type: 'customslider',
      name: 'Digital Spend',
      title: '1b. What percentage of your budget is spent on digital learning?',
      hideNumber: true,
      visibleIf: '{Budget} anyof ["I know it exactly", "I know it approximately"] and {Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      rangeStart: 0,
      rangeMin: 0,
      rangeMax: 100
    },
    {
      type: 'radiogroup',
      name: 'Size of Department',
      title: '1c. How many people are in your L&D team?',
      hideNumber: true,
      visibleIf: '{Budget} anyof ["I know it exactly", "I know it approximately"] and {Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      choices: [
        '< 3',
        '4-10',
        '11-20',
        '21-30',
        '30+'
      ]
    }
  ]
}

export default firstGoalsSection
