import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

// Store
export const register = createSlice({
  name: reducerName,
  initialState: {
    username: '',
    isRegistered: false
  },
  reducers: {
    setRegisterData: (state, { payload }) => {
      state.username = payload.username
      state.isRegistered = payload.isRegistered
      state.isPremium = payload.isPremium
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setRegisterData
} = register.actions

// Reducer
export default register.reducer
