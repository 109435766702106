const personalSection = {
  name: 'You and your organization',
  elements: [
    {
      type: 'text',
      name: 'question23',
      hideNumber: true,
      title: '1. What is your job title?'
    },
    {
      type: 'dropdown',
      name: 'Role',
      hideNumber: true,
      title: '2. What is your primary role?',
      isRequired: true,
      choices: [
        'Business leader / senior manager (not L&D/HR)',
        'Senior manager - HR',
        'Senior manager - L&D overall',
        'L&D strategy and management',
        'Organisation Development',
        'Content development',
        'Classroom learning / training delivery',
        'Learning administration',
        'Technology specialist',
        'Learning consultant',
        'Performance consultant',
        'Other'
      ]
    },
    {
      type: 'text',
      name: 'Role Name',
      hideNumber: true,
      visibleIf: '{Role} anyof ["Other"]',
      title: '2a. Please specify'
    },
    {
      type: 'text',
      name: 'Organisation',
      hideNumber: true,
      title: '3. What is the name of your organisation?'
    },
    {
      type: 'radiogroup',
      name: 'Size',
      title: '4. What is the size of your organisation?',
      hideNumber: true,
      isRequired: true,
      choices: [
        '1 - 9',
        '10 - 249',
        '250 - 499',
        '500 - 999',
        '1000 - 4999',
        '5000 - 19999',
        '20000+'
      ]
    },
    {
      type: 'radiogroup',
      name: 'Region',
      title: '5. Where is your organisation\'s head office?',
      hideNumber: true,
      isRequired: true,
      choices: [
        'UK',
        'Other European countries',
        'USA/Canada',
        'Australia/New Zealand',
        'Other Asia Pacific',
        'Middle East/Africa/India',
        'South/Central America'
      ]
    },
    {
      type: 'radiogroup',
      name: 'Spread',
      isRequired: true,
      title: '6. What is your organisation\'s geographical spread?',
      hideNumber: true,
      choices: [
        'Single site',
        'Multiple locations - one nation',
        'Mostly single nation with some global distribution ',
        'Multinational - highly distributed with multiple locations across the globe'
      ]
    },
    {
      type: 'radiogroup',
      name: 'Sector',
      title: '7. In which sector is your organisation\'s primary business?',
      hideNumber: true,
      choices: [
        'Private sector',
        'Public sector',
        'Not-for-profit sector'
      ]
    },
    {
      type: 'dropdown',
      name: 'Industry',
      isRequired: true,
      title: '8. In which industry is your organisation\'s primary business?',
      hideNumber: true,
      choices: [
        'Central government',
        'Local government',
        'IT and Telecoms',
        'Professional services, law and accountancy',
        'Finance, banking and insurance',
        'Health',
        'Social care/housing association',
        'Other charity/voluntary sector',
        'Retail',
        'Engineering',
        'Manufacturing',
        'Pharmaceutical',
        'Transport',
        'Utilities',
        'Hospitality',
        'Education (HE, FE)',
        'Commercial training/e-learning supply',
        'Consulting',
        'Art, media and design',
        'Other'
      ]
    },
    {
      type: 'text',
      name: 'Industry Name',
      hideNumber: true,
      visibleIf: '{Industry} anyof ["Other"]',
      title: '8a. Please specify'
    }
  ]
}

export default personalSection
