import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    background: '#EFEEF3',
    outline: '90px solid #EFEEF3'
  },
  header: {
    '@media (min-width:1200px)': {
      height: 110
    }
  }
}))
