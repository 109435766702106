import React, { useRef } from 'react'
import { View, Canvas } from '@react-pdf/renderer'
import styles from './style'

const drawChart = (painterObject, score) => {
  painterObject.arc = function (x, y, radius, startAngle, endAngle, anticlockwise) {
    // decrease start/end angle by pi/2 radians to start at 12 o'clock
    startAngle -= Math.PI / 2
    endAngle -= Math.PI / 2
    const startX = x + radius * Math.cos(startAngle)
    const startY = y + radius * Math.sin(startAngle)
    const endX = x + radius * Math.cos(endAngle)
    const endY = y + radius * Math.sin(endAngle)
    const arcAngle = endAngle - startAngle
    const largeArc = (arcAngle > Math.PI) ^ (startAngle > endAngle) ^ anticlockwise
    const path =
        'M ' + startX + ',' + startY +
        ' A ' + radius + ',' + radius +
        ' 0 ' + (largeArc ? '1' : '0') + ',' + (anticlockwise ? '0' : '1') +
        ' ' + endX + ',' + endY
    return this.path(path)
  }
  const endAngle = Math.PI * 2 * (score / 9)
  painterObject.fontSize(18)
  painterObject.text(score.toFixed(2), 10, 20)
  painterObject.fontSize(12)
  painterObject.text('out of 9', 0, 40)
  painterObject.circle(20, 40, 40).lineWidth(5).strokeColor('#E9EAEB', 1).stroke()
  painterObject.arc(20, 40, 46, 0, endAngle, false).lineWidth(5).strokeColor('#FF8200', 1).stroke()
}
const CircularChart = ({ score }) => {
  const ref = useRef(null)

  return (
    <View style={styles.container}>
      <Canvas
        ref={ref}
        paint={
          painterObject => drawChart(painterObject, score)
        }
      />
    </View>
  )
}
export default CircularChart
