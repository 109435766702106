import React from 'react'
import {
  Dialog,
  Typography,
  Button
} from '@material-ui/core'
import { useStyles } from './style'

const CloseTeamReviewDialog = ({
  open,
  handleClose,
  handleDeny
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <Typography variant='h3' className={classes.header}>Are you sure you want to close the review?</Typography>
        <Typography className={classes.text}><b>Closed reviews cannot be reopened.</b> If a team member completes the review after closing, their results won't be added to the overall team aggregate</Typography>
        <Typography>Once you`ve closed the review, don`t forget to notify everyone that they can view the team results!</Typography>
        <div className={classes.buttons}>
          <Button className='Close_Review_Modal_No' variant='outlined' color='secondary' onClick={handleDeny}>No</Button>
          <Button className={`${classes.rightButton} Close_Review_Modal_Yes`} variant='contained' color='secondary' onClick={handleClose}>Yes, close review</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default CloseTeamReviewDialog
