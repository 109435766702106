import React from 'react'
import { Accordion, Typography, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './style'

const TeamFAQ = () => {
  const classes = useStyles()

  return (
    <span className={classes.faqBox}>
      <Typography variant='h2'>FAQs</Typography>
      <div className={classes.accordionBox}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='faq1-content'
            id='faq1-header'
            className='ILP_FAQ_1'
          >
            <Typography className={classes.emphasis}>What happens if a user has already completed their review for that season?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>When a team member with a completed review signs in via their invite link, they’re shown a message asking them if they would like to continue using their completed results, or if they would instead like to clear those results and take the review again. Whichever option they choose, their results are still counted as part of the team’s aggregated results.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='faq2-content'
            id='faq2-header'
            className='ILP_FAQ_2'
          >
            <Typography className={classes.emphasis}>Are the team results anonymous?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Each team member’s individual results are shown in the aggregated results. These results are all anonymous, meaning neither the team members nor the admin can see whose results are whose.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='faq3-content'
            id='faq3-header'
            className='ILP_FAQ_3'
          >
            <Typography className={classes.emphasis}>How will I be charged?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>There are no setup fees, monthly fees, minimums or hidden charges. It’s just a one-off investment of £3,500 per season. Once the season is over, you’ll have the option of renewing your team account for the next season. However, this will not be automatically charged.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='faq4-content'
            id='faq4-header'
            className='ILP_FAQ_4'
          >
            <Typography className={classes.emphasis}>What payment methods are accepted?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>The team account is a prepaid online service. Everything is paid for upfront and before use by credit or debit card. If you require an invoice to pay, email lpbenchmark@mindtools.com.<br /><br />Unfortunately, we currently can’t support purchase orders or payments by PayPal.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='faq5-content'
            id='faq5-header'
            className='ILP_FAQ_5'
          >
            <Typography className={classes.emphasis}>What currency will I be billed in?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Subscriptions will be billed in US dollars, euros, Canadian dollars, Australian dollars, Indian Rupees or pounds sterling.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='faq6-content'
            id='faq6-header'
            className='ILP_FAQ_6'
          >
            <Typography className={classes.emphasis}>What payment system do you use? Is it secure?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>The LPB uses Recurly, a secure and well-established credit card payment gateway service.</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </span>
  )
}

export default TeamFAQ
