import React from 'react'
import {
  CircularScoreCard,
  LinearScoreCard,
  DashboardTakeaway,
  DashboardReading,
  DashboardBehaviourSection,
  TeamComponentScoreCard
} from '../../components'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { useStyles } from './style'
import { componentTakeawayTexts } from '../../helpers/utils'

const takeaways = [
  {
    type: 'benchmark',
    score: 1.8,
    text:
      'the average for researching the impact of emerging technologies on learning'
  },
  {
    type: 'industry',
    score: 3.6,
    text: 'your industry average for providing micro-content when appropriate'
  },
  {
    type: 'size',
    score: -2.5,
    text:
      'the average for your organisation size for managing your learning resources to help people find their information they need'
  }
]

const DashboardAreaPanel = ({ title, keys, score, readings, image, profile, behaviours, isTeam, members, userLoggedIn }) => {
  const classes = useStyles()
  const currentSeason = score[0].values[0].date
  const matchesLg = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  takeaways.forEach(takeaway => {
    const userScore = score[0].values[0].score
    if (takeaway.type === 'benchmark') {
      const benchmarkScore = score[1].values.find(value => value.date === currentSeason).score
      takeaway.score = (userScore - benchmarkScore).toFixed(2)
    }
    if (takeaway.type === 'industry') {
      const industryScore = score[2].values.find(value => value.date === currentSeason).score
      takeaway.score = (userScore - industryScore).toFixed(2)
    }
    if (takeaway.type === 'size') {
      const sizeScore = score[3].values.find(value => value.date === currentSeason).score
      takeaway.score = (userScore - sizeScore).toFixed(2)
    }
    takeaway.text = componentTakeawayTexts[title][takeaway.type]
  })

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={8}>
        <Typography variant='h2'>{title}</Typography>
        <Typography variant='h3'>Key questions to consider</Typography>
        <ol>
          {keys &&
            keys.length &&
            keys.map((key, index) => (
              <li key={index}>
                <Typography>{key}</Typography>
              </li>
            ))}
        </ol>
      </Grid>
      <Grid item xs={1} style={{ display: matchesLg ? 'none' : '' }} />
      <Grid item xs={3} lg={4}>
        <div className={classes.img}>
          <img src={image} alt='' />
        </div>
      </Grid>
      {isTeam && (
        <Grid item md={10} lg={10} className={classes.chartsContainer}>
          <TeamComponentScoreCard currentSeason={currentSeason} title={title} members={members} userLoggedIn={userLoggedIn} />
        </Grid>
      )}
      <Grid item md={4} lg={4} className={classes.chartsContainer}>
        <CircularScoreCard title={title} score={score} maxScore={9} industry={profile.industry} isTeam={isTeam} />
      </Grid>
      <Grid item md={6} lg={6} className={classes.chartsContainer}>
        <LinearScoreCard type='components' scores={score} profile={profile} isTeam={isTeam} />
      </Grid>
      <Grid item xs={12} classes={{ root: classes.takeaways }}>
        <Typography variant='body1' className={classes.takeawaysTitle}>Quick takeaways</Typography>
        <Typography variant='body1'>
          The six key components are measured on a scale of 0-9, and are
          revisited annually to ensure statistical validity and reliability.
        </Typography>
        <Grid alignItems='center' container spacing={2}>
          {takeaways.map((takeaway, idx) => (
            <Grid item xs={12} sm={6} md={4} key={idx}>
              <DashboardTakeaway
                score={takeaway.score}
                text={takeaway.text}
                type={takeaway.type}
                idx={idx}
                isTeam={isTeam}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DashboardBehaviourSection behaviours={behaviours} season={currentSeason} isTeam={isTeam} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h2'>Further reading</Typography>
        <Grid container spacing={3}>
          {readings.map((reading, idx) => (
            <Grid item xs={12} sm={12} md={4} lg={4} key={idx}>
              <DashboardReading
                title={reading.title}
                text={reading.description}
                idx={idx}
                color={reading.color}
                image={reading.img}
                link={reading.link}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DashboardAreaPanel
