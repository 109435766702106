import { StyleSheet, Font } from '@react-pdf/renderer'
import Matter from '../../../../fonts/Matter-Regular.ttf'

Font.register({ family: 'Matter', src: Matter })

const styles = StyleSheet.create({
  card: {
    width: '100%',
    border: '1px solid #E9EAEB',
    marginVertical: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row'

  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '5%',
    objectFit: 'contain'

  },
  image: {
    objectFit: 'contain'
  },
  infoTextBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    marginHorizontal: 5
  },
  progressTextBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginHorizontal: 5,
    marginBottom: 5,
    marginTop: 5
  }
})

export default styles
