import { StyleSheet, Font } from '@react-pdf/renderer'
import Matter from '../../../../fonts/Matter-Regular.ttf'

Font.register({ family: 'Matter', src: Matter })

const styles = StyleSheet.create({
  blueBox: {
    display: 'block',
    backgroundColor: '#006fb7',
    borderRadius: '5px',
    paddingLeft: '10px',
    paddingRight: '5px',
    paddingTop: '10px',
    paddingBottom: '10px'
  }
})

export default styles
