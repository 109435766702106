const environmentSection = {
  name: 'Challenges p4',
  elements: [
    {
      type: 'customrating',

      name: 'Environment_1',
      title: 'Product demand is hard to forecast',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',

      name: 'Environment_2',
      title: 'Customer requirements and preferences are hard to forecast',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',

      name: 'Environment_3',
      title: 'My industry is very unstable with huge change resulting from major economic, technological, social, or political forces',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default environmentSection
