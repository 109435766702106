export const steps = [
  {
    step: 1,
    header: 'Add your team',
    description: [
      'Simply enter 2–19 email addresses, hit Submit, and you`ll generate an invite for your team to complete their reviews.'
    ],
    hasButton: true
  },
  {
    step: 2,
    header: 'Team complete their reviews',
    description: [
      'This involves using the same questions as the free version. Each team member has their own login so they can complete the review at their own pace, at any time that suits them.'
    ]
  },
  {
    step: 3,
    header: 'Close the review',
    description: [
      'Once you’re ready, close the review to reveal the aggregated score to you and your team.',
      'Make sure everyone you want to complete the review has done so, as it can’t be reopened.'
    ]
  },
  {
    step: 4,
    header: 'View team results',
    description: [
      'Time to discover how aligned you all are! Don’t forget to tell the rest of the team the results are available to view.'
    ]
  }
]
