import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  loadText: {
    margin: '4px 0'
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '120px 0 0'
  },
  loadImg: {
    margin: '40px 0'
  },
  progress: {
    width: '40%',
    margin: '40px 0'
  },
  loadFooter: {
    marginTop: '190px'
  }
}))
