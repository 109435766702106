import * as actions from './slice'
import * as teamActions from '../team/slice'

export const createUserData = {
  eventName: 'createUserData',
  onSuccess: [{ redux: true, action: actions.setUserData }],
  onError: [{ redux: true, action: actions.createUserDataError }]
}

export const getUserData = {
  eventName: 'getUserData',
  onSuccess: [{ redux: true, action: actions.setUserData }],
  onError: [{ redux: true, action: actions.getUserDataError }]
}

export const sendContactEmail = {
  eventName: 'sendContactEmail',
  onSuccess: [],
  onError: [{ redux: true, action: actions.sendContactEmailError }]
}

export const updateUserDataOnRegister = {
  eventName: 'updateUserDataOnRegister',
  onSuccess: [],
  onError: [{ redux: true, action: actions.updateUserDataOnRegisterError }]
}

export const getFeatureToggles = {
  eventName: 'getFeatureToggles',
  onSuccess: [{ redux: true, action: actions.setFeatureToggles }],
  onError: []
}

export const devUpdateUser = {
  eventName: 'devUpdateUser',
  onSuccess: [{ redux: true, action: actions.setUpdatedUserData }],
  onError: []
}

export const validateSubscription = {
  eventName: 'validateSubscription',
  onSuccess: [{ redux: true, action: actions.setSubscriptionValidated }],
  onError: [{ redux: true, action: actions.setSubscriptionError }]
}

export const updateUserFirstLogin = {
  eventName: 'updateUserFirstLogin',
  onSuccess: [{ redux: true, action: actions.setUserData }],
  onError: [{ redux: true, action: actions.updateUserFirstLoginError }]
}

export const updateUserTeam = {
  eventName: 'updateUserTeam',
  onSuccess: [{ redux: true, action: teamActions.setTeam }, { redux: true, action: actions.setUser }],
  onError: [{ redux: true, action: actions.updateUserTeamError }]
}

export const getInvoicesInfo = {
  eventName: 'getInvoiceInfo',
  onSuccess: [{ redux: true, action: actions.setInvoices }],
  onError: [{ redux: true, action: actions.setInvoicesError }]
}

export const getInvoicePDF = {
  eventName: 'getInvoicePDF',
  onSuccess: [{ redux: true, action: actions.setInvoicePDF }],
  onError: [{ redux: true, action: actions.setInvoicePDFError }]
}
