import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'

import { AppToolbar, LPBLink, MailtoLink } from '../../components'
import { useStyles } from './style'

const TermsAndConditions = () => {
  const classes = useStyles()

  const ScrollToTopOnMount = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return null
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <AppToolbar />
      <Grid
        className={classes.root}
        container
        direction='column'
        justify='space-between'
        alignItems='flex-start'
      >
        <Grid item xs={8}>
          <Typography className={classes.headTitle} variant='h1'>
            Terms of Use
          </Typography>
          <Typography className={classes.headContent} variant='body1'>
            Unless otherwise stated, definitions contained in the{' '}
            <LPBLink
              className={classes.linkLPB}
              target='_blank'
              rel='noopener noreferrer'
              to='/terms-of-sale'
            >
              Terms of Sale
            </LPBLink>{' '}
            shall apply in respect of these Terms of Use (terms). These terms
            apply to all users of the LPB. Please read them carefully because by
            accessing the LPB you are agreeing to abide by these terms. We may
            make changes to these terms from time to time, which will be
            published on the Website.
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Intellectual Property Rights
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            All of the content of the LPB is the copyright of Mind Tools Ltd trading as Mind Tools (Mind Tools) or is reproduced with
            permission from the copyright owner. Associated logos and/or any other names or logos of products or
            services provided by Mind Tools and referred to in the LPB are
            trademarks of Mind Tools, its group companies, or its licensors.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Nothing in these terms shall in any way exclude, modify or affect
            any of your statutory rights under intellectual property laws. Mind
            Tools reserves all intellectual property rights in the LPB. The
            intellectual property rights in the LPB and in any text, images,
            video, audio or other multimedia content, software or other
            information, data or material submitted to or accessible from the
            LPB are owned by us and our licensors. Mind Tools gives you a
            non-exclusive, non-transferrable license to use the LPB for the
            Season within which the Order is placed, and, insofar as is
            required, any permitted access to the LPB after the expiry of such
            Season. You may print off one copy, and may download extracts, of
            any page(s) of the LPB for your personal use, and you may draw the
            attention of others within your organization to the LPB. You must
            not modify the paper or digital copies of any materials you have
            printed off or downloaded in any way, and you must not use any
            illustrations, photographs, video or audio sequences or any graphics
            separately from any accompanying text. You agree not to adjust, try
            to circumvent or delete any notices contained in the LPB (including
            any intellectual property notices) and in particular, in any digital
            rights or other security technology embedded or contained within the
            LPB. All rights not expressly granted to you by these terms are
            reserved to Mind Tools.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Registration and Access
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you choose, or you are provided with, a user identification code,
            password or any other piece of information as part of our security
            procedures, you must treat such information as confidential. You
            must not disclose it to any third party.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We have the right to disable any user identification code or
            password, whether chosen by you or allocated by us, at any time, if
            in our reasonable opinion you have failed to comply with any of the
            provisions of these terms. If you know or suspect that anyone other
            than you knows your user identification code or password, you must
            promptly notify us at{' '}
            <MailtoLink email='lpbenchmark@mindtools.com'>
              lpbenchmark@mindtools.com
            </MailtoLink>
            .
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            To view and access the LPB, your device needs to comply with the
            following minimal technical requirements: you need to have the
            latest version of one of the following web browsers:
          </Typography>
          <ul>
            <li className={classes.paragraph}>Chrome</li>
            <li className={classes.paragraph}>Firefox</li>
            <li className={classes.paragraph}>Safari</li>
            <li className={classes.paragraph}>Edge</li>
          </ul>
          <Typography className={classes.paragraph} variant='body1'>
            PDF versions of any reports created from the LPB can be accessed via
            the built-in PDF viewers in each of these web browsers. Please read
            this section carefully as you are responsible for making sure that
            your device meets these requirements.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Permitted Uses
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The LPB may be used for lawful non-commercial use only.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The LPB may not otherwise be copied, modified, published, broadcast
            or otherwise distributed without the prior written permission of
            Mind Tools.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Prohibited Uses
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You may not use the LPB:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              in any way that breaches any applicable local, national or
              international law or regulation;
            </li>
            <li className={classes.paragraph}>
              in any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect;
            </li>
            <li className={classes.paragraph}>
              for the purpose of harming or attempting to harm minors in any
              way;
            </li>
            <li className={classes.paragraph}>
              to send, knowingly receive, upload, download, use or re-use any
              material which does not comply with our content standards;
            </li>
            <li className={classes.paragraph}>
              to transmit, or procure the sending of, any unsolicited or
              unauthorized advertising or promotional material or any other form
              of similar solicitation (spam); or
            </li>
            <li className={classes.paragraph}>
              to knowingly transmit any data, send or upload any material that
              contains viruses, Trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware.
            </li>
          </ul>
          <Typography className={classes.paragraph} variant='body1'>
            You also agree:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              not to reproduce, duplicate, copy or re-sell any part of the LPB
              in contravention of the provisions of our terms; or
            </li>
            <li className={classes.paragraph}>
              not to access without authority, interfere with, damage or
              disrupt:
              <ul>
                <li className={classes.paragraph}>any part of the LPB;</li>
                <li className={classes.paragraph}>
                  any equipment or network on which the LPB is stored;
                </li>
                <li className={classes.paragraph}>
                  any software used in the provision of the LPB; or
                </li>
                <li className={classes.paragraph}>
                  any equipment or network or software owned or used by any
                  third party.
                </li>
              </ul>
            </li>
          </ul>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Service Access
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            While we use reasonable efforts to ensure that the LPB is available
            24 hours a day. Mind Tools shall have no liability if for any reason
            the LPB is unavailable at any time or for any period. Access to the
            LPB may be suspended temporarily and without notice in the case of
            system failure, maintenance or repair or for reasons beyond our
            control.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            User Generated Content
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            As part of the LPB, we may allow users to post or otherwise input
            their own material, data and/or information (Material). Such
            Material does not necessarily reflect the views of Mind Tools. By
            posting, you represent that:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              you have all necessary rights in and to such Material and that
              such Material will not infringe any personal or proprietary rights
              of any third parties;
            </li>
            <li className={classes.paragraph}>
              such Material will not corrupt or otherwise be technically harmful
              to the LPB: and
            </li>
            <li className={classes.paragraph}>
              such Material will not be defamatory, unlawful, threatening,
              obscene, in breach of confidence, or in breach of privacy.
            </li>
          </ul>
          <Typography className={classes.paragraph} variant='body1'>
            You warrant that any contribution you make complies with these
            terms, and you will be liable to us and indemnify us for any breach
            of that warranty. If you are a consumer user, this means you will be
            responsible for any loss or damage we suffer as a result of your
            breach of warranty.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Any Material you upload will be considered non-confidential and
            non-proprietary, and we have the right to use, copy, distribute and
            disclose to third parties any such content for any purpose. We have
            the right to disclose your identity to any third party who is
            claiming that any Material posted or uploaded by you to our site
            constitutes a violation of their intellectual property rights, or of
            their right to privacy.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will not be responsible, or liable to any third party, for the
            content or accuracy of any content posted by you or any other user
            of the LPB.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The views expressed by other users on the LPB do not represent our
            views or values.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Mind Tools reserves the right, at its sole discretion, to review,
            edit or delete any Material posted by users that it deems
            inappropriate or in breach of these terms.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Removal Of Access
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Mind Tools reserves the right to block your access to the LPB in the
            event that you do not comply with these terms.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Disclaimer
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Mind Tools makes no warranties or representations of any kind
            concerning the accuracy or suitability of the content for any
            purpose, or that the LPB will be uninterrupted or error free. All
            content is provided “as is'.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The content in the LPB is provided for general information only. It
            is not intended to amount to advice on which you should rely. You
            must obtain professional or specialist advice before taking, or
            refraining from, any action on the basis of the content contained in
            the LPB.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Mind Tools will have no liability for any special, exemplary,
            indirect or consequential loss, costs, damages, charges or other
            expenses of any kind (including loss of profits or revenues, loss of
            business, depletion of goodwill, loss of anticipated savings, loss
            of contract or business interruption) arising under or in connection
            with use or inability to use the LPB.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Liability for personal injury or death caused by negligence or for
            fraud is not excluded or limited and the exclusions set out in this
            legal notice only apply to the extent permitted by law.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Governing Law
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            These terms are governed by the laws of England and Wales and shall
            be subject to the exclusive jurisdiction of the courts of England
            and Wales, provided that Mind Tools is entitled to apply to any
            court to bring an action for the infringement of its intellectual
            property rights.
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default TermsAndConditions
