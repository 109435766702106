import React from 'react'
import { Typography, Link, Paper } from '@material-ui/core'
import { useStyles } from './style'

const SkipNavigation = ({ isMobileScreen, openNavBar, closeNavBar, toggleMenu, navBarOpen, hideHamburger }) => {
  const classes = useStyles()
  const [isFocused, setIsFocused] = React.useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const checkEnter = (e) => {
    if (isMobileScreen) {
      return e.key === 'Enter'
    }
    return false
  }

  const handleNavBar = () => {
    if (navBarOpen) return openNavBar()

    return closeNavBar()
  }

  return (
    <div className={isFocused ? classes.rootFocused : classes.root} onFocus={handleFocus} onBlur={handleBlur}>
      <Paper elevation={3} className={classes.paper} tabindex='-1'>

        <Typography variant='body1' className={classes.headerText} tabindex='-1'>Jump to:</Typography>

        <Link
          onFocus={handleFocus}
          href='#nav-menu'
          tabindex='1'
          className={classes.link}
          onKeyDown={(e) => checkEnter(e) ? toggleMenu() : null}
        >
          Top navigation
        </Link>

        {
          !hideHamburger
            ? (
              <Link
                onFocus={handleFocus}
                href='#left-nav-drawer'
                tabindex='2'
                className={classes.link}
                onKeyDown={(e) => checkEnter(e)
                  ? (handleNavBar())
                  : null}
              >
                Side menu
              </Link>)
            : ''
        }
        <Link
          onFocus={handleFocus}
          href='#main'
          tabindex='3'
          className={classes.link}
        >
          Main content
        </Link>
      </Paper>
    </div>
  )
}

export default React.memo(SkipNavigation)
