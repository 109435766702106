import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'
import { datadogRum } from '@datadog/browser-rum'

// Store
export const dashboard = createSlice({
  name: reducerName,
  initialState: {
    page: Number(localStorage.getItem('surveyPageState')) || 0,
    innerPage: localStorage.getItem('surveyInnerPageState') || '',
    responses: null,
    stepsProgress: null,
    percentageCompleted: 0,
    results: null,
    profle: null,
    isSaving: false,
    resultsLoading: true,
    calculatingResults: false,
    resultsCleaned: null,
    responsesError: false,
    responsesErrorCount: 0,
    userReportError: false,
    userReportUploaded: false,
    userReportUrl: null,
    signedUrlForReport: null,
    signedUrlForReportError: false,
    PDFDownloading: false,
    updateResultsSuccess: false,
    sampleReportUrl: '',
    sampleReportUrlError: false,
    seasonCSV: null,
    seasonCSVError: false
  },
  reducers: {
    setScreenSize: (state, { payload }) => {
      state.isLGScreen = payload.isLGScreen
      state.isXSScreen = payload.isXSScreen
      state.isMDScreen = payload.isMDScreen
    },
    saveSurveyResponsesError: (state, _) => {
      state.saveError = true
      datadogRum.addTiming('save_responses_error')
      datadogRum.addError(new Error('Error: saveSurveyResponses'))
    },
    submitSurveyError: () => {
      datadogRum.addTiming('submit_survey_error')
      datadogRum.addError(new Error('Error: submitSurvey'))
    },
    setResultsError: () => {
      datadogRum.addTiming('get_results_error')
      datadogRum.addError(new Error('Error: getResults'))
    },
    setReportUrlError: () => {
      datadogRum.addTiming('get_report_error')
      datadogRum.addError(new Error('Error: getReportUrl'))
    },
    setIsSaving: (state, { payload }) => {
      state.isSaving = payload
    },
    setPage: (state, { payload }) => {
      state.page = payload
      localStorage.setItem('surveyPageState', Number(payload))
    },
    setInnerPage: (state, { payload }) => {
      state.innerPage = payload
      localStorage.setItem('surveyInnerPageState', payload)
    },
    setSurveyFeedback: (state, { payload }) => {
      state.saveError = false
      if (payload !== null) {
        state.stepsProgress = payload.stepsProgress
        state.unansweredProfileQuestions = payload.missingProfile
        state.percentageCompleted = payload.percentageCompleted
        state.results = undefined
        state.profile = undefined
      }
    },
    setResponsesError: (state, payload) => {
      if (state.responsesErrorCount > 1) {
        state.responsesError = true
        datadogRum.addTiming('responses_loaded_error')
        datadogRum.addError(new Error('Error: retrieverUserResponses'))
      }
      state.responsesErrorCount += 1
    },
    setResponses: (state, { payload }) => {
      datadogRum.addTiming('responses_loaded_success')
      state.responsesError = false
      if (payload !== null) {
        state.unansweredProfileQuestions = payload.missingProfile
        state.responses = payload.responses
        state.stepsProgress = payload.stepsProgress
        state.percentageCompleted = payload.percentageCompleted
      }
    },
    startGetResults: (state) => {
      state.resultsLoading = true
    },
    startSubmitSurvey: (state) => {
      state.calculatingResults = true
    },
    setResults: (state, { payload }) => {
      datadogRum.addTiming('results_loaded')
      state.resultsLoading = false
      if (payload) {
        state.results = payload.results
        state.profile = payload.profile
        state.latest = payload.latest
      }
    },
    setChartData: (state, { payload }) => {
      datadogRum.addTiming('chart_data_loaded_success')
      state.chartData = payload
      state.calculatingResults = false
      state.chartDataError = false
    },
    setChartDataError: (state) => {
      datadogRum.addTiming('chart_data_loaded_error')
      datadogRum.addError(new Error('Error: retrieverChartData'))
      state.chartDataError = true
    },
    setReportUrl: (state, { payload }) => {
      datadogRum.addTiming('report_url_loaded')

      const [reportUrl, ...links] = payload
      state.reportUrl = reportUrl
      // userReportUrl
      const userReportUrl = links.pop()
      state.userReportUrl = userReportUrl
      state.readingsUrl = links
    },
    setResultsClean: (state, { payload }) => {
      if (payload) {
        state.resultsCleaned = payload.cleaned
        state.results = payload.results
        state.profile = null
        state.latest = null
        state.responses = []
        state.stepsProgress = payload.stepsProgress
      }
    },
    setUserCSV: (state, { payload }) => {
      state.seasonCSV = payload
    },
    setUserCSVError: (state, _) => {
      state.seasonCSV = null
      state.seasonCSVError = true
    },
    setSampleReportUrl: (state, { payload }) => {
      state.sampleReportUrl = payload
      state.sampleReportUrlError = false
    },
    setEmailSaved: (state, { payload }) => {
      state.emailSaved = payload
    },
    setUserReportUploaded: (state, { payload }) => {
      state.userReportUploaded = payload
    },
    setUserReportError: (state, _) => {
      state.userReportError = true
    },
    setUserReportUrl: (state, { payload }) => {
      state.userReportUrl = payload
    },
    setSignedUrlForReport: (state, { payload }) => {
      state.signedUrlForReport = payload
    },
    setSignedUrlForReportError: (state, _) => {
      state.signedUrlForReportError = true
    },
    setPDFDownloading: (state, { payload }) => {
      state.PDFDownloading = payload
    },
    setUpdateResultsSuccess: (state, { payload }) => {
      state.updateResultsSuccess = true
    },
    getSampleReportError: (state, _) => {
      state.sampleReportUrlError = true
    },
    saveReportDownloadError: (state, _) => {
      state.emailSaved = false
      state.emailSavedError = true
    },
    resetEmailSaved: (state, _) => {
      state.emailSaved = false
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setPage,
  setInnerPage,
  setResponses,
  setResponsesError,
  startGetResults,
  startSubmitSurvey,
  setResults,
  setIsSaving,
  setChartData,
  setChartDataError,
  setSurveyFeedback,
  setReportUrl,
  saveSurveyResponsesError,
  submitSurveyError,
  setResultsError,
  setReportUrlError,
  setScreenSize,
  setResultsClean,
  setUserReportUploaded,
  setUserReportError,
  setUserReportUrl,
  setSignedUrlForReport,
  setSignedUrlForReportError,
  setPDFDownloading,
  setUpdateResultsSuccess,
  setSampleReportUrl,
  getSampleReportError,
  setEmailSaved,
  saveReportDownloadError,
  resetEmailSaved,
  setUserCSV,
  setUserCSVError
} = dashboard.actions

// Reducer
export default dashboard.reducer
