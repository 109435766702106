const firstImproveSection = {
  name: 'Strategy in practice and culture - Continued p16',
  elements: [
    {
      type: 'customrating',
      name: 'Improve_1',
      title: '96. We provide learners with real-time feedback on progress',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Improve_2',
      title: '97. We routinely collect information on the extent to which learning has been understood',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Improve_3',
      title: '98. We routinely collect information from employees on the extent to which learning has been applied at work',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Improve_4',
      title: '99. We routinely collect information from managers on the extent to which learning has been applied at work',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Improve_5',
      title: '100. We use detailed analysis to personalise feedback to learners',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Improve_16',
      title: '101. We encourage peer-to-peer feedback to support continual improvement',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Improve_6',
      title: '102. We measure specific organisational metrics when evaluating the effectiveness of learning',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default firstImproveSection
