import TextField from '@material-ui/core/TextField'
import Slider from '@material-ui/core/Slider'
import Container from '@material-ui/core/Container'
import React, { useState } from 'react'
import { useStyles } from './style'

export const CustomSlider = ({ min, max, start, percent }) => {
  const classes = useStyles()
  const isPercentage = percent === true ? '%' : ''

  const getMarks = (min, max) => {
    return [
      { value: min, label: `${min}${isPercentage}` },
      { value: (max / 4), label: `${(max / 4)}` },
      { value: (2 * max / 4), label: `${(2 * max / 4)}` },
      { value: (3 * max / 4), label: `${(3 * max / 4)}` },
      { value: max, label: `${max}${isPercentage}` }
    ]
  }

  const handleValueChange = (e) => {
    e.persist()
    const regex = /[^0-9]/
    if (!regex.test(e.target.value)) {
      setValue(e.target.value)
    }
  }

  const marks = getMarks(min, max)
  const [value, setValue] = useState(start || 0)

  return (
    <Container className={classes.sliderContainer}>
      <TextField
        onChange={(e) => handleValueChange(e)}
        variant='outlined'
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', 'aria-label': 'text input for slider' }}
        value={Number(value)}
        className={classes.sliderInput}
      />
      <Slider
        className={classes.slider}
        step={1}
        marks={marks}
        value={Number(value)}
        min={min}
        max={max}
        defaultValue={start}
        aria-label='Slider for question'
        valueLabelDisplay='off'
        onChange={(e, value) => { setValue(value) }}
      />
    </Container>
  )
}
