const firstInfluenceSection = {
  name: 'Strategy in practice and culture - Continued p4',
  elements: [
    {
      type: 'customrating',
      name: 'Influence_1',
      title: '23. We know what digital solutions to learning our IT can deliver',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Influence_2',
      title: '24. We have good relationships with several organisational support functions (e.g., IT, Finance, Marketing)',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'InfluenceCulture_3',
      title: '25. We draw on internal expertise to support learning (e.g., marketing, data analytics experts)',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableDesign_2',
      title: '26. We have clear processes for working with internal subject matter experts',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'InfluenceCulture_7',
      title: '27. Succession planning is integrated into how we develop our employees',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'InfluenceCulture_8',
      title: '28. Digital learning is integrated into our induction and onboarding processes',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default firstInfluenceSection
