import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { MailtoLink } from '../../components'
import { useStyles } from './style'

const RegisterFooter = () => {
  const classes = useStyles()

  return (
    <>
      <Grid container justify='center' alignItems='center'>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <footer className={classes.footer}>
            <Typography className={classes.copyright} variant='caption' data-test='footer-rightsNote'>
              © 2023 Mind Tools Ltd. All rights reserved. Having trouble? Email us at{' '}
              <MailtoLink data-test='supportEmail' email='lpbenchmark@mindtools.com' />
            </Typography>
          </footer>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  )
}

export default RegisterFooter
