import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import styles from './style'
import globalStyles from '../../style'

const KeyBehaviours = ({ behaviours, season }) => {
  return (
    <View style={styles.behavioursContainer}>
      <Text style={[globalStyles.subheading, { marginBottom: 5 }]}>Key behaviours to be proud of</Text>
      <Text style={globalStyles.body}>Compared to the average for {season}, you're doing great in the following areas:</Text>
      {behaviours.map((behaviour, index) => {
        return (
          <View key={index} style={styles.behaviourCard}>
            <Text style={globalStyles.body}>{behaviour.user} out of 9 for</Text>
            <Text style={[globalStyles.body, globalStyles.bold]}>{`"${behaviour.text}"`}</Text>
            <Text style={globalStyles.body}>{`(vs ${season} average of ${behaviour.benchmark})`}</Text>
          </View>
        )
      })}
    </View>
  )
}

export default KeyBehaviours
