import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'
import { datadogRum } from '@datadog/browser-rum'

// Store
export const team = createSlice({
  name: reducerName,
  initialState: {
    teamInformation: {},
    team: null,
    teamId: null,
    members: [],
    teamInvitationStatus: null
  },
  reducers: {
    setTeamInfo: (state, { payload }) => {
      state.team = payload.team
      state.teamInformation = {
        type: payload.type,
        completed: payload.completed
      }
    },
    setTeamInfoError: (_, { payload }) => {
      datadogRum.addTiming('save_team_information_error')
      datadogRum.addError(new Error('Error: saveTeamInformation'))
    },
    setTeam: (state, { payload }) => {
      if (payload) {
        state.teamId = payload.team
        state.members = payload.members
        state.status = payload.status
        state.team = payload
      }
    },
    setTeamError: (_, { payload }) => {
      datadogRum.addTiming('get_team')
      datadogRum.addError(new Error('Error: getTeam'))
    },
    removeTeamMemberError: (_, { payload }) => {
      console.log('removeTeamMemberError', payload)
      datadogRum.addTiming('remove_team_member_error')
      datadogRum.addError(new Error('Error: removeTeamMember'))
    },
    closeTeamReviewError: (_, { payload }) => {
      console.log('closeTeamReviewError', payload)
      datadogRum.addTiming('close_team_review_error')
      datadogRum.addError(new Error('Error: closeTeamReviewError'))
    },
    addTeamMembersError: (_, { payload }) => {
      console.log('addTeamMembersError', payload)
      datadogRum.addTiming('add_team_members_error')
      datadogRum.addError(new Error('Error: addTeamMembersError'))
    },
    setTeamScoresChartData: (state, { payload }) => {
      state.teamChartData = payload
      state.teamCalculatingResults = false
      state.teamChartDataError = false
    },
    setTeamScoresChartDataError: (state, { payload }) => {
      console.log('setTeamScoresChartDataError', payload)
      datadogRum.addTiming('set_team_scores_chart_data_error')
      datadogRum.addError(new Error('Error: setTeamScoresChartDataError'))
      state.teamChartDataError = true
    },
    // LPB-1510
    setTeamInvitationStatus: (state, { payload }) => {
      state.teamInvitationStatus = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setTeamInfo,
  setTeamInfoError,
  setTeam,
  setTeamError,
  removeTeamMemberError,
  closeTeamReviewError,
  addTeamMembersError,
  setTeamScoresChartData,
  setTeamScoresChartDataError,
  // LPB-1510
  setTeamInvitationStatus
} = team.actions

// Reducer
export default team.reducer
