import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    '& .MuiTextField-root': {
      '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
        float: 'left'
      }
    }
  },
  formInput: {
    marginBottom: 20
  },
  loginText: {
    marginTop: 24
  },
  primaryButton: {
    backgroundColor: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s'
    },
    '&.Mui-disabled': {
      backgroundColor: '#ACB3B9',
      color: '#EFF0F2'
    }
  }
}))
