import React from 'react'
import { Grid, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { MailtoLink, LPBLink } from '../../components'
import CheckIcon from '@material-ui/icons/Check'
import { useStyles } from './style'

const WhyUpgradeComponent = ({ handleUpgrade }) => {
  const classes = useStyles()

  return (
    <Grid item lg={6} md={12}>
      <Typography variant='h2' style={{ 'margin-bottom': '40px' }}> Why should you upgrade to a teams account?</Typography>
      <Typography variant='h3'>Everything the free LPB offers, plus:</Typography>
      <List>
        <ListItem>
          <ListItemIcon className={classes.li2}>
            <CheckIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='body1'>1 admin and up to 19 team members under one account</Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.li2}>
            <CheckIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='body1'>Combined average maturity score for the whole team</Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.li2}>
            <CheckIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='body1'>See anonymised colleague's scores simultaneously</Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.li2}>
            <CheckIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='body1'>Aggregated team results you can share with business stakeholders</Typography>
          </ListItemText>
        </ListItem>
      </List>
      <div className={classes.upgradeBox}>
        <Typography variant='body1' style={{ 'margin-bottom': '16px' }}>Pay now: <b>£3,500</b> (one off payment)</Typography>
        <Button className='ILP_Pay_CTA_2' onClick={handleUpgrade} variant='contained' color='secondary'>Upgrade to a team account</Button>
        <Typography className={classes.helper}>NOTE: You will be redirected to a secure external payment system. Alternatively, if you require an invoice to pay, email <MailtoLink email='lpbenchmark@mindtools.com'>lpbenchmark@mindtools.com</MailtoLink></Typography>
      </div>
      <Typography>For more information, read our <LPBLink to='/terms-and-conditions' target='_blank'>terms & conditions.</LPBLink></Typography>
    </Grid>
  )
}

export default WhyUpgradeComponent
