import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    transition: '0.2s all ease',
    margin: 0
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 -24px'

  },
  pointer: {
    '& :hover': {
      cursor: 'pointer'
    }
  },
  dropdownContainer: {
    width: '244px',
    '&:focus-visible': {
      outline: '1px solid rgba(0, 111, 183, 1) ',
      borderRadius: '5px'
    }
  },

  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Matter',
    fontWeight: 700,
    lineHeight: '22px',
    color: 'rgba(0, 111, 183, 1)'
  },
  dropdownArrow: {
    fontWeight: 700,
    padding: 0,
    color: 'rgba(0, 111, 183, 1)',
    marginRight: '15px',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    },
    '&:focus-visible': {
      outline: '1px solid rgba(0, 111, 183, 1) ',
      borderRadius: '5px'
    }
  },
  dropdownText: {
    fontFamily: 'Matter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: 'rgba(37, 55, 70, 1)'
  }
}))
