import { Grid, Paper, Typography, Button } from '@material-ui/core'
import { useStyles } from './style'
import { userSlice } from '../../../reducers'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import React from 'react'
const TeamsCard = ({ teamsInfo }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { teamAdmin, teamResults, hasTeam } = teamsInfo
  const getCardDescription = (teamAdmin, teamResults) => {
    if (teamAdmin && !hasTeam) return 'Simply add your team members, let them complete their reviews, and discover how aligned you all are!'
    if (!teamResults) return 'Your team admin needs to close the review before you can see your team\'s aggregated results. Once they\'re available, you\'ll be able to click through to see how aligned your team are, and what areas need working on.'
    return 'Your aggregated team results are available. Click through to see how aligned your team is, and what areas need working on.'
  }
  const handleClick = () => {
    if (teamResults) {
      dispatch(userSlice.actions.setActiveMenuSection('teams'))
      dispatch(userSlice.actions.setMenuLevel(1))
      navigate('/team-dashboard')
      return
    }

    if (!hasTeam) {
      dispatch(userSlice.actions.setActiveMenuSection('teams'))
      dispatch(userSlice.actions.setMenuLevel(0))
      navigate('/team-create')
      return
    }

    if (teamAdmin) {
      dispatch(userSlice.actions.setActiveMenuSection('teams'))
      dispatch(userSlice.actions.setMenuLevel(1))
      navigate('/teams')
    }
  }
  const cardDescription = getCardDescription(teamAdmin, teamResults)
  const classes = useStyles()
  return (
    <Paper className={classes.homePaper}>
      <Typography data-test='homePageCard1' variant='h2' className={classes.cardTitle}>
        Your team
      </Typography>
      <Grid container className={classes.homePaperContent}>
        <Grid item xs={7} xl={8} className={classes.fixHeight}>

          <Typography>
            {cardDescription}
          </Typography>

        </Grid>
        <Grid item xs={5} xl={4}>
          <img className={classes.imgHover} src={(!teamAdmin && !teamResults) ? '/img/team-homepage-2.png' : '/img/team-homepage-1.png'} alt='' />
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <Button
          disabled={!teamAdmin && !teamResults}
          className={classes.footerButton}
          data-test='lnk-teamsCard'
          variant='text'
          onClick={handleClick}
        >
          {!teamResults ? 'View your team space' : 'View your team results'}
        </Button>
      </div>
    </Paper>
  )
}

export default TeamsCard
