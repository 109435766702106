import reducer, * as actions from './slice'
import * as selectors from './selector'
import name from './name'

const dashboard = {
  reducer,
  actions,
  selectors,
  name
}

export default dashboard
