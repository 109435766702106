const getStageByScore = (score) => {
  if (score >= 50 && score < 63) return 2
  if (score >= 63 && score < 73) return 3
  if (score >= 73 && score <= 100) return 4
  return 1
}

const pointsToNextStage = score => {
  const currentStage = getStageByScore(score)
  switch (currentStage) {
    case 1:
      return 50 - score
    case 2:
      return 63 - score
    case 3:
      return 73 - score
    default:
      return 0
  }
}

const getTeamScoresDiff = members => {
  const sortedMemberScores = members.map(member => member.scores.score).sort((a, b) => a - b)
  const highestScore = sortedMemberScores[sortedMemberScores.length - 1]
  const lowerstScore = sortedMemberScores[0]
  return highestScore - lowerstScore
}

const componentTakeawayTexts = {
  'Strategy and business alignment': {
    benchmark: 'the average for allocating learning resources to address business priorities',
    industry: 'your industry average for analysing business problems before recommending a solution',
    size: 'the average for your organisation size for planning how you’ll meet agreed business metrics or KPIs'
  },
  'Employee experience': {
    benchmark: 'the average for recognising people’s learning achievements',
    industry: 'your industry average for providing access to available learning opportunities',
    size: 'the average for your organisation size for leveraging technology-enabled learning to support career development'
  },
  'Environment, talent and culture': {
    benchmark: 'the average for fostering a good relationship with your IT department',
    industry: 'your industry average for mapping learning interventions to your capability framework',
    size: 'the average for your organisation size for encouraging reflection—and providing the time to do so'
  },
  'L&D team capabilities': {
    benchmark: 'the average for researching the impact of emerging technologies on learning',
    industry: 'your industry average for providing micro-content when appropriate',
    size: 'the average for your organisation size for managing your learning resources to help people find the information they need'
  },
  'Organisational engagement': {
    benchmark: 'the average for involving users in designing the most appropriate learning approach',
    industry: 'your industry average for supporting classroom facilitators in using technology to transfer learning to the workplace',
    size: 'the average for your organisation size for implementing a communication plan for every one of your key stakeholders'
  },
  'Learning impact': {
    benchmark: 'the average for collecting regular data on how well people are understanding learning points',
    industry: 'your industry average for leveraging performance data to measure and improve your learning programmes’ impact',
    size: 'the average for your organisation size for effectively communicating how learning benefits every individual'
  }
}

const stagesCopy = {
  1: {
    head: 'Stage 1: Transactional L&D intervention',
    points: [
      'You focus on efficiency',
      'Your L&D strategy is responsive, but would benefit from more detailed and informed planning',
      'You aren\'t getting the most out of your data'
    ]
  },
  2: {
    head: 'Stage 2: Implementing L&D programmes',
    points: [
      'You focus on technology, but your impact on learning culture is limited',
      'You have good business awareness, but your L&D decisions are often made in isolation'
    ]
  },
  3: {
    head: 'Stage 3: Proactive talent and performance strategy',
    points: [
      'You focus on outcomes',
      'You drive and integrate work and learning, but senior figures don’t always consider L&D a priority',
      'You leverage data to inform decisions and effect change'
    ]
  },
  4: {
    head: 'Stage 4: High-impact learning culture',
    points: [
      'Multiple stakeholders share a focus on outcomes',
      'You draw on analytics to inform and fine-tune your L&D strategy'
    ]
  }
}

export {
  getStageByScore,
  pointsToNextStage,
  componentTakeawayTexts,
  stagesCopy,
  getTeamScoresDiff
}
