import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './style'
import { Skeleton } from '@material-ui/lab'

const DashboardAreasSkeleton = () => {
  const classes = useStyles()
  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12}>
        <Skeleton variant='text'>
          <Typography variant='h1'>
            Exploring the six key components
          </Typography>
        </Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='rect' width='100%' height={100} />
      </Grid>
      <Grid item xs={8}>
        <Skeleton variant='text'>
          <Typography variant='h2'>Title</Typography>
        </Skeleton>
        <Skeleton>
          <Typography variant='h3'>Key questions to consider</Typography>
          <Typography variant='body1'>Key questions number one this line needs to be long</Typography>
          <Typography variant='body`'>Key questions number two this line needs to be long</Typography>
        </Skeleton>
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant='circle'>
          <div className={classes.img}>
            <img src='/img/dashboard-summary.png' alt='' />
          </div>
        </Skeleton>
      </Grid>
      <Grid item md={4} lg={4} className={classes.chartsContainer}>
        <Skeleton variant='rect' width='100%' height={600} />
      </Grid>
      <Grid item md={8} lg={8} className={classes.chartsContainer}>
        <Skeleton variant='rect' width='100%' height={600} />
      </Grid>
      <Grid item xs={12} classes={{ root: classes.takeaways }}>
        <Skeleton variant='text'>
          <Typography variant='body1' className={classes.takeawaysTitle}>Quick takeaways</Typography>
        </Skeleton>
        <Skeleton variant='text'>
          <Typography variant='body1'>
            Remember, your score is measured on a scale of 1–100, and is revisited
            annually to ensure statistical validity and reliability.
          </Typography>
        </Skeleton>
        <Grid alignItems='center' container spacing={2}>

          <Grid item xs={12} md={3} lg={3}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>

        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='text'>
          <Typography variant='h2'>
            Other solutions we provide
          </Typography>
        </Skeleton>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DashboardAreasSkeleton
