import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  homePaper: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '390px'
  },
  cardStep: {
    height: '70px',
    border: '2px solid #253746',
    borderRadius: '50%',
    padding: '12px',
    marginTop: 0,
    minWidth: '73px',
    textAlign: 'center'
  },
  cardStepFilled: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    height: '70px',
    border: '2px solid #006fb7',
    borderRadius: '50%',
    padding: '12px',
    marginTop: 0,
    minWidth: '73px',
    textAlign: 'center'
  },
  cardTitle: {
    marginBottom: 8,
    textAlign: 'center'
  },
  cardDescription: {
    marginBottom: 12,
    textAlign: 'center'
  },
  footer: {
    justifySelf: 'flex-end'
  }
}))
