import React from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { ReportPDF } from '../../components'

const PDFPreview = () => (
  <PDFViewer style={{ width: '100vw', height: '100vh' }}>
    <ReportPDF />
  </PDFViewer>
)

export default PDFPreview
