import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none'
  }
}))
