import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { surveySlice, teamSlice, userSlice } from '../../reducers'
import { AppToolbar, HowItWorksComponent, TeamCreateInformationCard, TeamCreateNameCard, TeamCreateMembersCard } from '../../components'
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import { useStyles } from './style'
import { validateEmail } from '../../libs/formUtils'
import { basedURL } from '../../helpers/domain'

const TeamCreatePage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const dataLayer = window.dataLayer || []

  const { register, watch, control } = useForm({
    defaultValues: {
      team: '',
      organisation: '',
      size: '',
      region: '',
      spread: '',
      industry: ''
    }
  })

  const [pageState, setPageState] = React.useState('get-started')
  const [teamNameError, setTeamNameError] = React.useState('')
  const [teamInformationError, setTeamInformationError] = React.useState('')
  const [teamMembersError, setTeamMembersError] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [needToSendInvitation, setNeedToSendInvitation] = React.useState(false)
  const [membersToInvite, setMembersToInvite] = React.useState(null)
  const [detailsPageInfo, setDetailsPageInfo] = React.useState([
    {
      type: 'name',
      completed: false,
      expanded: true
    },
    {
      type: 'information',
      completed: false,
      expanded: false
    },
    {
      type: 'members',
      membersCount: 1,
      members: [],
      completed: false,
      expanded: false
    }
  ])

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)
  const teamInformation = useSelector(teamSlice.selectors.selectTeamInformation)
  const team = useSelector(teamSlice.selectors.selectTeam)
  const teamInvitationStatus = useSelector(teamSlice.selectors.selectTeamInvitationStatus)
  const userData = useSelector(userSlice.selectors.selectUserData)

  const [getTeamMemberInvitationStatus] = useEvent([teamSlice.eventBus.getTeamMemberInvitationStatus])
  const [saveTeamInformation] = useEvent([teamSlice.eventBus.saveTeamInformation])
  const [getUserData] = useEvent([userSlice.eventBus.getUserData])

  const membersArray = Array.from(Array(detailsPageInfo[2].membersCount).keys())

  const handleGetStarted = () => {
    setPageState('team-details')
  }

  const handleCreateTeamName = () => {
    const formData = watch()
    const teamName = formData.team
    const error = teamName.length > 50
      ? 'Maximun 50 characters'
      : teamName.length === 0
        ? 'You must give your team a name'
        : null
    if (error) {
      setTeamNameError(error)
    } else {
      saveTeamInformation.trigger({ type: 'name', data: teamName })
    }
  }

  const handleCreateTeamInformation = () => {
    const formData = watch()
    const organisationName = formData.organisation
    const organisationSize = formData.size
    const organisationRegion = formData.region
    const organisationSpread = formData.spread
    const organisationIndustry = formData.industry
    const organisationHashId = team.hashId

    const nameError = !organisationName || organisationName.length === 0
    const sizeError = !organisationSize || organisationSize.length === 0
    const regionError = !organisationRegion || organisationRegion.length === 0
    const spreadError = !organisationSpread || organisationSpread.length === 0
    const industryError = !organisationIndustry || organisationIndustry.length === 0

    if (nameError || sizeError || regionError || spreadError || industryError) {
      setTeamInformationError({ nameError, sizeError, regionError, spreadError, industryError })
    } else {
      const teamInfoData = {
        name: organisationName,
        size: organisationSize,
        region: organisationRegion,
        spread: organisationSpread,
        industry: organisationIndustry,
        hashId: organisationHashId
      }

      saveTeamInformation.trigger({ type: 'information', data: teamInfoData })
      getUserData.trigger()
    }
  }

  const handleAddTeamMembers = () => {
    const formData = watch()
    const formEmailsToInvite = Object.keys(formData).filter(key => key.startsWith(('member-'))).map(key => formData[key])

    if (formEmailsToInvite.length === 1 && (formEmailsToInvite.find(key => key === '') === '')) {
      setTeamMembersError('Please enter at least one email address.')
      return
    }

    const usedEmails = formEmailsToInvite.map(email => membersArray.indexOf(email)).filter(val => val >= 0)
    if (usedEmails.length > 0) {
      setTeamMembersError("You can't invite existing members.")
      return
    }

    const lastEmailHasBeenVerified = (formEmailsToInvite.find(key => key === '') === '')
    if (!lastEmailHasBeenVerified) {
      const [lastEmail] = formEmailsToInvite.slice(-1)
      const findDuplicatesEmails = formEmailsToInvite.filter((item, index) => formEmailsToInvite.indexOf(item) !== index)
      if (findDuplicatesEmails.length > 0) {
        setTeamMembersError(`The email ${lastEmail} is already listed above.`)
        return
      }
      if (!validateEmail(lastEmail)) {
        setTeamMembersError(`Email ${lastEmail} is not valid.`)
        return
      }

      // check member status invitation and handle members inivitation on useEffect
      setMembersToInvite(formEmailsToInvite)
      const invitationData = { invitedMember: lastEmail }
      setNeedToSendInvitation(true)
      getTeamMemberInvitationStatus.trigger({ data: invitationData })
      setIsLoading(true)
    }
  }

  const addTeamMember = () => {
    const formData = watch()
    const formEmailsToInvite = Object.keys(formData).filter(key => key.startsWith(('member-'))).map(key => formData[key])

    const hasEmptyFields = (formEmailsToInvite.find(key => key === '') === '')
    if (hasEmptyFields) {
      setTeamMembersError('Please enter an email address before adding another one.')
      return
    }

    const usedEmails = formEmailsToInvite.map(email => membersArray.indexOf(email)).filter(val => val >= 0)
    if (usedEmails.length > 0) {
      setTeamMembersError("You can't invite existing members.")
      return
    }

    const [invitedEmail] = formEmailsToInvite.slice(-1)
    const findDuplicatesEmails = formEmailsToInvite.filter((item, index) => formEmailsToInvite.indexOf(item) !== index)
    if (findDuplicatesEmails.length > 0) {
      setTeamMembersError(`The email ${invitedEmail} is already listed above.`)
      return
    }

    if (invitedEmail && validateEmail(invitedEmail)) {
      const invitationData = { invitedMember: invitedEmail }
      getTeamMemberInvitationStatus.trigger({ data: invitationData })
      setIsLoading(true)
    } else {
      setTeamMembersError(`Email ${invitedEmail} is not valid. Please enter a valid email address.`)
    }
  }

  const handleNavigateToTeams = () => {
    if (!userData.reviewCompleted) {
      dispatch(userSlice.actions.setMenuLevel(1))
      dispatch(userSlice.actions.setActiveMenuSection('teams'))
      navigate('/teams')
    } else {
      navigate('/clean-results?teamId=clean')
    }
  }

  const jumpAddMembers = () => {
    handleNavigateToTeams()
  }

  useEffect(() => {
    if (detailsPageInfo[2].membersCount === 19) {
      setTeamMembersError('You have reached the maximum of 20 team members')
    }
  }, [detailsPageInfo])

  const updateTeamDetails = teamInfo => {
    if (teamInfo.type === 'error') {
      navigate('/')
    }

    const detailsPageInfoCopy = [...detailsPageInfo]
    if (teamInfo.type === 'name' && teamInfo.completed) {
      detailsPageInfoCopy[0].completed = true
      detailsPageInfoCopy[0].expanded = false
      detailsPageInfoCopy[1].expanded = true
      setDetailsPageInfo(detailsPageInfoCopy)
    }

    if (teamInfo.type === 'information' && teamInfo.completed) {
      detailsPageInfoCopy[1].completed = true
      detailsPageInfoCopy[1].expanded = false
      detailsPageInfoCopy[2].expanded = true
      setDetailsPageInfo(detailsPageInfoCopy)
    }

    if (teamInfo.type === 'members' && teamInfo.completed) {
      handleNavigateToTeams()
    }
  }

  useEffect(() => {
    if (Object.keys(teamInformation).length > 0) {
      updateTeamDetails(teamInformation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamInformation])

  useEffect(() => {
    dataLayer.push({
      event: 'stateChange',
      attributes: {
        path: '/team-create'
      }
    })
  })

  useEffect(() => {
    if (isLoading) {
      if (teamInvitationStatus === null) {
        setIsLoading(false)
      } else if (teamInvitationStatus.allowedToInvite && needToSendInvitation) {
        setIsLoading(false)
        setNeedToSendInvitation(false)
        const teamMembersData = {
          hashId: team.hashId,
          members: membersToInvite,
          url: window.location.protocol + '//' + basedURL()
        }
        saveTeamInformation.trigger({ type: 'members', data: teamMembersData })
      } else if (teamInvitationStatus.allowedToInvite) {
        setIsLoading(false)
        const detailsPageInfoCopy = [...detailsPageInfo]
        if (detailsPageInfoCopy[2].membersCount < 19) {
          detailsPageInfoCopy[2].membersCount++
          setDetailsPageInfo(detailsPageInfoCopy)
        }
        setTeamMembersError('')
      } else {
        setIsLoading(false)
        setTeamMembersError(`We couldn’t add this member ${teamInvitationStatus.invitedMember} to your team as they’re already part of another team.`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamInvitationStatus])

  return (
    <div className={classes.root}>
      <AppToolbar
        isMobileScreen={isMDScreen || isXSScreen}
        hideHamburger
      />
      <Grid container>
        <div className={isLGScreen ? classes.main : classes.mainMD} id='main'>
          {pageState === 'get-started' && (
            <HowItWorksComponent
              handleGetStarted={handleGetStarted}
            />
          )}
          {pageState === 'team-details' && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Typography variant='h1' className={classes.title} data-test='createTeamNameHeader'>
                  Add team and start benchmark
                </Typography>
                <Grid container spacing={6}>
                  <Grid style={{ display: !isLGScreen ? 'none' : '' }} lg={2} />
                  <Grid item lg={8} xs={12}>
                    <TeamCreateNameCard
                      detailsPageInfo={detailsPageInfo}
                      teamNameError={teamNameError}
                      register={register}
                      handleCreateTeamName={handleCreateTeamName}
                      saveTeamInformation={saveTeamInformation}
                    />
                    <TeamCreateInformationCard
                      detailsPageInfo={detailsPageInfo}
                      register={register}
                      control={control}
                      teamInformationError={teamInformationError}
                      saveTeamInformation={saveTeamInformation}
                      handleCreateTeamInformation={handleCreateTeamInformation}
                    />
                    <TeamCreateMembersCard
                      detailsPageInfo={detailsPageInfo}
                      membersArray={membersArray}
                      addTeamMember={addTeamMember}
                      teamMembersError={teamMembersError}
                      jumpAddMembers={jumpAddMembers}
                      saveTeamInformation={saveTeamInformation}
                      register={register}
                      isLoading={isLoading}
                    />
                    <Button
                      disabled={!detailsPageInfo[2].expanded}
                      variant='contained'
                      color='secondary'
                      className={`${classes.cardButton} Team_Create_Submit`}
                      onClick={handleAddTeamMembers}
                      startIcon={
                        isLoading ? <CircularProgress size={14} color='inherit' /> : <SendIcon />
                      }
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </div>
  )
}

export default TeamCreatePage
