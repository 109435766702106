import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './style'

const LPWorksRow = ({ handleClickDownload }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleCreate = () => {
    navigate('/register')
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.darkContainer}>
        <Grid item xs={12}>
          <Typography variant='h2' style={{ textAlign: 'center', marginBottom: 40, marginTop: 64 }}>How it works</Typography>
        </Grid>
        <Grid item md={4}>
          <div className={classes.worksContainer}>
            <Typography variant='h3' className={classes.worksTitle}>1. Create an account</Typography>
            <Typography className={classes.worksText}>We just need a few details to help you get started. All the information you provide is anonymous and confidential.</Typography>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={classes.worksContainer}>
            <Typography variant='h3' className={classes.worksTitle}>2. Complete your review</Typography>
            <Typography className={classes.worksText}>Reflect on your company's current L&D offering, including your organisation's current goals, strategy, technology and challenges.</Typography>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={classes.worksContainer}>
            <Typography variant='h3' className={classes.worksTitle}>3. Instant personalised results</Typography>
            <Typography className={classes.worksText}>After you've completed the review, you'll gain access to a bespoke and interactive dashboard, complete with downloadable PDF.</Typography>
            <Typography onClick={handleClickDownload} className={classes.download}>Download sample PDF</Typography>
          </div>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Button variant='contained' color='secondary' style={{ width: 'fit-content', margin: '24px auto 0' }} onClick={handleCreate}>Create an account</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LPWorksRow
