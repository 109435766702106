import React from 'react'
import Typography from '@material-ui/core/Typography'

const teamCarousel = [
  {
    img: 'img/team_carousel.png',
    title: 'Everything the free LPB offers, plus:',
    data: () => (
      <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>1 admin and up to 19 team members under one account</Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>Combined average maturity score for the whole team</Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>See anonymised colleague's scores simultaneously</Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>Aggregated team results you can share with business stakeholders</Typography>
        </div>
      </div>
    )
  }
]

export default teamCarousel
