import React from 'react'
import { Typography, Button, Grid } from '@material-ui/core'
import { MailtoLink } from '../../components'
import { useStyles } from './style'

const AccountTeamBanner = ({ handleClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.teamBannerBox}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography variant='h2'>How far have your team progressed?</Typography>
          <Typography variant='body1' style={{ marginBottom: '24px' }}>Retake the review and discover the differences compared to last time.</Typography>
          <Button variant='contained' color='secondary' onClick={handleClick}>Buy again</Button>
        </Grid>
        <Grid item xs={4}>
          <img src='/img/team-homepage-1.png' width='150px' height='150px' alt='Team banner' />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>If you previously paid by invoice, email <MailtoLink email='lpbenchmark@mindtools.com'>lpbenchmark@mindtools.com</MailtoLink> to repurchase.</Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default AccountTeamBanner
