import {
  scaleLinear,
  select,
  axisBottom
} from 'd3'

export const drawSpreadChart = (data, screenSize, ref, forPdf) => {
  const margin = 40

  let chartWidth = 450 - 2 * margin
  const chartHeight = 100

  switch (screenSize) {
    case 'xs':
      chartWidth = 700
      break
    case 'md':
      chartWidth = 700
      break
    case 'lg':
      chartWidth = 950
      break
    default:
      break
  }

  const xScale = scaleLinear([0, 100], [0, chartWidth])

  const chart = select(ref.current)
    .html('')
    .append('svg')
    .attr('class', 'spreadChartSvgContent')
    .attr('viewBox', `0 0 ${chartWidth + margin} ${chartHeight + margin * 2}`)
    .attr('aria-label', 'bar chart')
    .attr('align', 'center')
    .append('g')
    .attr('transform', `translate(10, ${margin})`)

  //  const tooltip = select(ref.current)
  //    .append('div')
  //    .attr('class', 'tooltip')

  const xAxis = axisBottom(xScale)
    .ticks()
    .tickFormat(d => `${d.toString()}%`)
    .tickSizeOuter(0)

  chart
    .append('g')
    .attr('transform', `translate(0, ${chartHeight})`)
    .attr('class', forPdf ? 'x-axis-pdf' : 'x-axis')
    .call(xAxis)

  const barGroup = chart.append('g').attr('class', 'bar-group')
  barGroup
    .selectAll('.rect')
    .data(data)
    .enter()
    .append('g')
    .append('rect')
    .attr('class', 'rect')
    .attr('x', xScale(0))
    .attr('width', d => xScale(d.value))
    .attr('height', 64)
    .attr('fill', d => d.color)

  //  const showTooltip = (e, d) => {
  //    console.log('spreadChart showTooltip', d)
  //    return tooltip
  //      .style('left', `${e.pageX + 10}px`)
  //      .style('top', `${e.pageY}px`)
  //      .style('display', 'inline-block')
  //      .text(`teste%`)
  //  }
  //
  //  const hideTooltip = () => {
  //    return tooltip.style('display', 'none')
  //  }
  //
  //  barGroup.on('mousemove', showTooltip)
  //  barGroup.on('mouseout', hideTooltip)
}
