import React from 'react'
import { useStyles } from './style'
import { ClickAwayListener, Tooltip, Typography } from '@material-ui/core'

const TextTooltip = ({ text, tooltipText }) => {
  const classes = useStyles()
  const [isTooltilOpen, setTooltipOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          onClose={handleTooltipClose}
          open={isTooltilOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={tooltipText}
          placement='bottom-start'
          arrow
        >
          <Typography
            className={classes.text}
            onClick={handleTooltipOpen}
            data-test='lnk-tooltip'
          >
            {text}
          </Typography>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

export default TextTooltip
