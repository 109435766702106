import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { MailtoLink } from '../../components'
import { useStyles } from './style'

const TeamAlignedComponent = ({ handleUpgrade }) => {
  const classes = useStyles()

  return (
    <Grid item lg={6} md={12}>
      <Typography variant='h1'>How aligned are your team?</Typography>
      <Typography variant='body1' className={classes.description}>Introducing LPB Premium – the upgraded team version of the Learning Performance Benchmark. Benchmark with your colleagues, gain a 360° view of your L&D, and identify the areas you need to focus on.</Typography>
      <Typography variant='h3'> The LPB Premium will help you:</Typography>
      <ul className={classes.description}>
        <li className={classes.li1}>
          <Typography variant='body1'>introduce your team to new ideas and ways of thinking</Typography>
        </li>
        <li className={classes.li1}>
          <Typography variant='body1'>discover differences in opinion, both big and small</Typography>
        </li>
        <li className={classes.li1}>
          <Typography variant='body1'>start conversations to drive change</Typography>
        </li>
      </ul>
      <Button className='ILP_Pay_CTA_1' onClick={handleUpgrade} variant='contained' color='secondary'>Upgrade to a team account</Button>
      <Typography className={classes.helper}>NOTE: You will be redirected to a secure external payment system. Alternatively, if you require an invoice to pay, email <MailtoLink email='lpbenchmark@mindtools.com'>lpbenchmark@mindtools.com</MailtoLink></Typography>
    </Grid>
  )
}

export default TeamAlignedComponent
