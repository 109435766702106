import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
  },
  img: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '32px'
  },
  introText: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginTop: '24px',
    marginBottom: '24px'
  },
  chartsContainer: {
    padding: '64px 12px !important'
  }
}))
