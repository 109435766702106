import React from 'react'
import { Page, View, Text, Image } from '@react-pdf/renderer'
import { PDFFooter } from '../../components'
import BAELogo from '../../img/BAE-systems-logo-white.png'
import BloombergLogo from '../../img/Bloomberg-Logo-white.png'
import KPMGLogo from '../../img/KPMG-Logo-white.png'
import NHSLogo from '../../img/NHS-Logo-white.png'
import NestleLogo from '../../img/Nestle-Logo-white.png'
import SiemensLogo from '../../img/Siemens-Logo-white.png'
import styles from './style'
import globalStyles from '../../style'

const IntroPage = () => (
  <Page size='A4' orientation='landscape'>
    <View style={[globalStyles.pageView, { display: 'flex', flexDirection: 'row', height: '75%' }]}>

      <View style={styles.textContainer}>
        <Text style={globalStyles.heading}>Introduction</Text>
        <Text style={globalStyles.subheading}>What is the Learning Performance Benchmark?</Text>
        <Text style={[globalStyles.body, { fontSize: 11 }]}>The Learning Performance Benchmark is an independent and confidential L&D comparison tool. It is designed to uncover gaps in your learning strategy, identify what to prioritise and benchmarks you against your peers.</Text>
        <Text style={globalStyles.subheading}>How is my score calculated?</Text>
        <Text style={[globalStyles.body, { fontSize: 11 }]}>Your score is an aggregate of 96 strategic behaviours, measured on a scale of 1–100. It tracks the overall health of your organisation’s learning strategy, and establishes
          a baseline for the coming year. In this way, your score indicates not only the impact of your L&D strategy in practice, but also the extent of your digital innovation, and how well your organisation is embedding behavioural change. The learning maturity score has a positive relationship to the 4 strategic levers of business:
        </Text>
        <Text style={[globalStyles.body, { fontSize: 11 }]}>1. growth</Text>
        <Text style={[globalStyles.body, { fontSize: 11 }]}>2. transformation</Text>
        <Text style={[globalStyles.body, { fontSize: 11 }]}>3. productivity</Text>
        <Text style={[globalStyles.body, { fontSize: 11 }]}>4. profitability</Text>
        <Text style={globalStyles.subheading}>Data source</Text>
        <Text style={[globalStyles.body, { fontSize: 11 }]}>The benchmark scores have been established from the responses almost 800 participants from the private, public and not-for-profit sectors who completed the 2021 Learning Performance Benchmark.</Text>

      </View>

      <View style={styles.imageContainer}>
        <Image src='https://dev-lpb-report.s3.eu-west-2.amazonaws.com/verify-email.png' style={styles.img} />

      </View>

    </View>
    <View style={styles.logosBox}>
      <View style={styles.logoContainer}>

        <Image src={SiemensLogo} style={styles.logo} />
      </View>
      <View style={styles.logoContainer}>
        <Image src={BloombergLogo} style={styles.logo} />
      </View>
      <View style={styles.logoContainer}>
        <Image src={NestleLogo} style={styles.logo} />

      </View>
      <View style={styles.logoContainer}>
        <Image src={NHSLogo} style={styles.logo} />

      </View>
      <View style={styles.logoContainer}>
        <Image src={KPMGLogo} style={[styles.logo, { transform: 'scale(0.8)' }]} />

      </View>
      <View style={styles.logoContainer}>
        <Image src={BAELogo} style={styles.logo} />

      </View>

    </View>
    <PDFFooter pageNumber={2} truncate style={{ marginTop: 5 }} />
  </Page>
)

export default IntroPage
