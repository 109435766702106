import { Grid } from '@material-ui/core'
import React from 'react'
import { Skeleton } from '@material-ui/lab'

const HomePageLoading = () => {
  return (
    <Grid item xs={12} md={6}>
      <Skeleton variant='text' animation='wave' width='30%' height='100px' style={{ margin: '20px 0' }} />
      <Skeleton variant='text' animation='wave' width='100%' height='50px' style={{ marginBottom: '-16px' }} />
      <Skeleton variant='text' animation='wave' width='100%' height='50px' style={{ marginBottom: '-16px' }} />
      <Skeleton variant='text' animation='wave' width='100%' height='50px' style={{ marginBottom: '-16px' }} />
      <Skeleton variant='text' animation='wave' width='25%' height='80px' style={{ margin: '20px 0' }} />
    </Grid>
  )
}

export default HomePageLoading
