const firstTechnologySection = {
  name: 'Technology, tools and access',
  elements: [
    {
      type: 'matrix',
      name: 'Tech Collaboration',
      title: '1. Which of the following are you using or planning to use over the next 12 months?',
      hideNumber: true,
      columns: [
        {
          value: 'Column 1',
          text: 'We use this now'
        },
        {
          value: 'Column 2',
          text: 'We\'re just experimenting / trialling it'
        },
        {
          value: 'Column 3',
          text: 'We plan to use this in the next 12 months'
        },
        {
          value: 'Column 4',
          text: 'We do not use this'
        }
      ],
      rows: [
        'Webinar (e.g., Webex, Adobe Connect)',
        'Communication tools (e.g., instant messaging, forums)',
        'Virtual classrooms (live)',
        'Communities of practice (e.g., communities to support working practices/subject interest groups)',
        'External social networking or peer-to-peer sites (e.g., LinkedIn, Twitter, Instagram, TikTok)',
        'In-house/enterprise social networks (e.g., Yammer, Slack, Teams)',
        'Learning communities (e.g., action learning) ',
        'Feeds/curation and social bookmarking (e.g., Curatr, Pinterest, StumbleUpon)',
        'Continuous learning platforms (e.g., Degreed, Axonify, Fuse)'
      ]
    }
  ]
}

export default firstTechnologySection
