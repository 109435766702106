import React from 'react'
import {
  DashboardMaturityExplore,
  DashboardMaturityPercentagesCard,
  DashboardMaturirySolutions
} from '../../components'
import { useStyles } from './style'
import { Grid, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const DashboardMaturitySkeleton = () => {
  const classes = useStyles()
  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={8}>
        <Skeleton variant='text'>

          <Typography variant='h1'>Your learning maturity stage</Typography>
        </Skeleton>
        <Skeleton variant='text'>
          <Typography className={classes.introText}>
            Our four-phase maturity model will forge your path towards becoming a
            top-performing learning organisation. Explore our recommendations for
            how to progress to the higher stages of the model.
          </Typography>
        </Skeleton>

      </Grid>
      <Grid item xs={4}>
        <Skeleton variant='circle'>
          <div className={classes.img}>
            <img src='/img/dashboard-maturity.png' alt='' />
          </div>
        </Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='rect' width='100%' height={500} />
      </Grid>
      <Grid item md={8} lg={8} className={classes.chartsContainer}>
        <Skeleton variant='rect' width='100%' height={500} />
      </Grid>
      <Grid item md={4} lg={4} className={classes.chartsContainer}>
        <Skeleton variant='rect'>
          <DashboardMaturityPercentagesCard />
        </Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton>
          <DashboardMaturityExplore stage={4} />
        </Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton>
          <DashboardMaturirySolutions />
        </Skeleton>
      </Grid>
    </Grid>
  )
}
export default DashboardMaturitySkeleton
