const thirdEngageSection = {
  name: 'Strategy in practice and culture - Continued p15',
  elements: [
    {
      type: 'customrating',
      name: 'Engage_11',
      title: '90. We complete pilot projects before implementing new learning initiatives',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Engage_12',
      title: '91. We give our learning initiatives a specific identity and brand',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Engage_13',
      title: '92. We ensure a communication plan is in place for all key stakeholders',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Engage_14',
      title: '93. We pull key stakeholders together into a steering group to support new learning initiatives',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Engage_15',
      title: '94. We use digital technology to promote organised learning initiatives through campaigns',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Engage_16',
      title: '95. We always test a minimum viable product before we scale',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default thirdEngageSection
