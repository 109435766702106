import React from 'react'
import { useStyles } from './style'
import { Paper, Typography } from '@material-ui/core'
import { TeamMemberScoresChart } from '../../components'

const KeysComponent = {
  'Strategy and business alignment': 'Align',
  'Employee experience': 'Listen',
  'Environment, talent and culture': 'Influence',
  'L&D team capabilities': 'Enable',
  'Organisational engagement': 'Engage',
  'Learning impact': 'Improve'
}

// Exploring the six key components
const TeamComponentScoreCard = ({ currentSeason, title, members, userLoggedIn }) => {
  const classes = useStyles()

  const allIndividualScores = members.map(item => {
    const { hashId, scores } = item
    const { components } = scores
    const componentsScore = []
    let color = '#FF8200'
    let member = item.name

    if (hashId === userLoggedIn.hashId) {
      color = '#FFD09F'
      member = 'You'
    }

    for (const [key, score] of Object.entries(components)) {
      componentsScore.push({ key, score })
    }

    return { name: member, componentsScore, color }
  })

  const scores = allIndividualScores.map((item) => {
    const component = item.componentsScore.filter(x => x.key === KeysComponent[title])[0]
    return { member: item.name, score: component.score, color: item.color }
  })

  return (
    <Paper
      variant='outlined'
      className={classes.root}
      data-test='card-yourScore'
    >
      <Typography variant='h3'>{`Individual ${currentSeason} team member scores`}</Typography>
      <TeamMemberScoresChart scores={scores} limit={10} />
    </Paper>
  )
}

// Team learning maturity score
const TeamScoreCard = ({ currentSeason, members, userLoggedIn }) => {
  const classes = useStyles()

  const scores = members.map(item => {
    const { hashId, scores } = item
    const { score } = scores

    let color = '#FF8200'
    let member = item.name

    if (hashId === userLoggedIn.hashId) {
      color = '#FFD09F'
      member = 'You'
    }

    return { member, score, color }
  })

  return (
    <Paper
      variant='outlined'
      className={classes.root}
      data-test='card-yourScore'
    >
      <Typography variant='h3'>{`Individual ${currentSeason} team member scores`}</Typography>
      <TeamMemberScoresChart scores={scores} limit={100} />
    </Paper>
  )
}

export { TeamComponentScoreCard, TeamScoreCard }
