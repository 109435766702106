import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(_theme => ({
  svgContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '100%',
    verticalAlign: 'middle',
    overflow: 'hidden'
  }
}))
