import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
  },
  takeaways: {
    backgroundColor: '#EEEFF1',
    boxShadow: '-90px 0px 0px 0px #EEEFF1,90px 0px 0px 0px #EEEFF1'
  },
  chartsContainer: {
    padding: '32px 12px !important'
  },
  img: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
    padding: '32px',
    '@media (max-width: 1200px)': {
      paddingRight: '0px'
    }

  },
  introText: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginTop: '24px',
    marginBottom: '24px'
  },
  takeawaysTitle: {
    color: '#253746',
    margin: '24px 0',
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: '1.5rem',
    '@media (min-width:600px)': {
      fontSize: '1.667rem'
    },
    '@media (min-width:900px)': {
      fontSize: '1.875rem'
    },
    '@media (min-width:1200px)': {
      fontSize: '2.0833rem'
    }
  }
}))
