import React, { useRef, useEffect } from 'react'
import { Paper, Typography, useMediaQuery, Grid } from '@material-ui/core'
import { drawSpreadChart } from './draw'
import { useStyles } from './style'

const TeamMaturitySpread = ({ members }) => {
  const classes = useStyles()
  const ref = useRef()

  const isXSScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isMDScreen = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'))
  const isLGScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const screenSize = (isXSScreen && 'xs') || (isMDScreen && 'md') || (isLGScreen && 'lg')
  const stagesCalc = members.map(member => member.scores.score).reduce((acc, curr) => {
    if (curr <= 50) {
      acc.memberStages[0] += 1
    }
    if (curr > 50 && curr <= 63) {
      acc.memberStages[1] += 1
    }
    if (curr > 63 && curr <= 73) {
      acc.memberStages[2] += 1
    }
    if (curr > 73) {
      acc.memberStages[3] += 1
    }
    acc.sum += 1
    return acc
  }, { sum: 0, memberStages: [0, 0, 0, 0] })
  const memberStages = stagesCalc.memberStages.map(sum => Math.round(100 * (sum / stagesCalc.sum)))
  const chartData = [
    {
      value: 100,
      score: memberStages[3],
      color: '#03519B'
    },
    {
      value: 100 - memberStages[3],
      score: memberStages[2],
      color: '#0071BD'
    },
    {
      value: 100 - memberStages[3] - memberStages[2],
      score: memberStages[1],
      color: '#0095E5'
    },
    {
      value: 100 - memberStages[3] - memberStages[2] - memberStages[1],
      score: memberStages[0],
      color: '#4ABFF8'
    }
  ]

  const [chartWidth, setChartWidth] = React.useState(null)

  useEffect(() => {
    if (ref.current) {
      setChartWidth(Math.round(ref.current.offsetWidth))
    }
    if (chartWidth && ref.current) {
      drawSpreadChart(chartData, screenSize, ref, false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, screenSize])

  return (
    <Paper variant='outlined' className={classes.root}>
      <Typography variant='h3'>Distribution of team members per stage</Typography>
      <div id='maturity-spread' ref={ref} />
      <Grid container spacing={1} style={{ flexDirection: 'row-reverse' }}>
        {chartData.map((data, idx) => (
          <Grid key={idx} item xs={3} className={classes.labelBox}>
            <span style={{ backgroundColor: data.color }} className={classes.label} />
            <Typography variant='body1'>Stage {4 - idx}: {data.score}%</Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

export default TeamMaturitySpread
