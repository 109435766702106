import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  sectionTitle: {
    marginBottom: '1.5rem',
    marginTop: 0
  },
  introText: {
    fontSize: 20,
    fontWeight: 400,
    marginBottom: '16px'
  },
  img: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%'
  }
}))
