import React from 'react'
import { useSelector } from 'react-redux'
import { surveySlice } from '../../reducers'
import { useStyles } from './style'
import { Typography, Paper, Button } from '@material-ui/core'

const DashboardReading = ({ image, title, text, link, idx, color }) => {
  const classes = useStyles()
  const readings = useSelector(surveySlice.selectors.selectReadings)

  const readMore = () => {
    window.open(readings[link], '_blank')
  }

  return (
    <Paper className={classes.root}>
      <div onClick={readMore} className={classes.imageBox} style={{ backgroundColor: color, cursor: 'pointer' }}>
        <img onClick={readMore} src={image} alt={`${title} cover`} height='194px' style={{ cursor: 'pointer' }} />
      </div>
      <div className={classes.textBox}>
        <Typography variant='h3'>{title}</Typography>
        <Typography className={classes.text} variant='body1'>
          {text}
        </Typography>

      </div>
      <div className={classes.footer}>

        <Button
          className={classes.footerText}
          variant='text'
          data-test={`lnk-readMore-${idx}`}
          onClick={readMore}
        >
          Read more
        </Button>
      </div>
    </Paper>
  )
}

export default DashboardReading
