import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { LPBLink } from '../../components'
import { useStyles } from './style'

const LPDataRow = () => {
  const classes = useStyles()

  const handleEthical = () => {
    window.open('/ethical')
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.darkContainer}>
        <Grid item xs={12}>
          <Typography variant='h2' style={{ textAlign: 'center', marginBottom: 40, marginTop: 64 }}>What happens to your data</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant='h3' style={{ marginBottom: 8 }}>We're commited to protecting our participants' rights</Typography>
          <Typography>Your data is used exclusively for research purposes. It’s stored in a GDPR-compliant UK database, because anonymity and confidentiality are of the utmost importance to us. This is reflected in our <LPBLink to='/privacy-policy' target='_blank'>privacy policy</LPBLink> and our <LPBLink to='/terms-and-conditions' target='_blank'>terms and conditions</LPBLink>.</Typography>
        </Grid>
        <Grid item md={6}>
          <div className={classes.wellDoneImg}>
            <img src='/img/well-done.png' alt='What happens to your data first' className={classes.image} />
          </div>
        </Grid>
        <Grid item md={6}>
          <div className={classes.wellDoneImg2}>
            <img src='/img/survey-cover-3.png' alt='What happens to your data second' className={classes.image} />
          </div>
        </Grid>
        <Grid item md={6}>
          <Typography variant='h3' style={{ marginBottom: 8 }}>We support the the L&OD community through our research</Typography>
          <Typography>The data we collect through the LPB enables us to develop and conduct industry-leading research, from which we produce free reports on learning trends, as well as in-depth topical reports to provide insights and practical advice that make an impact. So by taking the LPB, you support not only us, but also your peers and the wider community.</Typography>
          <Button disableElevation variant='contained' color='secondary' style={{ width: 'fit-content', margin: '24px auto 0' }} onClick={handleEthical}>Read our ethical statement</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LPDataRow
