import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24
  },
  container: {
    padding: '0 100px 70px',
    background: 'linear-gradient(0deg, #EEEFF1 0%, rgba(238, 239, 241, 0) 100%)'
  },
  lightContainer: {
    padding: '0 100px 70px'
  },
  footerContainer: {
    padding: '0 100px',
    alignItems: 'center'
  },
  darkContainer: {
    padding: '0 100px 70px',
    background: '#EEEFF1'
  },
  blueContainer: {
    padding: '0 100px 70px',
    background: '#006FB7',
    color: 'white'
  },
  logoContainer: {
    width: '254px',
    height: '57px',
    marginLeft: '16px'
  },
  image: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  heading1: {
    fontSize: 48
  },
  introText: {
    fontSize: 20
  },
  topBtn: {
    marginLeft: 16
  },
  gapsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '700px'
  },
  gapsImg: {
    height: '360px',
    marginLeft: '24px'
  },
  gapsFooter: {
    margin: '64px auto 24px',
    textAlign: 'center'
  },
  logosRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 300px'
  },
  helpContainer: {
    marginBottom: 40
  },
  helpImg: {
    height: '400px',
    marginLeft: '24px'
  },
  worksContainer: {
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  worksTitle: {
    marginBottom: 8
  },
  worksText: {
    maxWidth: '300px',
    textAlign: 'center'
  },
  wellDoneImg: {
    height: '140px',
    marginLeft: '24px',
    marginBottom: 94
  },
  wellDoneImg2: {
    height: '190px',
    marginBottom: 94
  },
  tickMark: {
    display: 'flex',
    marginBottom: 18
  },
  whiteBtn: {
    background: 'white',
    color: '#006FB7'
  },
  accordionContainer: {
    maxWidth: '900px',
    margin: '0 auto'
  },
  download: {
    textDecoration: 'underline',
    color: '#006FB7',
    textAlign: 'center',
    marginTop: 8,
    cursor: 'pointer'
  },
  footerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  footerSocial: {
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    padding: '24px 24px 8px',
    height: '100%'
  },
  completerResultContainer: {
    display: 'flex',
    marginBottom: '16px'
  },
  quoteBox: {
    maxWidth: '900px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center'
  },
  quoteText: {
    marginLeft: '14px',
    display: 'flex',
    flexDirection: 'column'
  }
}))
