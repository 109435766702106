const thirdGoalsSection = {
  name: 'Vision, resources and impact - Continued p5',
  elements: [
    {
      type: 'customslider',
      name: 'text1',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '5. Qualifications gained by employees have increased by:'
    },
    {
      type: 'customslider',
      name: 'text2',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '6. Customer satisfaction has improved by:'
    },
    {
      type: 'customslider',
      name: 'text3',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '7. Employee satisfaction has improved by:'
    },
    {
      type: 'customslider',
      name: 'text4',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '8. Our ability to manage change has improved by:'
    },
    {
      type: 'customslider',
      name: 'text6',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '9. Employee turnover has decreased by:'
    }

  ]
}

export default thirdGoalsSection
