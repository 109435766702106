import { StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
})

export default styles
