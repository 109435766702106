import React, { useLayoutEffect, useRef, useEffect } from 'react'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { useStyles } from './style'
import { drawChart } from './draw.js'

const chartData = [
  {
    name: 'Stage 1',
    values: [
      { date: 2021, value: 54 },
      { date: 2022, value: 56 },
      { date: 2023, value: 51 }
    ],
    color: '#4ABFF8'
  },
  {
    name: 'Stage 2',
    values: [
      { date: 2021, value: 23 },
      { date: 2022, value: 22 },
      { date: 2023, value: 23 }
    ],
    color: '#0095E5'
  },
  {
    name: 'Stage 3',
    values: [
      { date: 2021, value: 13 },
      { date: 2022, value: 12 },
      { date: 2023, value: 15 }
    ],
    color: '#0071BD'
  },
  {
    name: 'Stage 4',
    values: [
      { date: 2021, value: 10 },
      { date: 2022, value: 11 },
      { date: 2023, value: 11 }
    ],
    color: '#03519B'
  }
]

const PercentagesChart = ({ id = null, forPdf = false, currentSeason }) => {
  const isXSScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isMDScreen = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'))
  const isLGScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const screenSize = (isXSScreen && 'xs') || (isMDScreen && 'md') || (isLGScreen && 'lg')
  const classes = useStyles()
  const ref = useRef()
  const containerRef = useRef()
  const [chartWidth, setDimension] = React.useState(null)
  useLayoutEffect(() => {
    if (ref.current) {
      setDimension(Math.round(ref.current.offsetWidth))
    }
  }, [])

  const chartDataBySeason = chartData.map(data => {
    const value = data.values.find(value => value.date === currentSeason).value
    return {
      name: data.name,
      value,
      color: data.color
    }
  })

  useEffect(() => {
    if (chartWidth && ref.current) {
      drawChart(chartDataBySeason, screenSize, ref)
    }
  }, [screenSize, chartWidth, chartDataBySeason])

  return (
    <div ref={containerRef} className={forPdf ? classes.rootForPdf : classes.root} id={id}>
      <div ref={ref} className={forPdf ? classes.svgContainerForPdf : classes.svgContainer} />

      <Grid container spacing={1} className={forPdf ? classes.footerForPdf : classes.footer}>
        {chartDataBySeason.map((data, idx) => (
          <Grid key={idx} item xs={6} className={forPdf ? classes.labelBoxForPdf : classes.labelBox}>
            <span style={{ backgroundColor: data.color }} className={forPdf ? classes.labelForPdf : classes.label} />
            <Typography variant='body1'>{data.name}: {data.value}%</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default PercentagesChart
