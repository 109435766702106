const organisationSection = {
  name: 'You and your organization - Continued p1',
  elements: [
    {
      type: 'checkbox',
      name: 'Programme',
      title: '9. Does your organisation operate any of the following (if none applies, skip question):',
      hideNumber: true,
      choices: [
        'apprenticeship programme?',
        'graduate/Master\'s/doctoral programme?',
        'academy model for organisation-wide learning?'
      ]
    },
    {
      type: 'text',
      name: 'Academy Model',
      visibleIf: '{Programme} = "academy model for organisation-wide learning?"',
      title: '9a. Please tell us more about your academy approach',
      hideNumber: true,
      titleLocation: 'left'
    },
    {
      type: 'radiogroup',
      name: 'Structure',
      title: '10. How is your L&D function structured?',
      hideNumber: true,
      choices: [
        'central L&D team (part of or independent of HR);',
        'no central L&D team (e.g., part of a business unit);',
        'no internal L&D function (e.g., our services are outsourced);'
      ]
    },
    {
      type: 'radiogroup',
      name: 'Strategy',
      title: '11. How long have you had your current learning strategy in place?',
      hideNumber: true,
      choices: [
        {
          value: 'item1',
          text: 'We don\'t have a learning strategy'
        },
        '0-3 months',
        '4-7 months',
        '8-12 months',
        '13-24 months',
        '24+ months'
      ]
    },
    {
      type: 'html',
      name: 'Strategy explanation',
      visibleIf: '{Strategy} = "item1"',
      hideNumber: true,
      html: 'Whilst the Learning Performance Benchmark is designed for organizations with a learning strategy in place, we still recommend completing it even if you don’t have one. 97% of L&D leaders that do, say it has given them new ideas, challenged their thinking, and helped them to improve.'
    },
    {
      type: 'text',
      name: 'Strategy details',
      visibleIf: '{Strategy} anyof ["0-3 months", "4-7 months", "8-12 months", "13-24 months"]',
      title: '11a. What was the key driver for this learning strategy?',
      hideNumber: true,
      titleLocation: 'left'
    },
    {
      type: 'comment',
      name: 'Goal',
      hideNumber: true,
      title: '12. Do you have a specific goal you would like to achieve by completing the Learning Performance Benchmark? If so, please note it down here.'
    },
    {
      type: 'comment',
      name: 'Main_challenge',
      hideNumber: true,
      title: '13. What is the primary challenge you will be aiming to solve with your L&D strategy over the next 12 months?'
    }
  ]
}

export default organisationSection
