import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import styles from './style'

const PDFFooter = ({ pageNumber, truncate = false }) => (
  <View style={!truncate ? styles.view : styles.alternateView} wrap={false}>
    <Text style={styles.text}>{new Date().toLocaleDateString('en-UK')}</Text>
    <Text style={styles.text}>{pageNumber}</Text>
  </View>
)
export default PDFFooter
