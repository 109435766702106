import { Grid, Paper, Typography, Button } from '@material-ui/core'
import { useStyles } from './style'
import React from 'react'
const ReportCard = ({ reportUrl }) => {
  const handleDownloadReport = () => {
    startReportDownload()
  }
  const startReportDownload = () => {
    setTimeout(() => {
      if (reportUrl !== undefined) {
        window.open(reportUrl)
      } else {
        startReportDownload()
      }
    }, 500)
  }
  const classes = useStyles()
  return (
    <Paper className={classes.homePaper}>
      <Typography data-test='homePageCard1' variant='h2' className={classes.cardTitle}>
        Our latest report
      </Typography>
      <Grid container className={classes.homePaperContent}>
        <Grid item xs={7} xl={8} className={classes.fixHeight}>

          <Typography>
            The Learning Performance Benchmark enables us to develop and
            conduct industry-leading research. By completing, you
            support not only us, but also your peers and the wider
            community.
          </Typography>
          <br />
          <Typography display='inline'>
            Check out our brand new report,{' '}
            <b>Is your learning culture keeping pace with rapid digitalization?</b>
          </Typography>

        </Grid>
        <Grid item xs={5} xl={4}>
          <img onClick={handleDownloadReport} className={classes.homePaperImage} src='/img/home-download-2022.png' alt='Is your learning culture keeping pace with rapid digitalization?' />
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <Button
          className={classes.footerButton}
          reportUrl={reportUrl}
          data-test='lnk-firstCard'
          variant='text'
          onClick={handleDownloadReport}
        >
          Download report
        </Button>
      </div>
    </Paper>
  )
}

export default ReportCard
