import React from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import { useStyles } from './style'

const TeamUpgradeHowItWorks = () => {
  const classes = useStyles()

  return (
    <Grid item lg={5} md={12}>
      <Paper variant='outlined' className={classes.paper}>
        <Typography variant='h3'>How it works</Typography>
        <div className={classes.paperRow}>
          <img width='48px' height='48px' src='/img/team-how-it-works-add.png' alt='Team how it works add icon' />
          <span style={{ 'margin-left': '24px' }}>
            <Typography className={classes.emphasis}>1. Add your team</Typography>
            <Typography>Each team member will receive an email with a link to set up their own account.</Typography>
          </span>
        </div>
        <hr className={classes.hr} />
        <div className={classes.paperRow}>
          <img width='48px' height='48px' src='/img/team-how-it-works-complete.png' alt='Team how it works complete icon' />
          <span style={{ 'margin-left': '24px' }}>
            <Typography className={classes.emphasis}>2. Complete individually</Typography>
            <Typography>Allow your team to complete their review and get their individual results.</Typography>
            <Typography className={classes.helper}>(Don't worry if you, or any of your team, have already submitted a review - your existing scores can be included in the team results!)</Typography>
          </span>
        </div>
        <hr className={classes.hr} />
        <div className={classes.paperRow}>
          <img width='48px' height='48px' src='/img/team-how-it-works-results.png' alt='Team how it works results icon' />
          <span style={{ 'margin-left': '24px' }}>
            <Typography className={classes.emphasis}>3. See aggregated results</Typography>
            <Typography>Close the review and see anonymised, aggregated results. Your team will have access too!</Typography>
          </span>
        </div>
      </Paper>
    </Grid>
  )
}

export default TeamUpgradeHowItWorks
