import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  RegisterHeader,
  RegisterFooter,
  PageAlert,
  RegisterCarousel,
  LoginForm
} from '../../components'
import Grid from '@material-ui/core/Grid'
import { useMediaQuery } from '@material-ui/core'
import { useStyles } from './style'
import { loginSlice } from '../../reducers'

const Login = () => {
  const classes = useStyles()
  const search = new URLSearchParams(useLocation().search)
  const dispatcher = useDispatch()
  const showVerifyAlert = useSelector(loginSlice.selectors.selectShowVerifyAlert)
  const showVerifyError = useSelector(loginSlice.selectors.selectShowVerifyError)
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const teamId = search.get('teamId') || ''
  const premium = search.get('premium') || ''

  if (showVerifyAlert) {
    setTimeout(() => {
      dispatcher(loginSlice.actions.setShowVerifyAlert(false))
    }, 7000)
  }
  if (showVerifyError) {
    setTimeout(() => {
      dispatcher(loginSlice.actions.setShowVerifyError(false))
    }, 7000)
  }

  const LeftSide = () => {
    return (
      <>
        <Grid item xs={12}>
          <RegisterHeader />
        </Grid>
        <Grid item xs={12}>
          <LoginForm teamId={teamId} premiumUser={premium} />
        </Grid>
        <Grid item xs={12}>
          <RegisterFooter />
        </Grid>
        {showVerifyAlert && (
          <PageAlert text='Your email address was verified. Log in to access the portal!' />
        )}
        {showVerifyError.show && (
          <PageAlert error text={`There was a problem verifying your email address. Please try again and contact lpbenchmark@mindtools.com if the error persists. Error code: ${showVerifyError.error.code}`} />
        )}
      </>
    )
  }

  const RightSide = () => {
    return (
      <Grid item xs={12}>
        <RegisterCarousel />
      </Grid>
    )
  }

  return (
    <>
      <Grid className={classes.root} container spacing={0}>
        <Grid container item lg={7} xl={7} md={12} spacing={0}>
          <LeftSide />
        </Grid>
        {!matches &&
          <Grid container item lg={5} xl={5} md={12} spacing={0}>
            <RightSide />
          </Grid>}

      </Grid>
    </>
  )
}

export default Login
