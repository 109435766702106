import React from 'react'
import { Grid, Typography, Card, CardContent, CardActions, Button, useMediaQuery } from '@material-ui/core'

const cardsInfo = [
  {
    header: 'Speak to one of our expert analysts',
    description: 'If you are interested in finding out more about how we can help you on your jorney to becoming a high-impact learning organisation, contact our in-house analyst services.',
    isEmail: true,
    link: 'insights@mindtools.com'
  },
  {
    header: 'Join the Learning Innovation Group',
    description: 'Join a dynamic, evidence-led, and practical community of practice for senior learning leaders. For over 10 years we have been running impactful workshops, seminars, podcasts, and much more.',
    link: 'https://mindtools.com/business/products/lig'
  },
  {
    header: 'Get bespoke custom learning',
    description: 'Every organistaion has its own unique challenges and culture. Over the years we`ve developed custom solutions - from the everyday to the plain obscure - for organisations across the globe.',
    link: 'https://mindtools.com/business/products/custom-learning'
  }
]

const DashboardMaturirySolutions = () => {
  const matchesXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))

  const handleEmailClick = (e) => {
    window.open('mailto:insights@mindtools.com', '_blank')
    e.preventDefault()
  }

  const handleLinkClick = (link) => {
    window.open(link, '_blank')
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={10} lg={10} xl={12}>
          <Typography variant='h2'>Other solutions we provide</Typography>
        </Grid>
        <Grid item xs={2} lg={2} style={{ display: matchesXl ? 'none' : '' }} />
      </Grid>
      <Grid container spacing={3}>
        {cardsInfo.map((cardInfo, idx) => (
          <Grid item xs={12} md={4} key={idx}>
            <Card style={{ height: '100%', position: 'relative' }}>
              <CardContent>
                <Typography variant='h3' gutterTop gutterBottom>{cardInfo.header}</Typography>
                <Typography variant='body1' gutterTop gutterBottom style={{ marginBottom: 36 }}>{cardInfo.description}</Typography>
              </CardContent>
              <CardActions>
                {cardInfo.isEmail
                  ? (
                    <Button
                      style={{ position: 'absolute', bottom: 12, left: 12 }}
                      size='small'
                      onClick={handleEmailClick}
                    > Email us
                    </Button>
                    )
                  : (
                    <Button
                      style={{ position: 'absolute', bottom: 12, left: 12 }}
                      size='small'
                      onClick={() => handleLinkClick(cardInfo.link)}
                    >Learn more
                    </Button>
                    )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default DashboardMaturirySolutions
