import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    padding: '24px',
    minHeight: '180px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  label: {
    margin: '0 5px',
    display: 'block',
    width: 10,
    height: 10,
    borderRadius: '50%'
  },
  labelBox: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  }
}))
