// This dictionary contains 67 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { surveyLocalization } from "survey-core";

export var georgianSurveyStrings = {
  pagePrevText: "უკან",
  pageNextText: "შემდეგ",
  completeText: "დასრულება",
  // previewText: "Preview",
  // editText: "Edit",
  // startSurveyText: "Start",
  otherItemText: "სხვა (გთხოვთ მიუთითეთ)",
  // noneItemText: "None",
  // selectAllItemText: "Select All",
  progressText: "გვერდი {0} / {1}",
  // indexText: "{0} of {1}",
  // panelDynamicProgressText: "{0} of {1}",
  // questionsProgressText: "Answered {0}/{1} questions",
  emptySurvey: "არცერთი კითხვა არ არის.",
  completingSurvey: "გმადლობთ კითხვარის შევსებისთვის!",
  // completingSurveyBefore: "Our records show that you have already completed this survey.",
  loadingSurvey: "ჩატვირთვა სერვერიდან...",
  placeholder: "არჩევა...",
  // ratingOptionsCaption: "Tap to rate here...",
  // value: "value",
  requiredError: "გთხოვთ უპასუხეთ კითხვას.",
  // requiredErrorInPanel: "Response required: answer at least one question.",
  // requiredInAllRowsError: "Response required: answer questions in all rows.",
  numericError: "პასუხი უნდა იყოს რიცხვი.",
  // minError: "The value should not be less than {0}",
  // maxError: "The value should not be greater than {0}",
  textMinLength: "გთხოვთ შეიყვანეთ არანაკლებ {0} სიმბოლო.",
  // textMaxLength: "Please enter no more than {0} character(s).",
  // textMinMaxLength: "Please enter at least {0} and no more than {1} characters.",
  // minRowCountError: "Please fill in at least {0} row(s).",
  minSelectError: "გთხოვთ აირჩიეთ არანაკლებ {0} ვარიანტი.",
  maxSelectError: "გთხოვთ აირჩიეთ არაუმეტეს {0} ვარიანტი.",
  numericMinMax: "'{0}' უნდა იყოს მეტი ან ტოლი, ვიდრე {1}, და ნაკლები ან ტოლი ვიდრე {2}",
  numericMin: "'{0}' უნდა იყოს მეტი ან ტოლი ვიდრე {1}",
  numericMax: "'{0}' უნდა იყოს ნაკლები ან ტოლი ვიდრე {1}",
  invalidEmail: "გთხოვთ შეიყვანოთ ელ. ფოსტის რეალური მისამართი.",
  // invalidExpression: "The expression: {0} should return 'true'.",
  // urlRequestError: "The request returned error '{0}'. {1}",
  // urlGetChoicesError: "The request returned empty data or the 'path' property is incorrect",
  // exceedMaxSize: "The file size should not exceed {0}.",
  // otherRequiredError: "Response required: enter another value.",
  // uploadingFile: "Your file is uploading. Please wait several seconds and try again.",
  // loadingFile: "Loading...",
  // chooseFile: "Choose file(s)...",
  // noFileChosen: "No file chosen",
  // fileDragAreaPlaceholder: "Drop a file here or click the button below to load the file.",
  // confirmDelete: "Do you want to delete the record?",
  // keyDuplicationError: "This value should be unique.",
  // addColumn: "Add Column",
  // addRow: "Add Row",
  // removeRow: "Remove",
  // emptyRowsText: "There are no rows.",
  // addPanel: "Add new",
  // removePanel: "Remove",
  // choices_Item: "item",
  // matrix_column: "Column",
  // matrix_row: "Row",
  // multipletext_itemname: "text",
  // savingData: "The results are being saved on the server...",
  // savingDataError: "An error occurred and we could not save the results.",
  // savingDataSuccess: "The results were saved successfully!",
  // saveAgainButton: "Try again",
  // timerMin: "min",
  // timerSec: "sec",
  // timerSpentAll: "You have spent {0} on this page and {1} in total.",
  // timerSpentPage: "You have spent {0} on this page.",
  // timerSpentSurvey: "You have spent {0} in total.",
  // timerLimitAll: "You have spent {0} of {1} on this page and {2} of {3} in total.",
  // timerLimitPage: "You have spent {0} of {1} on this page.",
  // timerLimitSurvey: "You have spent {0} of {1} in total.",
  // clearCaption: "Clear",
  // signaturePlaceHolder: "Sign here",
  // chooseFileCaption: "Choose file",
  // removeFileCaption: "Remove this file",
  // booleanCheckedLabel: "Yes",
  // booleanUncheckedLabel: "No",
  // confirmRemoveFile: "Are you sure that you want to remove this file: {0}?",
  // confirmRemoveAllFiles: "Are you sure that you want to remove all files?",
  // questionTitlePatternText: "Question Title",
  // modalCancelButtonText: "Cancel",
  // modalApplyButtonText: "Apply",
  // filterStringPlaceholder: "Type to search...",
  // emptyMessage: "No data to display",
  // noEntriesText: "There are no entries yet.\nClick the button below to add a new entry.",
  // more: "More"
};

surveyLocalization.locales["ka"] = georgianSurveyStrings;
surveyLocalization.localeNames["ka"] = "ქართული";
