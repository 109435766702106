const contentSection = {
  name: 'Challenges p1',
  elements: [
    {
      type: 'matrix',
      name: 'Barrier Content',
      title: '4. Which of the following challenges related to content have you faced in the past 12 months?',
      hideNumber: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Not at all concerned'
        },
        {
          value: 'Column 2',
          text: 'Somewhat concerned'
        },
        {
          value: 'Column 3',
          text: 'Extremely concerned'
        }
      ],
      rows: [
        'Cost of setup, development, and maintenance of tech solutions',
        'Lack of attractive, high-quality digital content that supports our business goals',
        'Past experience of digital learning hasn’t met our expectations',
        'Digital learning is too generic and not sufficiently tailored to our needs'
      ]
    }
  ]
}

export default contentSection
