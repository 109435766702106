import React, { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Auth from '@aws-amplify/auth'
import { useSession } from '@emerald-works/react-auth'
import { ExpandMore } from '@material-ui/icons'
import { datadogRum } from '@datadog/browser-rum'

import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Paper,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  ClickAwayListener,
  IconButton
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { userSlice } from '../../reducers'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './style'
import { SkipNavigation } from '..'

const AppToolbar = ({
  isMobileScreen,
  navBarOpen,
  openNavBar,
  closeNavBar,
  hideHamburger = false
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const session = useSession()
  const dispatcher = useDispatch()

  const { user } = session
  const anchorRef = React.useRef(null)
  const anchorEl = React.useRef(null)

  const [open, setOpen] = React.useState(false)
  const [navOpen, setNavOpen] = React.useState(false)
  const prevOpen = useRef(open)
  const prevNavOpen = useRef(navOpen)

  const featureToggles = useSelector(userSlice.selectors.selectFeatureToggles)

  const getDevTools = () => {
    if (!user) return false

    if (!featureToggles) return false
    if (!featureToggles['lpb-dev-tools']?.active || !featureToggles['lpb-dev-tools']?.strategies?.length) return false
    const { userIds } = featureToggles['lpb-dev-tools'].strategies[0].parameters
    const list = userIds.split(',') || []
    if (list.includes(user.attributes.email)) return true
    return false
  }

  const isDevToolsEnabled = getDevTools()
  const toggleMenu = () => {
    setNavOpen((prevNavOpen) => !prevNavOpen)
  }

  const closeMenu = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return
    }

    setNavOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleNavListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setNavOpen(false)
    }
  }

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (prevNavOpen.current === true && navOpen === false) {
      anchorEl.current.focus()
    }

    prevNavOpen.current = navOpen
  }, [navOpen])

  const handleLogout = async () => {
    try {
      await Auth.signOut()
      await session.updateSessionContext()
      localStorage.clear()
      datadogRum.removeUser()
      navigate('/welcome')
    } catch (e) {
      datadogRum.addError(e)
      console.log('Signout error: ', e)
    }
  }

  const pathname = window.location.pathname.split('/')[1]
  const accountPage = pathname === 'account'
  const contactPage = pathname === 'contact'
  const devToolsPage = pathname === 'dev-tools'

  return (
    <div className={classes.root} tabIndex='-1'>
      <nav name='top navigation' aria-label='top navigation'>

        <AppBar position='fixed'>

          <Toolbar className={classes.bar}>
            <SkipNavigation
              isMobileScreen={isMobileScreen}
              openNavBar={openNavBar}
              closeNavBar={closeNavBar}
              toggleMenu={toggleMenu}
              navBarOpen={navBarOpen}
              hideHamburger={hideHamburger}
            />
            <Grid container direction='row' justifyContent='space-between'>
              {isMobileScreen && (
                <Grid item xs>
                  <IconButton
                    onClick={!navBarOpen ? openNavBar : closeNavBar}
                    className={navBarOpen ? classes.navBarOpen : null}
                  >
                    {!hideHamburger && <MenuIcon className={navBarOpen ? classes.navBarOpen : null} />}
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={6}>
                <div className={!isMobileScreen ? classes.box : classes.boxMD}>
                  <div className={!isMobileScreen ? classes.imageContainer : classes.imageContainerMD}>

                    <img
                      tabIndex={0}
                      src='img/mindtools-lpb-logo_60.png'
                      alt='MindTools for Business Home'
                      className={classes.image}
                      onClick={() => {
                        dispatcher(userSlice.actions.setMenuLevel(0))
                        dispatcher(userSlice.actions.setActiveMenuSection('home'))
                        navigate('/')
                      }}
                      data-test='btn-headerLogo'
                    />
                  </div>

                </div>
              </Grid>
              {isMobileScreen && (
                <Grid item xs>
                  <IconButton
                    className={classes.navMenu}
                    ref={anchorEl}
                    aria-controls={navOpen ? 'nav-menu' : undefined}
                    aria-haspopup='true'
                    onClick={toggleMenu}
                  >
                    <MoreVertIcon className={navOpen ? classes.navMenuOpen : null} />
                  </IconButton>
                  <Popper open={navOpen} anchorEl={anchorEl.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={closeMenu}>
                            <MenuList autoFocusItem={navOpen} id='nav-menu' onKeyDown={handleNavListKeyDown}>
                              <MenuItem onClick={() => navigate('/account')}>Account</MenuItem>
                              <MenuItem onClick={() => navigate('/contact')}>Contact</MenuItem>
                              <MenuItem className={`${classes.link} cky-banner-element`}>Cookies</MenuItem>
                              <MenuItem onClick={() => navigate('/privacy-policy')}>Privacy Policy</MenuItem>
                              <MenuItem onClick={() => navigate('/terms-and-conditions')}>Terms & Conditions</MenuItem>
                              <MenuItem onClick={() => navigate('/terms-of-sale')}>Terms of Sale</MenuItem>
                              <MenuItem onClick={() => navigate('/cookies-policy')}>Cookies Policy</MenuItem>
                              <MenuItem onClick={() => navigate('/ethical')}>Ethical Standards</MenuItem>
                              {isDevToolsEnabled && (
                                <MenuItem onClick={() => navigate('/dev-tools')}>Dev</MenuItem>
                              )}
                              <MenuItem onClick={handleLogout}>Log out</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              )}
            </Grid>
            {user && !isMobileScreen && (
              <>
                {isDevToolsEnabled && (
                  <Button
                    className={!devToolsPage ? classes.link : classes.activeLink}
                    onClick={() => navigate('/dev-tools')}
                    data-test='btn-toDevTools'
                  >
                    Dev
                  </Button>
                )}
                <Button
                  className={!accountPage ? classes.link : classes.activeLink}
                  onClick={() => navigate('/account')}
                  data-test='btn-toAccount'
                >
                  Account
                </Button>
                <Button
                  className={!contactPage ? classes.link : classes.activeLink}
                  onClick={() => navigate('/contact')}
                  data-test='btn-toContact'
                >
                  Contact
                </Button>
                <Button
                  className={`${classes.link} cky-banner-element`}
                  data-test='btn-openCookies'
                >
                  Cookies
                </Button>
                <Button
                  className={classes.adjustedLink}
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup='true'
                  onClick={handleToggle}
                  data-test='btn-legal'
                >
                  Privacy
                  <ExpandMore />
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                            <MenuItem onClick={() => window.open('/privacy-policy')}>Privacy Policy</MenuItem>
                            <MenuItem onClick={() => window.open('/terms-and-conditions')}>Terms & Conditions</MenuItem>
                            <MenuItem onClick={() => window.open('/terms-of-sale')}>Terms of Sale</MenuItem>
                            <MenuItem onClick={() => window.open('/cookies-policy')}>Cookies Policy</MenuItem>
                            <MenuItem onClick={() => window.open('/ethical')}>Ethical Standards</MenuItem>
                            {isDevToolsEnabled && (
                              <MenuItem onClick={() => navigate('/dev-tools')}>Dev</MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Button
                  className={classes.link}
                  onClick={handleLogout}
                  data-test='btn-logout'
                >
                  Logout
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
      </nav>
    </div>
  )
}

export default React.memo(AppToolbar)
