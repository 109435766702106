import { StyleSheet } from '@react-pdf/renderer'
const styles = StyleSheet.create({
  link: {
    color: '#006fb7',
    marginVertical: '5px'
  },
  linksBox: {
    marginTop: 5
  }
})
export default styles
