import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    height: '100vh'
  },
  collapseBtnHide: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '1px',
    borderRadius: '50%',
    padding: '2px',
    backgroundColor: '#006fb7',
    '&:hover': {
      backgroundColor: '#024e95'
    },
    transition: 'left 0.25s ease-in-out'
  },
  collapseBtn: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '282px',
    backgroundColor: '#006fb7',
    borderRadius: '50%',
    padding: '2px',
    '&:hover': {
      backgroundColor: '#024e95'
    },
    transition: 'left 0.25s ease-in-out'
  },
  collapseIcon: {
    '&.MuiSvgIcon-root': {
      fill: 'white'
    }
  },
  main: {
    transition: 'margin-left 0.25s ease-in-out',
    padding: '48px',
    marginTop: '56px',
    background: '#FFF'
  },
  mainMD: {
    padding: '24px 48px',
    transition: 'margin-left 0.25s ease-in-out',
    marginTop: '64px',
    background: '#FFF',
    marginLeft: 70,
    width: '100%'
  },
  description: {
    marginBottom: 40
  },
  helper: {
    fontSize: 12,
    marginTop: 8
  },
  upgradeBox: {
    marginTop: 60,
    marginBottom: 21,
    background: '#EEEFF1',
    padding: '26px 24px'
  },
  imgBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  paper: {
    padding: 24
  },
  paperRow: {
    display: 'flex',
    padding: '16px 0'
  },
  emphasis: {
    fontSize: 16,
    fontWeight: 700
  },
  hr: {
    borderColor: 'rgba(37, 55, 70, 0.12)'
  },
  faqBox: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 64
  }
}))
