import reducer, * as actions from './slice'
import * as selectors from './selector'
import name from './name'
import * as eventBus from './event-bus'

const survey = {
  reducer,
  actions,
  selectors,
  name,
  eventBus
}

export default survey
