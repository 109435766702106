import React from 'react'
import {
  Dialog,
  Typography,
  Button
} from '@material-ui/core'
import { useStyles } from './style'

const CloseReviewBlockedDialog = ({
  open,
  handleOk
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <Typography variant='h3' className={classes.header}>Insufficient results to close review</Typography>
        <Typography className={classes.text}>The LPB requires at least 3 members to have submitted their review before showing the team results. This ensures the results remain as anonymous as possible.</Typography>
        <Typography className={classes.text}>Nudge your team to submit their review so you can gain access.</Typography>
        <div className={classes.buttons}>
          <Button className={classes.rightButton} variant='contained' color='secondary' onClick={handleOk}>Ok</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default CloseReviewBlockedDialog
