const secondOrganisationGoalsSection = {
  name: 'Current goals and vision - Organisation p2',
  elements: [
    {
      type: 'matrix',
      name: 'Driver_11',
      title: '1 (continued). Which of the following benefits has your learning strategy delivered to the organisation in the past 12 months?',
      hideNumber: true,
      customMatrixTop: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_11',
          text: 'Managed risk successfully'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_14',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_14',
          text: 'Developed a continual learning culture'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_15',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_15',
          text: 'Harmonised learning across our organisation'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_17',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_17',
          text: 'Built organisational capabilities to solve problems'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_18',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_18',
          text: 'Facilitated new ways of working'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_19',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_19',
          text: 'Supported company mergers and acquisitions'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_20',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_20',
          text: 'Strengthened organisational growth in new markets'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_21',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_21',
          text: 'Utilised internal data to future-proof the organisation'
        }
      ]
    }
  ]
}

export default secondOrganisationGoalsSection
