import React from 'react'
import { Typography } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { useStyles } from './style'

const TimeEstimate = ({ time }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AccessTimeIcon />
      <Typography className={classes.text}>APPROX {time} MINUTES TO COMPLETE</Typography>
    </div>
  )
}

export default TimeEstimate
