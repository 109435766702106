import name from './name'

const selectSliceData = state => state[name]
const selectPage = state => selectSliceData(state).page
const selectInnerPage = state => selectSliceData(state).innerPage
const selectPDFDownloading = state => selectSliceData(state).pdfDownloading
const selectReportImagesLoaded = state => selectSliceData(state).reportImagesLoaded

export {
  selectPage,
  selectInnerPage,
  selectPDFDownloading,
  selectReportImagesLoaded
}
