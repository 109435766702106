import { StyleSheet, Font } from '@react-pdf/renderer'
import Matter from '../../../../fonts/Matter-Regular.ttf'

Font.register({ family: 'Matter', src: Matter })

const styles = StyleSheet.create({
  card: {
    width: '100%',
    height: '100%',
    border: '1px solid #E9EAEB',
    borderRadius: 5,
    marginVertical: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row'
  },
  container: {

  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%'
  },
  chartBox: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 15,
    width: '20%'
  }

})

export default styles
