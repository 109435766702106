import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '22px'
  },
  text: {
    paddingStart: '6px',
    fontSize: '12px',
    fontWeight: 700
  }
}))
