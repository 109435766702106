const thirdTechnologySection = {
  name: 'Technology, tools and access p2',
  elements: [
    {
      type: 'matrix',
      name: 'Tech Mix',
      title: '3. Which of these other digital technologies are you using, planning to use or experimenting with?',
      hideNumber: true,
      columns: [
        {
          value: 'Column 1',
          text: 'We use this now'
        },
        {
          value: 'Column 2',
          text: 'We\'re just experimenting / trialling it'
        },
        {
          value: 'Column 3',
          text: 'We plan to use this in the next 12 months'
        },
        {
          value: 'Column 4',
          text: 'We do not use this'
        }
      ],
      rows: [
        'File- and presentation- sharing applications (e.g., SharePoint, Google Docs, Dropbox)',
        'Collaborative project tools (e.g., Trello, Asana, JIRA)',
        'Mobile devices (smartphones, tablets)',
        'Wearable technologies (e.g., GoPro, smartwatches, productivity wristbands)',
        'Course-authoring tools (e.g., Lectora, Captivate, Elucidat, Shift, Articulate, Gomo)',
        'Massive online open courses (e.g., Coursera, Udemy, edX, FutureLearn)',
        'Learning record store (e.g., to use experience API/tin can)',
        'Achievement goals, badges (e.g., Mozilla OpenBadges)',
        'Game-based elements (e.g., leaderboards, levels, scores)',
        'AI tools (e.g., intelligent tutoring systems, virtual assistants, chatbots, Alexa, Siri, learnbots)',
        'Blockchain (e.g., verify credentials, issue certificates)',
        'Online journals',
        'Intranet'
      ]
    },
    {
      type: 'checkbox',
      name: 'question25',
      visibleIf: '{Tech Mix.Mobile devices (smartphones, tablets)} anyof ["Column 1", "Column 2"]',
      hideNumber: true,
      title: '3a. How are you using mobile learning?',
      choices: [
        'Provide smartphones that can be used for learning',
        'Provide tablet devices (e.g. iPad) that can be used for learning',
        'Support Bring Your Own Device (BYOD)',
        'Specifically enable your content for mobile devices',
        'Develop content that is platform-independent (e.g. in HTML5)',
        'Create advanced security policies (e.g. leavers/device loss management)',
        'Use apps to specifically support performance at the point of need',
        'Use geo location or context sensitive help',
        'Use QR codes in L&D',
        'Develop your own mobile apps in-house using mobile app development tools (e.g. GoMo, Udutu)',
        'Provide tools to organise and download mobile apps'
      ]
    }
  ]
}

export default thirdTechnologySection
