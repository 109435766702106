import basePalette from './base-palette'
import * as helpers from './helpers'
import { merge as deepMerge } from 'lodash'

const createPalette = (options = {}) => {
  return deepMerge({ ...basePalette, helpers }, options)
}

export {
  createPalette
}
