import React from 'react'
import { useStyles } from './style'
import { Button } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
const Back = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Button variant='text' startIcon={<ArrowBackIos style={{ width: 16, height: 16, position: 'relative', top: 1 }} />} className={classes.button}>
        Back
      </Button>
    </div>
  )
}
export default Back
