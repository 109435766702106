import React from 'react'
import { Page, View, Text, Image } from '@react-pdf/renderer'
import { PDFFooter } from '../../components'
import styles from './style'
import globalStyles from '../../style'
import TechImage from '../../img/resources-title.png'

const TrendsPage1 = () => {
  const dataURL = localStorage.getItem('techTrendsData')

  return (
    <Page size='A4' orientation='landscape'>
      <View style={globalStyles.pageView}>
        <View style={styles.textContainer}>
          <Text style={globalStyles.heading}>Overall participant trends</Text>
          <Text style={globalStyles.introText}>These trends are based on comprehensive data derived from every response to the Learning Performance Benchmark. This informs you about what other organisations are doing, what’s on trend—and whether you’re missing out on anything.</Text>
        </View>
        <View style={{ flexDirection: 'row', width: '100%', height: '100%' }}>

          <View style={styles.tableContainer}>
            <Text style={globalStyles.subheading}>Technology</Text>
            <Text style={globalStyles.body}>What are the most popular tools used to support learning and performance?</Text>
            {!dataURL ? <Text>Table placeholder</Text> : <Image src={dataURL} style={{ objectFit: 'contain', marginTop: 10 }} />}
          </View>
          <View style={styles.imageContainer}>
            <Image src={TechImage} style={{ objectFit: 'contain', marginTop: 100, marginLeft: 10 }} />
          </View>
        </View>
        <PDFFooter pageNumber={15} />
      </View>
    </Page>
  )
}

export default TrendsPage1
