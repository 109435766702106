import React, { useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import MobileStepper from '@material-ui/core/MobileStepper'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Grid, Typography, Button } from '@material-ui/core'
import { useStyles } from './style'

const LPParticipantsRow = () => {
  const classes = useStyles()
  const quotes = [
    {
      text: '[The Learning Performance Benchmark] gave us a benchmark of where we were and what we needed to do... I received action plans and some key steps, so it wasn’t too overwhelming or scary.',
      author: 'Tara Webber',
      role: 'Senior Learning Development Officer, Hertfordshire County Council'
    },
    {
      text: 'There was a part of me that was pleased. I needed the score to be low so I could show the business we were so far beneath where we should have been, and that we really needed to work differently.',
      author: 'Nebel Crowhurst',
      role: 'Head of People Experience, River Island'
    },
    {
      text: 'I actually use the Learning Performance Benchmark as our core principles for what we do, and it gives me the sense of hope, as well as ideas of what we can achieve.',
      author: 'Emma Smith',
      role: 'Head of Talent, FirstPort Limited'
    }
  ]

  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = quotes.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => {
      if (prevActiveStep === 2) return 0
      return prevActiveStep + 1
    })
  }

  const handlePrev = () => {
    setActiveStep(prevActiveStep => {
      if (prevActiveStep === 0) return 2
      return prevActiveStep - 1
    })
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  useEffect(() => {
    setInterval(handleNext, 20000)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.lightContainer}>
        <Grid item xs={12}>
          <Typography variant='h2' style={{ textAlign: 'center', marginBottom: 40, marginTop: 64 }}>What our participants say</Typography>
          <SwipeableViews
            axis='x'
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {quotes.map((quote, idx) => (
              <div key={idx} className={classes.quoteBox}>
                <img src='/img/survey-quote.png' width={30} height={20} alt='quote' />
                <div className={classes.quoteText}>
                  <Typography style={{ color: '#006FB7', marginBottom: 16 }}>{quote.text}</Typography>
                  <Typography style={{ fontSize: 14, fontWeight: 700 }}>{quote.author}</Typography>
                  <Typography style={{ fontSize: 14 }}>{quote.role}</Typography>
                </div>
              </div>
            ))}
          </SwipeableViews>
          <MobileStepper
            style={{ justifyContent: 'center', marginTop: 42 }}
            steps={maxSteps}
            position='static'
            variant='dots'
            activeStep={activeStep}
            nextButton={
              <Button data-test='quoteNext' size='small' onClick={handleNext} aria-label='Quote next button'>
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button data-test='quoteBack' size='small' onClick={handlePrev} aria-label='Quote back button'>
                <KeyboardArrowLeft />
              </Button>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LPParticipantsRow
