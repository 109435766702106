import { StyleSheet, Font } from '@react-pdf/renderer'
import Matter from '../../../../fonts/Matter-Regular.ttf'

Font.register({ family: 'Matter', src: Matter })

const styles = StyleSheet.create({
  text: {
    fontSize: 8,
    fontFamily: 'Matter'
  },
  view: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: '-5px',
    width: '100%',
    height: '5%',
    paddingHorizontal: 30,
    paddingVertical: 5,
    borderTop: '1px solid rgb(200,200,200)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15
  },
  alternateView: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: '1px',
    width: '90%',
    height: '5%',
    paddingHorizontal: 30,
    paddingVertical: 5,
    borderTop: '1px solid rgb(200,200,200)',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})

export default styles
