import React from 'react'
import {
  DashboardSummaryScore,
  DashboardSummaryStage,
  DashboardSummaryTrends
} from '../../components'
import { useStyles } from './style'
import { Grid, Typography } from '@material-ui/core'

const DashboardSummary = ({ score, stage, setDashboardPage, setInnerDashboardPage, isLoading, industry, isTeam }) => {
  const classes = useStyles()
  const latestSeason = score.score.length ? score.score[0].values[0].date : ''

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant='h1'>
          {
            isTeam
              ? 'Team summary'
              : `Summary ${latestSeason}`
          }
        </Typography>
      </Grid>
      <Grid item md={5} lg={5}>
        <DashboardSummaryScore
          setDashboardPage={setDashboardPage}
          setInnerDashboardPage={setInnerDashboardPage}
          score={score}
          isLoading={isLoading}
          industry={industry}
          isTeam={isTeam}
          latestSeason={latestSeason}
        />
      </Grid>
      <Grid item md={7} lg={7}>
        <DashboardSummaryStage
          setDashboardPage={setDashboardPage}
          setInnerDashboardPage={setInnerDashboardPage}
          stage={stage}
          isLoading={isLoading}
          isTeam={isTeam}
        />
        <DashboardSummaryTrends
          setDashboardPage={setDashboardPage}
          setInnerDashboardPage={setInnerDashboardPage}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  )
}

export default DashboardSummary
