const finalSectionMarketing = {
  name: 'Finishing Page Marketing',
  elements: [
    {
      type: 'matrix',
      name: 'Permission',
      title: '4. Your unique insights would prove invaluable to the wider L&D community. Do we have your permission to:',
      hideNumber: true,
      columns: [
        'Yes',
        'No'
      ],
      rows: [
        'name your organisation in our list of participants?',
        'attribute your comments to you personally?',
        'contact you about participating in a case study?',
        'contact you about research-related activities?'
      ]
    },
    {
      type: 'checkbox',
      name: 'Programme Marketing',
      title: '5. Would you be interested in receiving information on:',
      hideNumber: true,
      choices: [
        'The Learning Innovation Group - A dynamic, evidence-led, and practical community of practice for senior learning leaders. For over 10 years we have been running impactful workshops, seminars, podcasts, and much more.',
        'Analyst services - We run the most comprehensive longitudinal review of what works in global learning and development. Our aim is a multi-level understanding of organisational learning that leads to positive business impact. Find out more about how we can help you on your journey to becoming a high-impact learning organisation.'
      ]
    }
  ],
  description: 'You`ve completed your Learning Performance Benchmark. Thanks so much for you time!'
}

export default finalSectionMarketing
