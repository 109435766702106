const infrastructureSection = {
  name: 'Challenges p2',
  elements: [
    {
      type: 'matrix',
      name: 'Barrier Tech',
      title: '5. Which of the following challenges related to IT have you faced in the past 12 months?',
      hideNumber: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Not at all concerned'
        },
        {
          value: 'Column 2',
          text: 'Somewhat concerned'
        },
        {
          value: 'Column 3',
          text: 'Extremely concerned'
        }
      ],
      rows: [
        'IT infrastructure (e.g., technical restrictions, outdated software)',
        'Insufficient employee access to technology (e.g., computers)',
        'Limited digital capabilities',
        'Lack of support from the IT department',
        'Lack of L&D budget to explore new technologies'
      ]
    },
    {
      type: 'checkbox',
      name: 'Barrier Global',
      title: '5a. Please tell us about the challenges you face relating to global implementation.',
      hideNumber: true,
      visibleIf: '{Spread} anyof ["Mostly single nation with some global distribution ", "Multinational - highly distributed with multiple locations across the globe"]',
      choices: [
        'Cost of translation',
        'Local stakeholder buy-in',
        'Cultural differences',
        'Content developed locally but not shared centrally',
        'Content developed centrally not relevant locally',
        'Inconsistent technology/multiple learning management systems',
        'Other'
      ]
    }
  ]
}

export default infrastructureSection
