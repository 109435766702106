// This dictionary contains 25 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { surveyLocalization } from "survey-core";

export var tajikSurveyStrings = {
  pagePrevText: "Бозгашт",
  pageNextText: "Оянда",
  completeText: "Иҷро шуд",
  // previewText: "Preview",
  // editText: "Edit",
  startSurveyText: "Оғоз",
  otherItemText: "Дигар (лутфан тавсиф кунед)",
  noneItemText: "Не",
  selectAllItemText: "Ҳамаро интихоб кардан",
  progressText: "Саҳифаи {0} аз {1}",
  // indexText: "{0} of {1}",
  // panelDynamicProgressText: "{0} of {1}",
  // questionsProgressText: "Answered {0}/{1} questions",
  emptySurvey: "Ягон савол вуҷуд надорад.",
  completingSurvey: "Ташаккур барои пур кардани саволнома!",
  completingSurveyBefore: "Шумо аллакай ин пурсишро анҷом додаед.",
  loadingSurvey: "Боргирӣ аз сервер...",
  placeholder: "Интихоб кардан...",
  // ratingOptionsCaption: "Tap to rate here...",
  value: "қиммат",
  requiredError: "Илтимос, ба савол ҷавоб диҳед.",
  requiredErrorInPanel: "Илтимос, ақалан ба як савол ҷавоб диҳед.",
  requiredInAllRowsError: "Илтимос, ба ҳамаи саволҳо дар ҳамаи сатрҳо ҷавоб диҳед.",
  numericError: "Ҷавоб бояд рақам бошад.",
  // minError: "The value should not be less than {0}",
  // maxError: "The value should not be greater than {0}",
  textMinLength: "Илтимос, аз {0} зиёдтар рамз ворид кунед.",
  textMaxLength: "Илтимос, аз {0} камтар рамз ворид кунед.",
  textMinMaxLength: "Илтимос, аз {0} зиёдтар ва аз {1} камтар рамз ворид кунед.",
  minRowCountError: "Илтимос, на камтар аз {0} сатр пур кунед.",
  minSelectError: "Илтимос, ақалан {0} вариант интихоб кунед.",
  maxSelectError: "Илтимос, на зиёдтар аз {0} вариант интихоб кунед.",
  numericMinMax: "'{0}' бояд на кам аз {1} ва на бисёр аз {2} бошад",
  numericMin: "'{0}' бояд на кам аз {1} бошад",
  numericMax: "'{0}' бояд на зиёд аз {1} бошад",
  invalidEmail: "Илтимос, почтаи электронии воқеиро ворид кунед.",
  invalidExpression: "Ифодаи {0} бояд 'true' баргардонад.",
  urlRequestError: "Дархост хатогӣ бозгардонд '{0}'. {1}",
  urlGetChoicesError: "Ҷавоб ба дархост холӣ омад ё хосияти 'path' нодуруст муайян карда шудааст",
  exceedMaxSize: "Андозаи файл бояд на калон аз {0} бошад.",
  otherRequiredError: "Илтимос, ба майдони 'Дигар' додаҳоро ворид кунед",
  uploadingFile: "Файли шумо бор шуда истодааст. Якчанд сония интизор шавед ва бори дигар кӯшиш кунед.",
  loadingFile: "Боркунӣ...",
  chooseFile: "Файл(ҳо)-ро интихоб кунед...",
  // noFileChosen: "No file chosen",
  // fileDragAreaPlaceholder: "Drop a file here or click the button below to load the file.",
  confirmDelete: "Шумо мутмаин ҳастед, ки мехоҳед воридро тоза кунед?",
  keyDuplicationError: "Ин арзиш бояд беназир бошад.",
  addColumn: "Иловаи сутун",
  addRow: "Иловаи сатр",
  removeRow: "Нест кардан",
  // emptyRowsText: "There are no rows.",
  addPanel: "Илова кардан",
  removePanel: "Нест кардан",
  choices_Item: "Вариант",
  matrix_column: "Сутун",
  matrix_row: "Сатр",
  // multipletext_itemname: "text",
  savingData: "Натиҷа ба сервер сабт шуда истодаанд...",
  savingDataError: "Хатогӣ ба амал омад, натиҷа сабт нашуд.",
  savingDataSuccess: "Натиҷа бомуваффакият сабт шуд!",
  saveAgainButton: "Бори дигар кӯшиш карданд",
  timerMin: "дақ",
  timerSec: "сон",
  timerSpentAll: "Шумо {0} дар ин саҳифа ва {1} дар умум сарф кардед.",
  timerSpentPage: "Шумо {0} дар ин саҳифа сарф кардед.",
  timerSpentSurvey: "Шумо {0} дар ин тест сарф намудед.",
  timerLimitAll: "Шумо {0} аз {1} дар ин саҳифа ва {2} аз {3} дар умум сарф кардед дар дохили ин тест.",
  timerLimitPage: "Шумо {0} аз {1} дар ин саҳифа сарф кардед.",
  timerLimitSurvey: "Шумо {0} аз {1} дар ҳамаи тест сарф кардед.",
  clearCaption: "Тоза кардан",
  // signaturePlaceHolder: "Sign here",
  // chooseFileCaption: "Choose file",
  removeFileCaption: "Файлро нест кардан",
  // booleanCheckedLabel: "Yes",
  // booleanUncheckedLabel: "No",
  // confirmRemoveFile: "Are you sure that you want to remove this file: {0}?",
  // confirmRemoveAllFiles: "Are you sure that you want to remove all files?",
  // questionTitlePatternText: "Question Title",
  // modalCancelButtonText: "Cancel",
  // modalApplyButtonText: "Apply",
  // filterStringPlaceholder: "Type to search...",
  // emptyMessage: "No data to display",
  // noEntriesText: "There are no entries yet.\nClick the button below to add a new entry.",
  // more: "More"
};

surveyLocalization.locales["tg"] = tajikSurveyStrings;
surveyLocalization.localeNames["tg"] = "тоҷикӣ";
