const sixthEnableSection = {
  name: 'Strategy in practice and culture - Continued p9',
  elements: [
    {
      type: 'customrating',
      name: 'EnablePerformance_6',
      title: '69. We embed coaching in performance support',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_14',
      title: '70. We recommend learning resources based on individual performance',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableDesign_14',
      title: '71. We use diagnostic tools to tailor learning to individual needs',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_1',
      title: '72. We offer recommendations on how learners can use what they learn in their work',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_2',
      title: '73. We use defined practices to support knowledge transfer after formal learning',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_3',
      title: '74. We apply techniques to aid the retention and application of learning (e.g., spaced learning)',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_4',
      title: '75. Managers provide active support in the application of learning in the flow of work',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default sixthEnableSection
