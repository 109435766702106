const fourthEngageSection = {
  name: 'Strategy in practice and culture - Continued p15',
  elements: [
    {
      type: 'customrating',
      name: 'Engage_11',
      title: 'We complete pilot projects before implementing new learning initiatives',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Engage_12',
      title: 'We provide our learning initiatives with a specific identity and brand',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Engage_13',
      title: 'We ensure a communication plan is in place for all key stakeholders',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Engage_14',
      title: 'We pull key stakeholders together into a steering group to support new learning initiatives',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Engage_15',
      title: 'We use digital technology to promote organised learning initiatives through campaigns',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Engage_16',
      title: 'We always test a minimum viable product before we scale',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default fourthEngageSection
