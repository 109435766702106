import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { useStyles } from './style'

const SurveySkeleton = () => {
  const classes = useStyles()

  return (
    <span>
      <span>
        <Skeleton className={classes.skeleton} variant='text' animation='wave' width='50%' height='40px' />
        <Skeleton className={classes.skeleton} variant='rect' animation='wave' height='120px' style={{ marginBottom: '40px' }} />
      </span>
    </span>
  )
}

export default SurveySkeleton
