import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    '& .MuiTextField-root': {
      '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
        float: 'left'
      }
    }
  },
  main: {
    marginTop: '64px',
    padding: '0px 100px',
    transition: '0.25s all ease',
    '@media (min-width: 1536px)': {
      padding: '70px 100px'
    },
    '@media (max-width: 1200px)': {
      padding: '0px 48px',
      marginTop: '100px'
    }
  },
  mainMD: {
    padding: '24px',
    transition: 'all 0.25s ease-in-out',
    marginTop: '64px',
    marginLeft: 0
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'

  },
  image: {
    width: '100%',
    marginTop: '1rem'
  },
  rightColumnInner: {
    width: '500px'
  },
  socialBox: {
    border: '1px solid #E4E3E8',
    borderRadius: '4px',
    padding: '16px',
    alignSelf: 'flex-start',
    width: '250px',
    marginTop: '64px'
  },
  socialText: {
    fontWeight: '600',
    fontSize: '16px !important',
    lineHeight: 1.5
  },
  socialIcon: {
    marginLeft: '0.5rem',
    cursor: 'pointer'
  },
  title: {
    marginBottom: '1.5rem'
  },
  description: {
    fontSize: '1.35rem'
  },
  buttons: {
    margin: '64px 18px 64px 0'
  },
  collapseBtnHide: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '1px',
    borderRadius: '50%',
    padding: '2px',
    backgroundColor: '#006fb7',
    '&:hover': {
      backgroundColor: '#024e95'
    },
    transition: 'all 0.25s ease-in-out'
  },
  collapseBtn: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '242px',
    backgroundColor: '#006fb7',
    borderRadius: '50%',
    padding: '2px',
    '&:hover': {
      backgroundColor: '#024e95'
    },
    transition: 'all 0.25s ease-in-out'
  },
  collapseIcon: {
    '&.MuiSvgIcon-root': {
      fill: 'white'
    }
  }
}))
