import React, { useRef, useEffect } from 'react'
import { drawChart } from './draw'
import { useStyles } from './style'
import './chart.css'
import { useMediaQuery } from '@material-ui/core'

const ScoresChart = ({ data }) => {
  const isXSScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isMDScreen = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'))
  const isLGScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const screenSize = (isXSScreen && 'xs') || (isMDScreen && 'md') || (isLGScreen && 'lg')
  const classes = useStyles()
  const ref = useRef()
  const chartData = data

  useEffect(() => {
    if (ref.current && chartData) {
      drawChart(chartData, ref, screenSize)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize])

  return (
    <div
      ref={ref} className={classes.svgContainer}
    />
  )
}

const SmallCircularChart = ({ score }) => {
  const userScoreData = {
    color: '#FF8200',
    value: score
  }
  const circularScoreData = [
    userScoreData,
    {
      value: 100,
      color: '#EEEFF1'
    }
  ]

  return (
    <ScoresChart
      data={circularScoreData}
    />
  )
}

export default SmallCircularChart
