import React from 'react'
import {
  Dialog,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import { useStyles } from './style'

const CleanResultsDialog = ({
  open,
  handleClose,
  handleClean,
  cleaning
}) => {
  const classes = useStyles()

  const dialogClose = () => {
    handleClose()
  }

  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <Typography variant='h3'>Overwrite your results</Typography>
        <Typography className={classes.text}>Are you sure you want to overwrite your existing results?</Typography>
        <Typography>This <b>cannot</b> be undone.</Typography>
        <div className={classes.buttons}>
          <Button className='Clean_Results_Modal_No' variant='outlined' color='secondary' onClick={dialogClose}>No</Button>
          <Button className={`${classes.rightButton} Clean_Results_Modal_Yes`} variant='contained' color='secondary' onClick={handleClean} startIcon={cleaning ? <CircularProgress size={16} classes={{ root: classes.cleaning }} /> : null}>Yes overwrite</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default CleanResultsDialog
