import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  main: {
    padding: '48px',
    marginTop: '40px',
    height: '100%',
    transition: 'margin-left 0.25s ease-in-out',
    marginLeft: '296px'
  },
  mainXL: {
    marginLeft: '368px'
  },
  mainMD: {
    padding: '24px 48px',
    transition: 'margin-left 0.25s ease-in-out',
    marginTop: '64px',
    height: '100%',
    width: '100%'
  },
  mainXLCollapsed: {
    marginLeft: '72px'
  },

  sectionTitle: {
    marginBottom: '1.5rem'
  },
  introText: {
    fontSize: 20,
    fontWeight: 400,
    marginBottom: '16px'
  },
  img: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  tipsBox: {
    color: '#FFF',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px',
    padding: '24px',
    margin: '64px 0'
  },
  bulletList: {
    paddingInlineStart: '19px'
  },
  buttonsBox: {
    marginBottom: '64px'
  },
  saveExitButton: {
    margin: '0 16px 0 0',
    color: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s',
      color: '#FFFFFF'
    }
  },
  quoteTextBox: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  quoteText: {
    width: '25rem'
  },
  quoteAuthor: {
    paddingTop: '0.75rem',
    fontWeight: 700,
    lineHeight: 1.57
  },
  quoteRole: {
    width: '22rem'
  }
}))
