const secondDeliverySection = {
  name: 'Technology, tools and access p4',
  elements: [
    {
      type: 'dropdown',
      name: 'Cost',
      title: '5. In the past 12 months, what has been the impact of digital technologies on how much you’ve spent on organisational learning?',
      hideNumber: true,
      choices: [
        'We have saved money',
        'We have spent more money',
        'We don\'t have enough information to measure this'
      ]
    },
    {
      type: 'customslider',
      name: 'CostValue',
      visibleIf: '{Cost} = "We have saved money"',
      startWithNewLine: false,
      title: '5a. Amount saved in %',
      hideNumber: true,
      rangeStart: 0,
      rangeMin: 0,
      rangeMax: 100
    },
    {
      type: 'dropdown',
      name: 'Competency',
      title: '6. In the past 12 months, what has been the impact of digital technologies on how much time it’s taken for employees to become capable?',
      hideNumber: true,
      choices: [
        {
          value: 'We have saved money',
          text: 'They take less time'
        },
        {
          value: 'We have spent more money',
          text: 'They take more time'
        },
        'We don\'t have enough information to measure this'
      ]
    },
    {
      type: 'customslider',
      name: 'CompetencyValue',
      visibleIf: '{Competency} = "We have saved money"',
      startWithNewLine: false,
      rangeStart: 0,
      rangeMin: 0,
      rangeMax: 100,
      hideNumber: true,
      title: '6a. Time to competence reduced in %'
    },
    {
      type: 'comment',
      name: 'question26',
      hideNumber: true,
      title: '7. How do you see your organisation\'s use of digital technologies changing in the next 12 months?'
    },
    {
      type: 'comment',
      name: 'question27',
      hideNumber: true,
      title: '8. How do you see learning and development having an impact on the future of work?'
    }
  ]
}

export default secondDeliverySection
