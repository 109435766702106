import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'baseline',
    background: '#EEEFF1',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    bottom: 0,
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    boxShadow: '-0px -2px #253746'
  },
  content: {
    flex: '1 0 300px',
    fontSize: '16px',
    color: '#253746',
    margin: '15px'
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none'
  },
  button: {
    float: 'right',
    backgroundColor: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s'
    }
  }
}))
