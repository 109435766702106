import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  link: {
    fontSize: '12px',
    color: theme.palette.secondary.main,
    fontWeight: 400,
    cursor: 'pointer',
    flex: 1,
    textAlign: 'start',
    margin: 'auto 0'
  },
  primaryButton: {
    backgroundColor: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s'
    }
  },
  secondaryButton: {
    color: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s',
      color: '#FFFFFF'
    }
  }
}))
