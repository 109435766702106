const secondGeneralSection = {
  name: 'Challenges p3',
  elements: [
    {
      type: 'matrix',
      name: 'Barrier',
      title: '3. In the past 12 months, how concerned were you about the following challenges?',
      hideNumber: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Not at all concerned'
        },
        {
          value: 'Column 2',
          text: 'Somewhat concerned'
        },
        {
          value: 'Column 3',
          text: 'Extremely concerned'
        }
      ],
      rows: [
        'Lack of knowledge about the potential use and implementation of digital technologies',
        'Lack of capabilities to implement and manage digital learning',
        'Lack of skills among our employees to manage their own learning',
        'Lack of subject matter expertise',
        'Lack of analytical skills to use learning data effectively'
      ]
    }
  ]
}

export default secondGeneralSection
