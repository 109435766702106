import React from 'react'
import {
  Grid,
  Typography,
  Paper
} from '@material-ui/core'
import { useStyles } from './style'
import { LPBLink } from '../../components'
import Person2OutlinedIcon from '@material-ui/icons/PersonOutline'
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const RegisterOptions = ({ handleRegisterTeams, handleRegisterIndividual }) => {
  const classes = useStyles()

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Typography data-test='authPageHeader' variant='h1'>
          Let’s get started!
        </Typography>
        <Typography variant='body1'>Are you looking for a free individual account or to benchmark with your team?</Typography>
        <Paper elevation={0} variant='outlined' className={classes.option} onClick={handleRegisterIndividual}>
          <span className={classes.startIcon}>
            <Person2OutlinedIcon style={{ color: '#006Fb7', width: '24px', height: '24px' }} />
          </span>
          <span className={classes.optionText}>
            <Typography variant='subtitle1'>I want to use the benchmark individually</Typography>
            <Typography variant='body1'>Measure your organisations performance for free by completing on your own.</Typography>
          </span>
          <ArrowForwardIosIcon className={classes.arrow} />
        </Paper>
        <Paper elevation={0} variant='outlined' className={classes.option} onClick={handleRegisterTeams}>
          <span className={classes.startIcon}>
            <GroupAddOutlinedIcon style={{ color: '#006FB7', width: '24px', height: '24px' }} />
          </span>
          <span className={classes.optionText}>
            <Typography variant='subtitle1'>I want to purchase a teams account</Typography>
            <Typography variant='body1'>Benchmark with your team members and get a 360 view of your L&D.</Typography>
          </span>
          <ArrowForwardIosIcon className={classes.arrow} />
        </Paper>
        <p className={classes.loginText}>
          Already registered?{' '}
          <LPBLink data-test='lnk-registerPageLogin' to='/login'>
            Log in
          </LPBLink>.
        </p>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

export default RegisterOptions
