import React from 'react'
import { FormControl, TextField, Button, Typography, CircularProgress } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { TeamDetailsCard } from '../../components'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { teamSlice } from '../../reducers'
import { useStyles } from './style'

const TeamCreateMembersCard = ({
  detailsPageInfo,
  membersArray,
  addTeamMember,
  teamMembersError,
  jumpAddMembers,
  saveTeamInformation,
  register,
  isLoading
}) => {
  const classes = useStyles()
  const [getTeam] = useEvent([teamSlice.eventBus.getTeam])

  const handleJumpAddMembers = () => {
    getTeam.trigger()
    jumpAddMembers()
  }

  return (
    <TeamDetailsCard
      type={detailsPageInfo[2].type}
      completed={detailsPageInfo[2].completed}
      expanded={detailsPageInfo[2].expanded}
      loading={saveTeamInformation.isWorking}
    >
      <div>
        <form>
          {membersArray.map((count, idx) => (
            <FormControl key={idx} className={classes.formControlSmall} fullWidth>
              <TextField
                label='Enter email address'
                variant='outlined'
                name={`member-${idx}`}
                type='email'
                inputRef={register}
                inputPros={{ 'aria-label': `Team member ${count}` }}
              />
            </FormControl>
          ))}
        </form>
        <Button
          variant='text'
          startIcon={isLoading ? <CircularProgress size={14} color='inherit' /> : <AddIcon />}
          color='primary'
          onClick={addTeamMember}
          className='Team_Create_Add_Email'
        >
          Add another email address
        </Button>
        {teamMembersError.length > 0 && (
          <Typography className={classes.errorText}>{teamMembersError}</Typography>
        )}
        <Typography className={classes.membersFooter}>By submitting this form, team members will automatically receive an email inviting them to join the team and complete the review.</Typography>
        <Button
          variant='outlined'
          color='secondary'
          className={`${classes.cardButton} Team_Create_Skip`}
          onClick={handleJumpAddMembers}
        >
          I'll do this later
        </Button>
      </div>
    </TeamDetailsCard>
  )
}

export default TeamCreateMembersCard
