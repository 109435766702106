import { IconButton, RadioGroup, Typography } from '@material-ui/core'
import { useStyles } from './style'
import React, { useState } from 'react'

export const CustomRating = ({ rateMinDescription, rateMaxDescription, rateMax, defaultVal }) => {
  const classes = useStyles()
  const [result, setResult] = useState(defaultVal)
  const values = Array.from({ length: rateMax }, (_, index) => index + 1)

  return (
    <RadioGroup className={classes.root} aria-label='rating question'>
      <div className={classes.row}>
        {values.map((value, idx) => (
          <IconButton disableRipple key={idx} className={`${classes.button} ${result === value ? classes.selected : classes.button}`} onClick={() => setResult(value)}>{value}</IconButton>
        ))}
      </div>
      <div className={`${classes.spaced}`}>
        <Typography>{rateMinDescription}</Typography>
        <Typography>{rateMaxDescription}</Typography>
      </div>
    </RadioGroup>
  )
}
