import React, { useRef, useEffect } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { drawChart } from './draw'
import { useStyles } from './style'
import './chart.css'

const TeamMemberScoresChart = ({ id = null, scores, limit, forPdf = false }) => {
  const isXSScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isMDScreen = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'))
  const isLGScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  let screenSize = (isXSScreen && 'xs') || (isMDScreen && 'md') || (isLGScreen && 'lg')
  if (forPdf) screenSize = 'lg'
  const classes = useStyles()
  const ref = useRef()
  const [chartWidth, setDimension] = React.useState(null)
  const chartData = scores

  useEffect(() => {
    if (ref.current) {
      setDimension(Math.round(ref.current.offsetWidth))
    }
    if (chartWidth && ref.current && chartData) {
      drawChart(chartData, limit, screenSize, ref, forPdf)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, screenSize])

  return (
    <div ref={ref} className={!forPdf ? classes.svgContainer : classes.svgContainerForPdf} id={id} />
  )
}

export default TeamMemberScoresChart
