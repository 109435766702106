import React from 'react'
import { Page, View, Text } from '@react-pdf/renderer'
import { PDFFooter, ComponentsCard } from '../../components'
import styles from './style'
import globalStyles from '../../style'

const ComponentsCoverPage = ({ componentsChartData, profile, currentSeason }) => (
  <Page size='A4' orientation='landscape' wrap={false}>
    <View style={globalStyles.pageView}>
      <Text style={globalStyles.heading}>Six key components</Text>
      <Text style={globalStyles.introText}>Your maturity score comes from the interaction between 6 critical areas of impact. We call them the ‘6 key components’. They comprise the subsections in the ‘Strategy in practice and culture’ section of the review. Which of the 6 key components is your strongest—and which need a little work?</Text>
      <View style={styles.componentsCards}>
        <View style={styles.componentsColumn}>

          <ComponentsCard title='Strategy and business alignment' scores={componentsChartData.Align} profile={profile} currentSeason={currentSeason} />
          <ComponentsCard title='Environment, talent and culture' scores={componentsChartData.Influence} profile={profile} currentSeason={currentSeason} />
          <ComponentsCard title='Organisational engagement' scores={componentsChartData.Engage} profile={profile} currentSeason={currentSeason} />
        </View>
        <View style={styles.componentsColumn}>

          <ComponentsCard title='Employee experience' scores={componentsChartData.Listen} profile={profile} currentSeason={currentSeason} />
          <ComponentsCard title='L&D team capabilities' scores={componentsChartData.Enable} profile={profile} currentSeason={currentSeason} />
          <ComponentsCard title='Learning impact' scores={componentsChartData.Improve} profile={profile} currentSeason={currentSeason} />
        </View>
      </View>
      <PDFFooter pageNumber={4} />
    </View>

  </Page>
)

export default ComponentsCoverPage
