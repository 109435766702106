import React from 'react'
import Grid from '@material-ui/core/Grid'
import {
  RegisterHeader,
  RegisterFooter,
  ChangePasswordForm,
  PageAlert
} from '../../components'
import { ReactComponent as ChangePasswordSVG } from './changePassword.svg'
import { useStyles } from './style'

const ChangePassword = () => {
  const classes = useStyles()
  const [showAlert, setShowAlert] = React.useState(false)

  return (
    <>
      <Grid className={classes.root} container spacing={0}>
        <Grid container item lg={7} xs={12} spacing={0}>
          <Grid item xs={12}>
            <RegisterHeader />
          </Grid>
          <Grid item xs={12}>
            <ChangePasswordForm showAlert={setShowAlert} />
          </Grid>
        </Grid>
        <Grid container className={classes.imageGrid} item lg={5} xs={12} spacing={0}>
          <ChangePasswordSVG />
        </Grid>
        <Grid container item lg={7} xs={12} spacing={0}>
          <Grid item xs={12}>
            <RegisterFooter />
          </Grid>
        </Grid>
      </Grid>
      {showAlert && (
        <PageAlert text='Success! Your password has been changed!' />
      )}
    </>
  )
}

export default ChangePassword
