import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    background: '#FFFFFF',
    height: '100vh'
  },
  main: {
    marginLeft: '296px',
    padding: '48px',
    marginTop: '56px',
    transition: 'margin-left 0.25s ease-in-out'
  },
  mainXL: {
    marginLeft: '368px'
  },
  mainMD: {
    padding: '12px 48px',
    transition: 'margin-left 0.25s ease-in-out',
    marginTop: '64px'
  },
  mainXLCollapsed: {
    '@media (min-width: 1200px)': { marginLeft: '72px' }
  },
  summarySpacing: {
    marginTop: 0
  },
  button: {
    fontWeight: 700,
    fontSize: '14px'
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '120px 0 0'
  },
  collapseBtnHide: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '1px',
    backgroundColor: '#006fb7',
    borderRadius: '50%',
    padding: '2px',
    transition: 'left 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: '#024e95'
    }
  },
  collapseBtnHideXL: {
    left: '73px'
  },
  collapseBtn: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '282px',
    backgroundColor: '#006fb7',
    borderRadius: '50%',
    padding: '2px',
    transition: 'left 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: '#024e95'
    }
  },
  collapseBtnXL: {
    left: '354px'
  },
  collapseIcon: {
    '&.MuiSvgIcon-root': {
      fill: 'white'
    }
  }
}))
