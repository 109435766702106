import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './style'

const LPHelpRow = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleGetStarted = () => {
    navigate('/register')
  }

  return (
    <Grid item xs={12}>
      <Typography variant='h2' style={{ textAlign: 'center', marginBottom: 40, marginTop: 64 }}>We'll help you...</Typography>
      <Grid container spacing={3} className={classes.lightContainer}>
        <Grid item md={6}>
          <div className={classes.helpContainer}>
            <Typography variant='h3'>Identify opportunities to advance your L&D</Typography>
            <Typography>By completing the benchmark, you'll gain a clear picture of your L&D maturity. Throughout the process you’ll discover ways to improve your learning strategy and generate new ideas.</Typography>
          </div>
          <div className={classes.helpContainer}>
            <Typography variant='h3'>Keep up to date with what similar organisations are doing</Typography>
            <Typography>By filtering for industry, region and size, you can explore how your organisation compares to your peers and competitors. Use the results to learn where you're doing well and which areas to prioritise for improvement.</Typography>
          </div>
          <div className={classes.helpContainer}>
            <Typography variant='h3'>Get business buy-in</Typography>
            <Typography>The benchmark offers tangible results with metrics you can use to show value and build a business case. Drawing on 20 years' worth of data collection, we're proud that our longitudinal sharing within the learning community is something you can trust.</Typography>
          </div>
          <Button disableElevation variant='contained' color='secondary' style={{ width: 'fit-content', marginTop: 24 }} onClick={handleGetStarted}>Get started</Button>
        </Grid>
        <Grid item md={6}>
          <div className={classes.helpImg}>
            <img src='/img/lp-help.png' alt='Dashboard help example' className={classes.image} />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LPHelpRow
