const dashboardLinks = [
  { text: 'Summary', type: 'dashboard', url: '' },
  {
    text: 'Your learning maturity score',
    type: 'score',
    url: ''
  },
  {
    text: 'Six key components',
    type: 'areas',
    innerLinks: [
      {
        text: 'Strategy and business alignment',
        type: 'Align'
      },
      {
        text: 'Employee experience',
        type: 'Listen'
      },
      {
        text: 'Environment, talent and culture',
        type: 'Influence'
      },
      {
        text: 'L&D team capabilities',
        type: 'Enable'
      },
      {
        text: 'Organisational engagement',
        type: 'Engage'
      },
      {
        text: 'Learning impact',
        type: 'Improve'
      }
    ],
    url: ''
  },
  {
    text: 'Your learning maturity stage',
    type: 'stage',
    url: ''
  },
  {
    text: 'Overall participant trends',
    type: 'trends',
    innerLinks: [
      {
        text: 'Technology',
        type: 'Technology'
      },
      {
        text: 'Challenges',
        type: 'Challenges'
      },
      {
        text: 'In-house skillsets',
        type: 'Skills'
      }
    ],
    url: ''
  }
]

const surveySteps = [
  {
    label: 'You and your organisation',
    stepProgress: '11/11',
    innerSteps: [],
    surveySections: ['personal', 'organisation'],
    complete: true,
    position: 0
  },
  {
    label: 'Current goals and vision',
    stepProgress: '0/17',
    innerSteps: [
      {
        name: 'organisationgoals',
        title: 'Organisation'
      },
      {
        name: 'employeegoals',
        title: 'Employee'
      },
      {
        name: 'resources_3',
        title: 'Efficiency'
      },
      {
        name: 'resources_4',
        title: 'Objectives'
      }
    ],
    surveySections: [
      'cover',
      'organisationgoals_1',
      'organisationgoals_2',
      'employeegoals_1',
      'employeegoals_2',
      'resources_3',
      'resources_4'
    ],
    complete: false,
    position: 1
  },
  {
    label: 'Strategy in practice and culture',
    stepProgress: '6/24',
    innerSteps: [
      {
        name: 'align',
        title: 'Strategy and business alignment'
      },
      {
        name: 'listen',
        title: 'Employee experience'
      },
      {
        name: 'influence',
        title: 'Environment, talent and culture'
      },
      {
        name: 'enable',
        title: 'L&D team capabilities'
      },
      {
        name: 'engage',
        title: 'Organisational engagement'
      },
      {
        name: 'improve',
        title: 'Learning impact'
      }
    ],
    surveySections: [
      'cover',
      'align_1',
      'align_2',
      'listen_1',
      'influence_1',
      'influence_2',
      'influence_3',
      'enable_1',
      'enable_2',
      'enable_3',
      'enable_4',
      'enable_5',
      'enable_6',
      'engage_1',
      'engage_2',
      'engage_3',
      'improve_1',
      'improve_2',
      'improve_3'
    ],
    complete: false,
    position: 2,
    error: true
  },
  {
    label: 'Technology, tools and access',
    stepProgress: '0/6',
    innerSteps: [
      {
        name: 'technology',
        title: 'Digital technology and other tools'
      },
      {
        name: 'delivery',
        title: 'Delivery and access'
      }
    ],
    surveySections: [
      'cover',
      'technology_1',
      'technology_2',
      'technology_3',
      'delivery_1',
      'delivery_2'
    ],
    complete: false,
    position: 3
  },
  {
    label: 'Challenges',
    stepProgress: '0/5',
    innerSteps: [
      {
        name: 'general',
        title: 'General'
      },
      {
        name: 'content',
        title: 'Content'
      },
      {
        name: 'infrastructure',
        title: 'Technology and IT infrastructure'
      },
      {
        name: 'environment',
        title: 'Environment'
      }
    ],
    surveySections: ['cover', 'general', 'general_2', 'general_3', 'content', 'infrastructure', 'environment', 'challenges_final'],
    complete: false,
    position: 4
  },
  {
    label: 'Resources and impact',
    stepProgress: '0/4',
    innerSteps: [],
    surveySections: ['cover', 'goals_1', 'goals_2', 'goals_3', 'goals_4'],
    complete: false,
    position: 5
  }
]

export {
  dashboardLinks,
  surveySteps
}
