const fourthEnableSection = {
  name: 'Strategy in practice and culture - Continued p10',
  elements: [
    {
      type: 'customrating',
      name: 'EnableLND_1',
      title: '55. We have audited the skills of our L&D team against those required',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableLND_2',
      title: '56. We understand the critical questions that we need to ask of external providers',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableLND_3',
      title: '57. We are confident in incorporating the use of new media in learning design',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableLND_4',
      title: '58. We have the right skills to exploit digital technologies for organisational advantage',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableLND_5',
      title: '59. We research the impact of emerging digital technologies on learning',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableDesign_10',
      title: '60. We apply new concepts from learning theory to practice (e.g., neuroscience)',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_7',
      title: '61. Where appropriate, employees have access to a tutor or subject expert when learning online',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default fourthEnableSection
