import React from 'react'
import { Page, View, Text, Image } from '@react-pdf/renderer'
import { PDFFooter, QuickTakeawaysCard } from '../../components'
import styles from './style'
import globalStyles from '../../style'
import { pointsToNextStage } from '../../../../helpers/utils'

const ScorePage = ({ scoresChartData, currentSeason }) => {
  const score = scoresChartData
  const takeaways = [
    {
      type: 'benchmark',
      score: 1.8
    },
    {
      type: 'industry',
      score: 3.6
    },
    {
      type: 'size',
      score: -2.5
    },
    {
      type: 'stage',
      score: 0
    }
  ]
  takeaways.forEach(takeaway => {
    const userScore = score[0].values[0].score
    if (takeaway.type === 'benchmark') {
      const benchmarkScore = score[1].values.find(value => value.date === currentSeason).score
      takeaway.score = (userScore - benchmarkScore).toFixed(2)
    }
    if (takeaway.type === 'industry') {
      const industryScore = score[2].values.find(value => value.date === currentSeason).score
      takeaway.score = (userScore - industryScore).toFixed(2)
    }
    if (takeaway.type === 'size') {
      const sizeScore = score[3].values.find(value => value.date === currentSeason).score
      takeaway.score = (userScore - sizeScore).toFixed(2)
    }
    if (takeaway.type === 'stage') {
      takeaway.score = pointsToNextStage(userScore).toFixed(2)
    }
  })

  const circularDataUrl = localStorage.getItem('circularChartData')
  const linearDataUrl = localStorage.getItem('linearChartData')

  return (
    <Page size='A4' orientation='landscape' wrap={false}>
      <View style={globalStyles.pageView}>
        <Text style={globalStyles.heading}>Learning maturity score</Text>
        <Text style={globalStyles.introText}>Your maturity score provides a benchmark measurement of how well you have implemented your learning strategy across 6 key components. Those with the highest scores are consistently achieving the best results for their organisations and their people.</Text>
        <View style={styles.graphsContainer}>
          <View style={{ width: '50%' }}>

            <Text style={[globalStyles.subheading, { marginBottom: 5 }]}>{`Your ${currentSeason} score`}</Text>

            {!circularDataUrl ? <Text>Circular chart placeholder</Text> : <Image src={circularDataUrl} style={{ objectFit: 'contain', marginLeft: 50 }} />}
          </View>
          <View style={{ width: '50%' }}>

            <Text style={[globalStyles.subheading, { marginBottom: 5 }]}>Overall participant trends</Text>

            {!linearDataUrl ? <Text>Linear chart placeholder</Text> : <Image src={linearDataUrl} style={{ objectFit: 'contain' }} />}

          </View>
        </View>
        <Text style={globalStyles.subheading}>Quick takeaways</Text>
        <View style={styles.takeawaysBox}>
          <QuickTakeawaysCard type='overall' score={takeaways[0].score} />
          <QuickTakeawaysCard type='industry' score={takeaways[1].score} />
          <QuickTakeawaysCard type='size' score={takeaways[2].score} />
          <QuickTakeawaysCard type='stage' score={takeaways[3].score} />
        </View>
        <PDFFooter pageNumber={3} />
      </View>
    </Page>
  )
}

export default ScorePage
