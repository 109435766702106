import { Paper } from '@material-ui/core'
import { useStyles } from './style'
import React from 'react'
import { Skeleton } from '@material-ui/lab'

const HomePageLoadResults = () => {
  const classes = useStyles()
  return (
    <Paper className={classes.homePaper}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span style={{ flex: 4 }}>
          <Skeleton variant='text' animation='wave' width='50%' height='60px' style={{ marginBottom: '30px' }} />
          <Skeleton variant='text' animation='wave' width='90%' height='30px' style={{ marginBottom: '-8px' }} />
          <Skeleton variant='text' animation='wave' width='90%' height='30px' style={{ marginBottom: '-8px' }} />
          <Skeleton variant='text' animation='wave' width='90%' height='30px' style={{ marginBottom: '16px' }} />
          <Skeleton variant='text' animation='wave' width='90%' height='30px' style={{ marginBottom: '-8px' }} />
          <Skeleton variant='text' animation='wave' width='90%' height='30px' style={{ marginBottom: '30px' }} />
          <Skeleton variant='text' animation='wave' width='25%' height='50px' />
        </span>
        <span style={{ flex: 1 }}>
          <Skeleton style={{ marginTop: '85px' }} variant='circle' animation='wave' width='100%' height='150px' />
        </span>
      </div>
    </Paper>
  )
}

export default HomePageLoadResults
