import {
  select,
  pie,
  arc
} from 'd3'

export const drawChart = (data, screenSize, ref) => {
  const margin = 40

  let chartWidth = 450 - 2 * margin
  const chartHeight = 400

  switch (screenSize) {
    case 'xs':
      chartWidth = 300
      break
    case 'md':
      chartWidth = 330
      break
    case 'lg':
      chartWidth = 250
      break
    default:
      break
  }

  const chart = select(ref.current)
    .html('')
    .append('svg')
    .attr('class', 'pieChartSvgContent')
    .attr('style', 'margin-top:-40px')
    .attr('viewBox', `0 0 ${chartWidth} ${chartHeight + 2 * margin}`)
    .attr('aria-label', 'pie chart with maturity stage percentages')
    .attr('align', 'center')
    .append('g')
    .attr('transform', `translate(${chartWidth / 2}, ${chartHeight / 2})`)
  const radius = Math.min(chartWidth, chartHeight) / 2
  const pieData = pie().value(d => d.value)
  const arcData = arc().innerRadius(0).outerRadius(radius)

  chart
    .selectAll('arc')
    .data(pieData(data))
    .enter()
    .append('g')
    .attr('stroke', 'white')
    .append('g')
    .attr('class', 'arc')
    .append('path')
    .attr('fill', d => d.data.color)
    .attr('d', arcData)

  chart
    .selectAll('text')
    .data(pieData(data))
    .join('text')
    .attr('transform', d => `translate(${arcData.centroid(d)})`)
    .call(text => text.append('tspan'))
    .text(d => `${d.data.value}%`)
    .attr('font-family', 'Matter')
    .attr('font-weight', 'bold')
    .attr('x', '-0.5em')
    .attr('fill', 'white')
}
