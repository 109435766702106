import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  link: {
    fontSize: '12px',
    color: theme.palette.secondary.main,
    fontWeight: 400,
    cursor: 'pointer',
    flex: 1,
    textAlign: 'start',
    margin: 'auto 0'
  }
}))
