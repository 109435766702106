import React from 'react'
import { useStyles } from './style'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'

const PageAlert = ({ text, error, title = '' }) => {
  const { root, eRoot, leftSide, rightSide, textStyle } = useStyles()

  const alertRoot = error ? eRoot : root

  return (
    <div className={alertRoot}>
      <span className={leftSide}>
        {
          error
            ? <ErrorIcon />
            : <CheckCircleIcon />
        }
      </span>
      <span className={rightSide}>
        <p className={textStyle}><b>{error ? 'Error' : 'Success'} {title}</b></p>
        <p className={textStyle}>{text}</p>
      </span>
    </div>
  )
}

export default PageAlert
