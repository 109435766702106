import React from 'react'
import { FormControl, TextField, Button } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { userSlice } from '../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { useSession } from '@emerald-works/react-auth'
import { useStyles } from './style'

const ContactForm = ({ setEmailSent }) => {
  const classes = useStyles()
  const session = useSession()
  const [sendContactEmail] = useEvent([userSlice.eventBus.sendContactEmail])
  const { user } = session
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      message: ''
    }
  })

  const currentMessage = watch('message')

  const onSubmit = () => {
    if (currentMessage.length) {
      const sender = user.attributes.email
      sendContactEmail.trigger({ sender, message: currentMessage })
      setEmailSent(true)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth>
        <TextField
          className={classes.root}
          label='Message'
          variant='outlined'
          name='message'
          type='text'
          required
          multiline
          rows={10}
          inputRef={register}
          inputProps={{ 'aria-label': 'Message' }}
          data-test='contactMessage'
        />
      </FormControl>
      <Button
        id='btn-send-contact'
        className={classes.primaryButton}
        aria-label='Send message'
        type='submit'
        variant='contained'
        data-test='btn-send'
        disabled={!currentMessage}
      >
        Send
      </Button>
    </form>
  )
}

export default ContactForm
