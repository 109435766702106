import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import styles from './style'
import globalStyles from '../../style'
import { CircularChart } from '../index'

const ComponentsCard = ({ title, scores, profile, currentSeason }) => {
  const userScore = scores[0].values[0].score
  const benchmarkScores = {
    overall: scores[1].values.find(value => value.date === currentSeason).score,
    industry: scores[2].values.find(value => value.date === currentSeason).score,
    size: scores[3].values.find(value => value.date === currentSeason).score,
    region: scores[4].values.find(value => value.date === currentSeason).score
  }

  const subheadingStyle = [globalStyles.subheading, { marginTop: 0, marginBottom: 10 }]
  const bodyStyle = [globalStyles.body, { fontSize: 12, marginVertical: 5 }]
  return (
    <View style={styles.card}>
      <View style={styles.textBox}>

        <Text style={subheadingStyle}>{title}</Text>
        <Text style={bodyStyle}>Benchmark: {benchmarkScores.overall}</Text>
        <Text style={bodyStyle}>Your industry {`(${profile.industry})`}: {benchmarkScores.industry}</Text>
        <Text style={bodyStyle}>Your organisation size {`(${profile.size})`}: {benchmarkScores.size}</Text>
        <Text style={bodyStyle}>Your region {`(${profile.region})`}: {benchmarkScores.region}</Text>

      </View>
      <View style={styles.chartBox}>
        <CircularChart score={userScore} />
      </View>
    </View>
  )
}

export default ComponentsCard
