import { Grid, Paper, Typography } from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'
import { useStyles } from './style'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { surveySlice } from '../../../reducers'

const ProgressCard = () => {
  const stepsProgress = useSelector(surveySlice.selectors.selectStepsProgress)
  const NumberIcon = ({ value }) => {
    return (
      <div className={classes.NumberIcon}>
        <Typography variant='body1'>{value}</Typography>
      </div>
    )
  }

  const getProgress = (steps) => {
    if (steps[0] >= steps[1]) return 'complete'
    if (steps[0] === 0) return null
    if (steps[0] < steps[1]) return 'partial'
  }
  useEffect(() => {
    if (!stepsProgress) {
      setReviewSections([
        { title: 'You and your organisation', progress: null, timeEstimate: 5 },
        { title: 'Current goals and vision', progress: null, timeEstimate: 10 },
        { title: 'Strategy in practice and culture', progress: null, timeEstimate: 25 },
        { title: 'Technology, tools and access', progress: null, timeEstimate: 10 },
        { title: 'Challenges', progress: null, timeEstimate: 10 },
        { title: 'Resources and impact', progress: null, timeEstimate: 10 }
      ])
      return
    }
    setReviewSections([
      { title: 'You and your organisation', progress: getProgress(stepsProgress['You and your organisation']), timeEstimate: 5 },
      { title: 'Current goals and vision', progress: getProgress(stepsProgress['Current goals and vision']), timeEstimate: 10 },
      { title: 'Strategy in practice and culture', progress: getProgress(stepsProgress['Strategy in practice and culture']), timeEstimate: 25 },
      { title: 'Technology, tools and access', progress: getProgress(stepsProgress['Technology, tools and access']), timeEstimate: 10 },
      { title: 'Challenges', progress: getProgress(stepsProgress.Challenges), timeEstimate: 10 },
      { title: 'Resources and impact', progress: getProgress(stepsProgress['Resources and impact']), timeEstimate: 10 }
    ])
  }, [stepsProgress])

  const [reviewSections, setReviewSections] = React.useState([])
  const classes = useStyles()

  const HandleSwitchTextProgress = ({ section }) => {
    switch (section.progress) {
      case 'complete':
        return 'Complete'
      case 'partial':
        return 'In progress'
      default:
        return `${section.timeEstimate} mins`
    }
  }

  return (
    <Paper className={classes.homePaper}>
      <Typography data-test='homePageReportCard' variant='h2'>
        Your review
      </Typography>
      <Grid container className={classes.homePaperContent}>

        {reviewSections && reviewSections.map((section, idx) => {
          return (
            <React.Fragment key={idx}>
              <Grid item xs={1} className={classes.icon}>
                {section.progress === 'complete' ? <CheckCircleOutline /> : <NumberIcon value={idx + 1} />}
              </Grid>
              <Grid item xs={9}>
                <Typography variant='body1' className={classes.sectionTitle}>{section.title}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='body1' className={`${classes.sectionTitle} ${classes.completionText}`}>
                  <HandleSwitchTextProgress section={section} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {idx !== reviewSections.length - 1 && <hr className={classes.hr} />}
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </Paper>
  )
}

export default ProgressCard
