import { useDispatch } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { surveySlice } from '../../reducers'

const ResponsiveCheck = () => {
  const dispatcher = useDispatch()

  const isXSScreen = useMediaQuery('(max-width:600px)')
  const isMDScreen = useMediaQuery('(min-width:600px) and (max-width:1200px)')
  const isLGScreen = useMediaQuery('(min-width:1200px)')

  dispatcher(surveySlice.actions.setScreenSize({ isXSScreen, isMDScreen, isLGScreen }))

  return null
}

export default ResponsiveCheck
