import name from './name'

const selectSliceData = state => state[name]
const selectUsername = state => selectSliceData(state).username
const selectIsRegistered = state => selectSliceData(state).isRegistered

export {
  selectUsername,
  selectIsRegistered
}
