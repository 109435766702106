import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(_theme => ({
  root: {
    minWidth: '16rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  imageBox: {
    width: '100%',
    height: '12.5rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textBox: {
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'column'
  },
  text: {
    marginTop: '8px',
    marginBottom: '30px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical'
  },
  footer: {
    marginTop: '10px',
    position: 'absolute',
    bottom: '12px',
    left: '16px'
  },
  footerText: {

    textTransform: 'uppercase',
    cursor: 'pointer'
  }
}))
