import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  cardButton: {
    display: 'block',
    margin: '0 0 0 auto'
  },
  formControlSmall: {
    marginBottom: '24px'
  },
  errorText: {
    color: '#B00020',
    marginTop: '16px'
  },
  membersFooter: {
    margin: '24px 0'
  }
}))
