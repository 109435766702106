import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { Typography, Button } from '@material-ui/core'
import ArrowDropUp from '@material-ui/icons/ArrowDropUpRounded'
import ArrowDropDown from '@material-ui/icons/ArrowDropDownRounded'
import { basedURL } from '../../helpers/domain'
import { useStyles } from './style'
import { dashboardLinks } from '../navigation-drawer/mocks'
import { teamSlice } from '../../reducers'
import { CloseTeamReviewDialog, CloseReviewBlockedDialog, TeamUpdateProfileDialog } from '../../components'

const TeamsMenu = ({
  activeInnerLink,
  activeDashboardLink,
  setActiveDashboardLink,
  closeNavBar,
  isLGScreen,
  setActiveInnerLink,
  resultsPage,
  userData,
  teamResults
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const checkEnter = (e) => e.key === 'Enter'

  const teamStatus = useSelector(teamSlice.selectors.selectStatus)
  const teamMembers = useSelector(teamSlice.selectors.selectMembers)
  const teamData = useSelector(teamSlice.selectors.selectTeam)
  const memberProps = teamMembers.filter(member => member.email === userData.hashId)
  const isAdmin = memberProps?.role === 'owner'

  const [showCloseTeamReviewDialog, setShowTeamReviewDialog] = React.useState(false)
  const [showMinimumReviewersDialog, setShowMinimunReviewersDialog] = React.useState(false)
  const [closeTeamReview] = useEvent([teamSlice.eventBus.closeTeamReview])

  const [showUpdateProfileDialog, setShowUpdateDialog] = React.useState(false)

  const teamHasProfile = teamData?.configuration?.industry !== undefined

  const handleDashboarLink = (link) => {
    if (!resultsPage) {
      navigate('/team-dashboard')
    } else {
      setActiveDashboardLink(link)
      if (!isLGScreen) {
        closeNavBar()
      }
    }
  }
  const handleDashboardInnerClick = (link) => {
    setActiveInnerLink(link)
    if (!isLGScreen) {
      closeNavBar()
    }
  }

  const handleCloseReview = () => {
    const minCompleters = teamMembers.filter(member => member.status === 'Completed').length >= 3
    if (minCompleters) {
      setShowTeamReviewDialog(true)
    } else {
      setShowMinimunReviewersDialog(true)
    }
  }

  const denyCloseReview = () => {
    setShowTeamReviewDialog(false)
  }

  const confirmCloseTeamReview = () => {
    closeTeamReview.trigger({ baseUrl: window.location.protocol + '//' + basedURL() })
    setShowTeamReviewDialog(false)
  }

  const handleCloseMinReviewers = () => {
    setShowMinimunReviewersDialog(false)
  }

  const handleUpdateProfileClick = () => {
    setShowUpdateDialog(true)
  }

  const handleUpdateProfileClose = () => {
    setShowUpdateDialog(false)
  }

  return (
    <>
      <div className={classes.linksContainer}>
        <Typography className={classes.intro}>
          {teamData ? teamData.name : 'Team name'}
        </Typography>

        {isAdmin &&
          <span data-test='nav-team-members'>
            <Typography
              onClick={() => handleDashboarLink('teams')}
              onKeyDown={(e) =>
                checkEnter(e) ? handleDashboarLink('teams') : null}
              variant='body2'
              className={
                        activeDashboardLink === 'teams'
                          ? `${classes.linksTextActive} ${isLGScreen && classes.activeShadow}`
                          : classes.linksText
                      }
              tabindex={0}
            >
              Members
            </Typography>
          </span>}
        {teamResults && (
          <div>
            <Typography
              className={`${classes.overline} ${classes.overlineMargin}`}
            >
              Team Results
            </Typography>
            {dashboardLinks.map((link, idx) => (
              <span key={idx} data-test={'nav-' + link.type}>
                <Typography
                  key={idx}
                  onClick={() => { return !teamResults ? null : handleDashboarLink(link.type) }}
                  onKeyDown={(e) => { return !teamResults ? null : checkEnter(e) ? handleDashboarLink(link.type) : null }}
                  variant='body2'
                  className={
                            !teamResults
                              ? classes.linksDisabled
                              : activeDashboardLink === link.type
                                ? `${classes.linksTextActive} ${isLGScreen && classes.activeShadow}`
                                : classes.linksText
                          }
                  tabindex={0}
                >
                  {link.text}
                  {link.innerLinks &&
                            link.innerLinks.length > 0 &&
                            (activeDashboardLink === link.type
                              ? (
                                <ArrowDropUp className={classes.dropdownArrow} />
                                )
                              : (
                                <ArrowDropDown className={classes.dropdownArrow} />
                                ))}
                </Typography>
                {activeDashboardLink === link.type &&
                          link.innerLinks &&
                          link.innerLinks.length > 0 && (
                            <div className={classes.innerLinksContainer}>
                              {link.innerLinks.map((innerLink, idx) => (
                                <Typography
                                  key={idx}
                                  onClick={() =>
                                    handleDashboardInnerClick(innerLink.type)}
                                  onKeyDown={(e) =>
                                    checkEnter(e)
                                      ? handleDashboardInnerClick(innerLink.type)
                                      : null}
                                  variant='body2'
                                  className={
                                    activeInnerLink === innerLink.type
                                      ? classes.innerLinksTextActive
                                      : classes.innerLinksText
                                  }
                                  data-test={'nav-' + innerLink.type}
                                  tabindex={0}
                                >
                                  {innerLink.text}
                                </Typography>
                              ))}
                            </div>
                )}
              </span>
            ))}
          </div>
        )}
        {
          teamHasProfile
            ? (
              <span className={classes.btnContainer}>
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.button}
                  disabled={teamStatus !== 'open'}
                  onClick={handleCloseReview}
                >
                  Close Team Review
                </Button>
              </span>
              )
            : (
              <span className={classes.btnContainer}>
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.button}
                  onClick={handleUpdateProfileClick}
                >
                  Update team profile
                </Button>
              </span>
              )
        }
      </div>
      <CloseTeamReviewDialog
        open={showCloseTeamReviewDialog}
        handleDeny={denyCloseReview}
        handleClose={confirmCloseTeamReview}
      />
      <CloseReviewBlockedDialog
        open={showMinimumReviewersDialog}
        handleOk={handleCloseMinReviewers}
      />
      <TeamUpdateProfileDialog
        open={showUpdateProfileDialog}
        handleClose={handleUpdateProfileClose}
        teamId={teamData.team}
      />
    </>
  )
}
export default TeamsMenu
