const secondAlignSection = {
  name: 'Strategy in practice and culture - Continued p1',
  elements: [
    {
      type: 'customrating',
      name: 'Align_7',
      title: '8. We work with senior management to agree organisational metrics/KPIs for measuring learning impact',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_8',
      title: '9. We analyse the organisation’s problems before recommending solutions',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_9',
      title: '10. We have a plan for how we will meet the agreed organisational metrics/KPIs',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_10',
      title: '11. We allocate learning resources to address organisational priorities',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_11',
      title: '12. Our learning initiatives support the capabilities the organisation needs',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_13',
      title: '13. We ensure that our employees understand how their work contributes to the organisation\'s performance ',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_14',
      title: '14. We regularly review our programmes to check that they support organisational goals',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default secondAlignSection
