import React from 'react'
import { FormControl, TextField, Button } from '@material-ui/core'
import { TeamDetailsCard } from '../../components'
import { useStyles } from './style'

const TeamCreateNameCard = ({ detailsPageInfo, teamNameError, register, handleCreateTeamName, saveTeamInformation }) => {
  const classes = useStyles()

  return (
    <TeamDetailsCard
      type={detailsPageInfo[0].type}
      completed={detailsPageInfo[0].completed}
      expanded={detailsPageInfo[0].expanded}
      loading={saveTeamInformation.isWorking}
    >
      <div>
        <form>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              label='Enter team name'
              variant='outlined'
              name='team'
              type='text'
              helperText={!teamNameError ? 'Maximun 50 characters' : teamNameError}
              error={teamNameError}
              inputRef={register}
              inputPros={{ 'aria-label': 'Team name' }}
              data-test='teamNameField'
              required
            />
          </FormControl>
        </form>
        <Button
          type='button'
          variant='contained'
          color='secondary'
          className={`${classes.cardButton} Team_Create_1_Next`}
          onClick={handleCreateTeamName}
        >
          Next
        </Button>
      </div>
    </TeamDetailsCard>
  )
}

export default TeamCreateNameCard
