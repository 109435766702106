const secondGoalsSection = {
  name: 'Vision, resources and impact - Continued p4',
  elements: [
    {
      type: 'customslider',
      name: 'Engagement_text1',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '2. What percentage of employees access digital learning for compliance-related courses?'
    },
    {
      type: 'customslider',
      name: 'Engagement_text3',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '3. What percentage of employees access digital learning for other, non-compliance-related courses?'
    },
    {
      type: 'customslider',
      name: 'Engagement_text6',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '4. What percentage of employees have an individual development plan?'
    }
  ]
}

export default secondGoalsSection
