const thirdInfluenceSection = {
  name: 'Strategy in practice and culture - Continued p6',
  elements: [
    {
      type: 'customrating',
      name: 'Influence_6',
      title: '34. Our organisation encourages and provides time for reflection',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Influence_7',
      title: '35. We encourage employees to take on new work experiences as an opportunity to learn',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Influence_8',
      title: '36. Our organisation understands the value of learning from mistakes',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'InfluenceCulture_4',
      title: '37. Mentoring is an important part of our work culture',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'InfluenceCulture_5',
      title: '38. Our organisation provides an open-minded environment for sharing ideas',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'InfluenceCulture_6',
      title: '39. L&D is always discussed as part of performance reviews/appraisals',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default thirdInfluenceSection
