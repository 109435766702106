const fifthEnableSection = {
  name: 'Strategy in practice and culture - Continued p10',
  elements: [
    {
      type: 'customrating',
      name: 'EnablePerformance_8',
      title: '62. Performance goals and outcomes are discussed with employees before they start any learning programme',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_9',
      title: '63. We ensure employees have easy access to job aids',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_10',
      title: '64. We continually curate content to support performance',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_12',
      title: '65. We help employees locate in-house experts when they need them',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_13',
      title: '66. We organise our learning resources to make it easy for employees to find what they need',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableDesign_12',
      title: '67. We use data analytics to understand employee learning needs',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnablePerformance_5',
      title: '68. We focus on achieving desired performance outcomes for employees',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default fifthEnableSection
