import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: 99
  },
  navMenu: {
    float: 'right'
  },
  navMenuOpen: {
    '&.MuiSvgIcon-root': {
      fill: '#006fb7'
    }
  },
  navBarOpen: {
    '&.MuiSvgIcon-root': {
      fill: '#006fb7'
    }
  },
  bar: {
    backgroundColor: '#FFFFFF',
    color: '#343337',
    paddingLeft: 0,
    paddingRight: 0
  },
  box: {
    display: 'flex'
  },
  boxMD: {
    display: 'flex',
    justifyContent: 'center'
  },
  imageContainer: {
    width: '254px',
    height: '57px',
    marginLeft: '16px'
  },
  imageContainerMD: {
    width: '254px',
    height: '57px'
  },
  image: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  title: {
    cursor: 'pointer',
    marginLeft: '8px',
    paddingTop: '8px',
    '& p': {
      fontSize: '0.75rem',
      margin: '0'
    }
  },
  link: {
    color: '#343337',
    height: '64px',
    width: '100px',
    borderRadius: 0,
    borderBottom: '5px solid #FFFFFF',
    fontSize: '14px',
    fontWeight: 700,
    '&:hover': {
      borderBottom: '5px solid #0071BD',
      backgroundColor: '#FFFFFF'
    },
    padding: 15
  },
  activeLink: {
    color: '#343337',
    width: '100px',
    fontSize: '14px',
    fontWeight: 700,
    height: '64px',
    borderRadius: 0,
    borderBottom: '5px solid #0071BD',
    padding: 15
  },
  adjustedLink: {
    color: '#343337',
    height: '64px',
    width: '110px',
    borderRadius: 0,
    borderBottom: '5px solid #FFFFFF',
    fontSize: '14px',
    fontWeight: 700,
    '&:hover': {
      borderBottom: '5px solid #0071BD',
      backgroundColor: '#FFFFFF'
    },
    padding: '12px 25px 12px 30px'
  },
  legal: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}))
