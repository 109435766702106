import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  footer: {
    position: 'relative',
    height: '10rem',
    width: '100%'
  },
  copyright: {
    position: 'absolute',
    width: '100%',
    bottom: '0.5rem'
  }
}))
