import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(_theme => ({
  root: {
    height: '100%',
    justifyContent: 'space-between'

  },
  chartLabelBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  chartLabelBoxForPdf: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 50,
    '& p': {
      fontSize: '20px !important'
    }
  },
  smallChartLabelBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '70px'
  },
  chartLabelsContainer: {
    '@media (min-width: 1200px)': {
      paddingLeft: '24px'
    }
  },
  chartBox: {
    display: 'flex',
    minHeight: '18.75rem'
  },
  bigChartBox: {
    display: 'flex',
    minHeight: '25rem'
  },
  chartLabel: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-5px'
  },
  charLabelText: {
    fontSize: '16px',
    fontWeight: 400,
    marginTop: '1px'
  },
  button: {
    margin: '25px 0'
  },
  tooltip: {
    margin: '0 6px'
  },
  iconLabel: {
    margin: '8px -5px'
  },
  svgContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '100%',
    verticalAlign: 'middle',
    overflow: 'hidden',
    minHeight: '180px',
    minWidth: '150px',
    '@media (min-width: 900px) and (max-width: 1200px)': {
      marginTop: '10px'
    }
  },
  bigSvgContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '400px',
    verticalAlign: 'middle',
    overflow: 'hidden'
  },
  svgContainerForPdf: {
    display: 'inline-block',
    position: 'relative',
    width: '450px',
    height: '450px',
    verticalAlign: 'middle',
    overflow: 'hidden'
  }
}))
