import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    margin: '0 24px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '24px 0 0'
  },
  rightButton: {
    marginLeft: 16
  },
  header: {
    marginBottom: 24
  },
  formControl: {
    marginBottom: '40px'
  },
  formControlSmall: {
    marginBottom: '24px'
  },
  formLabel: {
    fontWeight: 'bold',
    marginBottom: '12px'
  },
  errorText: {
    color: '#B00020',
    marginTop: '16px'
  }
}))
