// This dictionary contains 15 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { surveyLocalization } from "survey-core";

export var teluguStrings = {
  pagePrevText: "వెనకటి",
  pageNextText: "తరువాయ",
  completeText: "పూర్తి చేయండి",
  previewText: "ముందుగా వీక్షించు",
  editText: "మార్పులు చెయ్యి",
  startSurveyText: "ప్రారంభించు",
  otherItemText: "ఇతరమైన(వివరించండి)",
  // noneItemText: "None",
  selectAllItemText: "అన్ని ఎంచుకో",
  progressText: "పేజీ{0}/{1}",
  // indexText: "{0} of {1}",
  panelDynamicProgressText: "దాఖలాలు{0}/{1}",
  questionsProgressText: "{0}/{1} ప్రశ్నలకు జవాబు ఇవ్వడం అయినది",
  emptySurvey: "ఈ సర్వేలో పేజీలు గాని ప్రశ్నలు గాని కనబడుట లేదు",
  completingSurvey: "సర్వేను ముగించి నందుకు ధన్యవాదములు",
  completingSurveyBefore: " మీరు ఇప్పటికే సర్వేను ముగించినట్లు మా రికార్డులు చూపిస్తున్నాయి",
  loadingSurvey: "సర్వే లోడ్ అవుతుంది",
  placeholder: "ఎన్నుకోండి",
  // ratingOptionsCaption: "Tap to rate here...",
  value: "విలువ",
  requiredError: "దయచేసి ప్రశ్నకు జవాబు ఇవ్వండి",
  requiredErrorInPanel: "దయచేసి కనీసం ఒక్క ప్రశ్నకైనా జవాబు ఇవ్వండి",
  requiredInAllRowsError: "దయచేసి అన్ని వరుసలలో ఉన్న ప్రశ్నలకు జవాబు ఇవ్వండి",
  numericError: "విలువను సంఖ్యలలో తెలియజేయండి",
  // minError: "The value should not be less than {0}",
  // maxError: "The value should not be greater than {0}",
  textMinLength: "దయచేసి కనీసం {0} అక్షరాలను నమోదు చేయండి",
  textMaxLength: "దయచేసి {0} కన్నా తక్కువ అక్షరాలను నమోదు చేయండి",
  textMinMaxLength: "దయచేసి {0} కన్నా ఎక్కువ మరియు{1} కన్నా తక్కువ అక్షరాలను నమోదు చేయండి",
  minRowCountError: "దయచేసి కనీసం {0}వరుసలను పూరించండి",
  minSelectError: "దయచేసి కనీసం{0} రకాలను ఎన్నుకోండి",
  maxSelectError: "దయచేసి {0} కన్నా ఎక్కువ రకాలను ఎన్నుకో కండి",
  numericMinMax: "'{0}' {1}తొ సమానంగా లేదా {1} కన్నా ఎక్కువ గా మరియు  {2}కన్నా తక్కువ ఉండాలి లెదా {2}తొ సమానంగా ఉండాలి",
  numericMin: "''{0}' {1}తొ  సమానంగా లేదా  {1}కన్నా ఎక్కువగా ఉండాలి",
  numericMax: "'{0}'  {1}  తక్కువ లేదా సమానంగా ఉండాలి",
  invalidEmail: "దయచేసి సరైన ఈమెయిల్  నమోదు చేయండి",
  invalidExpression: "{0} 'నిజం' అని తిరిగివ్వాలి",
  urlRequestError: "విన్నపము {0} ని తప్పుగా గుర్తించింది {1}",
  urlGetChoicesError: "విన్నపము ఖాళీ డేటాని తిరిగిచ్చింది, లేదా path తప్పైన ది",
  exceedMaxSize: "ఫైల్ పరిమాణం{0} కంటే పెద్దదిగా ఉండకూడదు",
  otherRequiredError: "దయచేసి ఇతర విలువలను నమో దించండి",
  uploadingFile: "మీ ఫైల్ అప్లోడ్ అవుతున్నది దయచేసి కొన్ని సెకండ్లు వేచి ఉండండి మరియు మళ్లీ ప్రయత్నించండి",
  loadingFile: "లోడ్ అవుతున్నది",
  chooseFile: "ఫైళ్లను ఎన్నుకోండి",
  noFileChosen: "ఏ ఫైల్ ఎన్నుకో లేదు",
  // fileDragAreaPlaceholder: "Drop a file here or click the button below to load the file.",
  confirmDelete: "మీరు రికార్డులను తొలగించాలని అనుకుంటున్నారా",
  keyDuplicationError: "విలువ ప్రత్యేకంగా ఉండాలి",
  addColumn: "కాలం చేర్పించండి",
  addRow: "వరుసలు చేర్పించండి",
  removeRow: "తీసేయండి",
  // emptyRowsText: "There are no rows.",
  addPanel: "కొత్త దాన్ని చేర్పించండి",
  removePanel: "తీసేయండి",
  choices_Item: "వస్తువులు",
  matrix_column: "కాలం ",
  matrix_row: "వరుస",
  // multipletext_itemname: "text",
  savingData: "ఫలితాంశాలు సర్వర్లో సేవ్ అవుతున్నాయి",
  savingDataError: "ఒక లోపము సంభవించినది అందుకని ఫలితాంశాలను సేవ్ చేయలేకపోయాము  ",
  savingDataSuccess: "ఫలితాంశాలను విజయవంతంగా సేవ్ చేసాము",
  saveAgainButton: "మళ్లీ ప్రయత్నించు",
  timerMin: "నిమిషాలు ",
  timerSec: "సెకండ్లు",
  timerSpentAll: "ఈ పేజీ పైన మీరు{0}  ఉపయోగించారు మొత్తంగా  {1} ఉపయోగించారు",
  timerSpentPage: "ఈ పేజీ పైన మీరు{0} ఉపయోగించారు",
  timerSpentSurvey: "మీరు మొత్తంగా {0}  ఉపయోగించారు",
  timerLimitAll: "ఈ పేజీ పైన మీరు {1}లో {0} భాగాన్ని ఉపయోగించారు. మోతంగా {3}లో {2} భాగాన్ని ఉపయోగించారు.",
  timerLimitPage: "ఈ పేజీలో మీరు {1}లోని{0} ని ఉపయోగించారు",
  timerLimitSurvey: " మొత్తంగా మీరు {1} లో {0} ని ఉపయోగించారు ",
  clearCaption: "స్పష్టమ్",
  // signaturePlaceHolder: "Sign here",
  chooseFileCaption: "ఫైల్ ఎంచుకోండి",
  removeFileCaption: "ఈ ఫైల్ తీసేయండి",
  booleanCheckedLabel: "అవును",
  booleanUncheckedLabel: "లేదు",
  confirmRemoveFile: " ఈ ఫైల్ని తీయించాలని మీరు ఖచ్చితంగా అనుకుంటున్నారా:{0} ?",
  confirmRemoveAllFiles: "అన్ని ఫైళ్లను తీసేయాలని మీరు ఖచ్చితంగా అనుకుంటున్నారా",
  questionTitlePatternText: "ప్రశ్న శీర్షిక",
  // modalCancelButtonText: "Cancel",
  // modalApplyButtonText: "Apply",
  // filterStringPlaceholder: "Type to search...",
  // emptyMessage: "No data to display",
  // noEntriesText: "There are no entries yet.\nClick the button below to add a new entry.",
  // more: "More"
};

surveyLocalization.locales["tel"] = teluguStrings;
surveyLocalization.localeNames["tel"] = "Telugu";
