import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(_theme => ({
  paperRoot: {
    padding: '24px',
    marginTop: '24px'
  },
  trendsBox: {
    display: 'flex',
    flexDirection: 'row'
  },
  trendBox: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px',
    margin: '12px 0 0'
  },
  trendIcon: {
    width: '75px',
    height: '55px',
    margin: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hoverIconButton: {
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  arrowIcon: {
    fontSize: '14px !important'
  }
}))
