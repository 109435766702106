import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
  },
  tabs: {
    borderBottom: '1px solid rgba(52, 51, 55, 0.12)'
  },
  tab: {
    minWidth: '140px'
  }
}))
