import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useSelector, useDispatch } from 'react-redux'
import { surveySlice } from '../../reducers'
import LPHeader from './header'
import LPFirstRow from './first-row'
import LPHelpRow from './help-row'
import LPWorksRow from './works-row'
import LPCompletersRow from './completers-row'
import LPDataRow from './data-row'
import LPParticipantsRow from './participants-row'
import LPTeamRow from './team-row'
import LPFaqRow from './faq-row'
import LPFooter from './footer'
import ReportDialog from './report-dialog'

const ExternalLandingPage = () => {
  const dispatch = useDispatch()
  const emailSaved = useSelector(surveySlice.selectors.selectUserEmailSaved)
  // const emailSavedError = useSelector(surveySlice.selectors.selectUserEmailSavedError)
  const sampleReportUrl = useSelector(surveySlice.selectors.selectSampleReportUrl)
  const [getSampleReport] = useEvent([surveySlice.eventBus.getSampleReport])
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const handleClickDownload = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    dispatch(surveySlice.actions.resetEmailSaved())
  }

  useEventsOnViewLoad(() => {
    getSampleReport.trigger()
  }, [getSampleReport])

  useEffect(() => {
    if (emailSaved && sampleReportUrl.length) {
      window.open(sampleReportUrl)
      handleCloseDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSaved, sampleReportUrl])
  return (
    <>
      <Grid container spacing={0}>
        <LPHeader />
        <LPFirstRow />
        <LPHelpRow />
        <LPWorksRow
          handleClickDownload={handleClickDownload}
        />
        <LPCompletersRow />
        <LPDataRow />
        <LPParticipantsRow />
        <LPTeamRow />
        <LPFaqRow />
        <LPFooter />
        <ReportDialog
          open={dialogOpen}
          handleClose={handleCloseDialog}
        />
      </Grid>
    </>
  )
}

export default ExternalLandingPage
