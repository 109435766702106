const firstOrganisationGoalsSection = {
  name: 'Current goals and vision - Organisation p1',
  elements: [
    {
      type: 'matrix',
      name: 'Driver_1',
      title: '1. Which of the following benefits has your learning strategy delivered to the organisation in the past 12 months?',
      hideNumber: true,
      customMatrixTop: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_1',
          text: 'Proven compliance with new regulations'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_2',
      title: 'Which of the following benefits has your learning strategy delivered to the organisation in the past 12 months?',
      hideNumber: true,
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_2',
          text: 'Boosted the implementation of changes to systems and processes'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_3',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      hideNumber: true,
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_3',
          text: 'Improved on-the-job productivity'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_5',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_5',
          text: 'Increased employee retention'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_7',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_7',
          text: 'Accelerated response times for changing organisational conditions'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_9',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_9',
          text: 'Enhanced organisational performance'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Driver_10',
      title: 'What are the benefits your learning strategy is delivering to the business and its goals (Please select all that apply)',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Driver_10',
          text: 'Increased innovation'
        }
      ]
    }
  ]
}

export default firstOrganisationGoalsSection
