const firstAlignSection = {
  name: 'Strategy in practice and culture',
  elements: [
    {
      type: 'customrating',

      name: 'Align_1',
      title: 'Our learning strategy allows for changing business priorities',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_2',
      title: 'L&D team activity is fully aligned with the organisation\'s strategic goals',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_3',
      title: 'Business leaders recognise that learning is aligned with the overall organisation plan',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_4',
      title: 'Our organisation assigns board-level accountability for organisational learning',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_5',
      title: 'Everyone on the L&D team understands the organisation’s strategic goals',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_6',
      title: 'Our learning initiatives are delivered in time to meet the organisation’s needs',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Align_12',
      title: 'Every stakeholder shares our vision for organisational learning',
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default firstAlignSection
