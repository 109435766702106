import React from 'react'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { useStyles } from './style'
import { Skeleton } from '@material-ui/lab'
import { Dropdown } from '..'

const DashboardScoreSkeleton = () => {
  const matchesXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const classes = useStyles()
  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item sm={8}>

        <Skeleton variant='text'>
          <Typography variant='h1'>
            Your learning maturity score
          </Typography>
        </Skeleton>
        <Skeleton variant='text'>
          <Typography className={classes.introText}>
            Let's delve into the statistics behind your overall score, and explore
            how you compare to other organisations that have participated over the
            last few years.
          </Typography>
        </Skeleton>
        <Skeleton variant='text'>
          <Dropdown dropdown='How is my score calculated?' />
        </Skeleton>

      </Grid>
      <Grid item md={1} lg={1} style={{ display: matchesXl ? 'none' : '' }} />
      <Grid item md={3} lg={3} xl={4}>

        <Skeleton variant='circle'>
          <div className={classes.img}>
            <img src='/img/dashboard-summary.png' alt='' />
          </div>
        </Skeleton>
      </Grid>
      <Grid item md={4} lg={4} className={classes.chartsContainer}>
        <Skeleton variant='rect' width='100%' height={600} />
      </Grid>
      <Grid item md={8} lg={8} className={classes.chartsContainer}>
        <Skeleton variant='rect' width='100%' height={600} />
      </Grid>
      <Grid item xs={12} classes={{ root: classes.takeaways }}>
        <Skeleton variant='text'>
          <Typography variant='body1' className={classes.takeawaysTitle}>Quick takeaways</Typography>

        </Skeleton>
        <Skeleton variant='text'>
          <Typography variant='body1'>
            Remember, your score is measured on a scale of 1–100, and is revisited
            annually to ensure statistical validity and reliability.
          </Typography>
        </Skeleton>
        <Grid alignItems='center' container spacing={2}>

          <Grid item xs={12} md={3} lg={3}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>

        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='text'>
          <Typography variant='h2'>
            Recommended reading to improve your score
          </Typography>
        </Skeleton>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Skeleton variant='rect' width='100%' height={200} />
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default DashboardScoreSkeleton
