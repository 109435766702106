const firstListenSection = {
  name: 'Strategy in practice and culture - Continued p2',
  elements: [
    {
      type: 'customrating',
      name: 'Listen_1',
      title: '15. Self-determined learning is common practice in our organisation',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Listen_3',
      title: '16. Employees can access clear information on learning opportunities available to them',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'InfluenceCulture_2',
      title: '17. Employees can access learning that is directly relevant to their job',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Listen_6',
      title: '18. We support employees in gaining organisation-specific/professional certification',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Listen_9',
      title: '19. We provide a broad range of non-job-related learning opportunities',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Listen_10',
      title: '20. We are proactive in understanding how employees currently learn what they need for their job',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Listen_16',
      title: '21. We support career development through digital learning',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Listen_7',
      title: '22. Employees are recognised for their learning achievements',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default firstListenSection
