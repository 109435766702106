import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  stepperRoot: {
    background: '#EEEFF1',
    overflow: 'hidden'
  },
  drawer: {
    width: '296px',
    zIndex: '9 !important'
  },
  paperHide: {
    left: '72px',
    width: '16px',
    transition: 'width 0.25s ease-in-out',
    backgroundColor: '#EEEFF1'
  },
  paperHideHome: {
    width: '16px',
    transition: 'width 0.25s ease-in-out',
    backgroundColor: '#EEEFF1'
  },
  paper: {
    width: '296px',
    transition: 'width 0.25s ease-in-out',
    backgroundColor: '#EEEFF1'
  },
  bigDrawer: {
    width: '375px',
    transition: 'width 0.25s ease-in-out',
    zIndex: '9 !important'
  },
  bigPaper: {
    width: '296px',
    transition: 'width 0.25s ease-in-out',
    backgroundColor: '#EEEFF1'
  },
  toolbar: {
    minHeight: '64px'
  },
  toolbarXL: {
    position: 'fixed',
    width: '72px',
    height: '100%',
    left: '0px',
    top: '64px',
    background: '#D3D8DE',
    border: '1px solid rgba(37, 55, 70, 0.12)',
    zIndex: 10
  },
  listItemXL: {
    marginLeft: '3px',
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': {
      marginLeft: '-7px'
    },
    cursor: 'pointer'
  },

  activeItemXL: {
    color: theme.palette.secondary.main
  },

  marginXL: {
    marginLeft: '72px'
  },

  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    whiteSpace: 'nowrap'
  },
  item: {
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '14px',
    cursor: 'pointer'
  },
  activeItem: {
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '14px',
    cursor: 'pointer'
  },
  stepLabelContainer: {
    whiteSpace: 'nowrap'
    // width: '300px'
  },
  stepLabelLeftGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left'
  },
  stepLabelRightGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  stepLabel: {
    fontWeight: 400,
    color: 'rgba(37, 55, 70, 0.38)',
    cursor: 'pointer',
    width: '186px',
    whiteSpace: 'no-wrap'
  },
  activeStepLabel: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    width: '186px',
    whiteSpace: 'no-wrap'
  },
  completeStepLabel: {
    fontWeight: 400,
    color: 'rgba(37, 55, 70)',
    cursor: 'pointer',
    width: '186px',
    whiteSpace: 'no-wrap'
  },
  activeStepChip: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main
  },
  activeStepIcon: {
    color: theme.palette.secondary.main
  },
  innerStepLabel: {
    cursor: 'pointer',
    padding: '2px 0',
    fontSize: '12px'
  },
  activeInnerStepLabel: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: '12px',
    padding: '2px 0'
  },
  linksContainer: {
    marginLeft: '16px'
  },
  linksText: {
    margin: '8px 0',
    cursor: 'pointer'
  },
  linksTextActive: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    margin: '8px 0',
    cursor: 'pointer'
  },
  innerLinksContainer: {
    marginLeft: '16px'
  },
  percentageBlock: {
    width: '80%',
    bottom: '50px',
    margin: '0rem 1.25rem 0',
    display: 'flex',
    alignItems: 'center',
    '@media (min-height: 840px)': {
      position: 'absolute'
    }
  },

  percentage: {
    width: '45%',
    marginRight: '12px'
  },
  linearDeterminateBar: {
    backgroundColor: '#FF8200'
  },
  linearDeterminateBackground: {
    backgroundColor: '#D3D8DE'
  },
  ewLogo: {
    padding: '0.575rem 1.25rem'
  },
  ewLogoOnSurvey: {
    position: 'absolute',
    paddingTop: 62
  },
  boxGrow: {
    flexGrow: 1
  },
  menuItem: {
    width: '45%'
  },
  menuItemActive: {
    width: '45%',
    color: theme.palette.secondary.main
  },

  menuItemText: {
    marginRight: 8
  },

  infoIcon: {
    width: 24,
    height: 24,
    marginRight: 8
  },
  arrowIcon: {
    width: 16,
    height: 16,
    marginLeft: -10
  },
  downloadButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '20px'
  },
  downloadProgress: {
    color: '#fff'
  }
}))
