const generalSection = {
  name: 'Challenges',
  elements: [
    {
      type: 'matrix',
      name: 'Barrier',
      title: '1. In the past 12 months, how concerned were you about the following challenges?',
      hideNumber: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Not at all concerned'
        },
        {
          value: 'Column 2',
          text: 'Somewhat concerned'
        },
        {
          value: 'Column 3',
          text: 'Extremely concerned'
        }
      ],
      rows: [
        'Learning not being seen as a management/leadership priority',
        'Many in the organisation don\'t see learning as a priority',
        'Reluctance by classroom facilitators to adopt new digital technologies',
        'Reluctance by employees to learn and work with new digital technologies',
        'Reluctance by managers to encourage new ways of learning and working',
        'Reluctance by managers to make time for learning'
      ]
    }
  ]
}

export default generalSection
