// This dictionary contains 4 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { surveyLocalization } from "survey-core";

export var slovakSurveyStrings = {
  pagePrevText: "Predchádzajúca",
  pageNextText: "Ďalej",
  completeText: "Dokončené",
  previewText: "Ukážka",
  editText: "Upraviť",
  startSurveyText: "Spustiť",
  otherItemText: "Iné (opíšte)",
  noneItemText: "Žiadne",
  selectAllItemText: "Vybrať všetky",
  progressText: "Strana {0} z {1}",
  // indexText: "{0} of {1}",
  panelDynamicProgressText: "Záznam {0} z {1}",
  questionsProgressText: "Zodpovedané otázky {0}/{1}",
  emptySurvey: "V prieskume nie je žiadna vidieľná stránka ani otázka.",
  completingSurvey: "Ďakujeme vám za dokončenie prieskumu.",
  completingSurveyBefore: "Podľa našich záznamov ste už tento prieskum dokončili.",
  loadingSurvey: "Načítanie prieskumu...",
  placeholder: "Vybrať...",
  // ratingOptionsCaption: "Tap to rate here...",
  value: "hodnota",
  requiredError: "Požaduje sa odozva.",
  requiredErrorInPanel: "Požaduje sa odozva: zodpovedajte aspoň jednu otázku.",
  requiredInAllRowsError: "Požaduje sa odozva: zodpovedajte otázky vo všetkých riadkoch.",
  numericError: "Hodnota má byť číselná.",
  minError: "Hodnota nemá byť nižšia než {0}",
  maxError: "Hodnota nemá byť vyššia než {0}",
  textMinLength: "Zadajte aspoň {0} znak(-y/-ov).",
  textMaxLength: "Nezadávajte viac než {0} znak(-y/-ov).",
  textMinMaxLength: "Zadajte aspoň {0} a nie viac než {1} znaky(-ov).",
  minRowCountError: "Vyplňte aspoň {0} riadok(-y/-ov).",
  minSelectError: "Vyberte aspoň {0} variant(-y/-ov).",
  maxSelectError: "Nevyberajte viac než {0} variant(-y/-ov).",
  numericMinMax: "„{0}“ má byť minimálne {1} a maximálne {2}",
  numericMin: "„{0}“ má byť minimálne {1}",
  numericMax: "„{0}“ má byť maximálne {1}",
  invalidEmail: "Zadajte platnú e-mailovú adresu.",
  invalidExpression: "Výraz: {0} má vrátiť hodnotu „true“.",
  urlRequestError: "Požiadavky vrátila hodnotu „{0}“. {1}",
  urlGetChoicesError: "Požiadavka vrátila prázdne údaje alebo je vlastnosť „cesta“ nesprávna",
  exceedMaxSize: "Veľkosť súboru nemá prekročiť {0}.",
  otherRequiredError: "Požaduje sa odozva: zadajte inú hodnotu.",
  uploadingFile: "Súbor sa odovzdáva. Počkajte niekoľko sekúnd a skúste to znova.",
  loadingFile: "Načítanie...",
  chooseFile: "Vyberte súbor(-y)...",
  noFileChosen: "Žiadny vybratý súbor",
  fileDragAreaPlaceholder: "Presuňte súbor sem alebo kliknite na nasledujúce tlačidlo a načítajte súbor.",
  confirmDelete: "Chcete záznam odstrániť?",
  keyDuplicationError: "Táto hodnota má byť jedinečná.",
  addColumn: "Pridať stĺpec",
  addRow: "Pridať riadok",
  removeRow: "Odstrániť",
  emptyRowsText: "K dispozícii nie sú žiadne riadky.",
  addPanel: "Pridať nové",
  removePanel: "Odstrániť",
  choices_Item: "položka",
  matrix_column: "Stĺpec",
  matrix_row: "Riadok",
  multipletext_itemname: "text",
  savingData: "Výsledky sa ukladajú na server...",
  savingDataError: "V dôsledku chyby sa nepodarilo výsledky uložiť.",
  savingDataSuccess: "Výsledky sa úspešne uložili.",
  saveAgainButton: "Skúste to znova",
  timerMin: "min",
  timerSec: "s",
  timerSpentAll: "Na tejto stránke ste strávili {0} a celkovo {1}.",
  timerSpentPage: "Na tejto stránke ste strávili {0}.",
  timerSpentSurvey: "Celkovo ste strávili {0}.",
  timerLimitAll: "Na tejto stránke ste strávili {0} z {1} a celkovo {2} z {3}.",
  timerLimitPage: "Na tejto stránke ste strávili {0} z {1}.",
  timerLimitSurvey: "Celkovo ste strávili {0} z {1}.",
  clearCaption: "Vymazať",
  signaturePlaceHolder: "Podpísať tu",
  chooseFileCaption: "Vybrať súbor",
  removeFileCaption: "Odstrániť tento súbor",
  booleanCheckedLabel: "Áno",
  booleanUncheckedLabel: "Nie",
  confirmRemoveFile: "Naozaj chcete odstrániť tento súbor: {0}?",
  confirmRemoveAllFiles: "Naozaj chcete odstrániť všetky súbory?",
  questionTitlePatternText: "Titul otázky",
  modalCancelButtonText: "Zrušiť",
  modalApplyButtonText: "Použiť",
  filterStringPlaceholder: "Vyhľadávanie písaním...",
  // emptyMessage: "No data to display",
  noEntriesText: "K dispozícii ešte nie sú žiadne zadania.\nKliknutím na nasledujúce tlačidlo pridajte nové zadanie.",
  // more: "More"
};

surveyLocalization.locales["sk"] = slovakSurveyStrings;
surveyLocalization.localeNames["sk"] = "slovenčina";
