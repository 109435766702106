import React from 'react'
import { useStyles } from './style'
import { Typography, Paper } from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

const DashboardTakeaway = ({ score, text, type, idx, isTeam }) => {
  const classes = useStyles()

  const txtPoints = score > 0 ? 'points above' : 'points below'

  const HandleIconStatements = () =>
    type !== 'maturity' &&
    type !== 'distance' &&
    (
      score >= 0
        ? (
          <ArrowUpwardIcon className={classes.scorePositive} />
          )
        : (
          <ArrowDownwardIcon className={classes.scoreNegative} />
          )
    )

  return (
    <Paper className={classes.root} data-test={`card-takeaway-${idx}`}>
      <Typography className={classes.text} variant='body1'>
        {
          isTeam && type !== 'maturity' && type !== 'distance'
            ? 'Your team is'
            : isTeam && type !== 'distance'
              ? 'Your team needs'
              : isTeam
                ? 'There is a'
                : type !== 'maturity'
                  ? 'Your are'
                  : 'You need'
        }
      </Typography>
      <span className={classes.scoreBox}>
        <Typography className={classes.score} variant='h2'>
          {Math.abs(score)}
        </Typography>
        <HandleIconStatements />
      </span>
      {
        type !== 'maturity' && type !== 'distance'
          ? (
            <Typography className={classes.text} variant='body1'>
              {txtPoints} {text}
            </Typography>
            )
          : (
            <Typography className={classes.text} variant='body1'>
              {text}
            </Typography>
            )
      }
    </Paper>
  )
}

export default DashboardTakeaway
