const secondEmployeeGoalsSection = {
  name: 'Current goals and vision - Employee p2',
  elements: [
    {
      type: 'matrix',
      name: 'Staff_7',
      title: '2 (continued). Which of the following benefits has your learning strategy delivered to employees in the past 12 months?',

      hideNumber: true,
      customMatrixTop: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_7',
          text: 'Accelerated the time it takes for employees to become capable'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_10',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_10',
          text: 'Increased self-determined learning'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_12',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_12',
          text: 'Improved performance management'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_13',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_13',
          text: 'Enhanced the ongoing sharing of knowledge'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_19',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_19',
          text: 'Improved change management'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_21',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_21',
          text: 'Strengthened employee wellbeing'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_22',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_22',
          text: 'Improved employee engagement with learning'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_20',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_20',
          text: 'Embed principles of diversity and inclusion'
        }
      ]
    }
  ]
}

export default secondEmployeeGoalsSection
