import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '8rem',
    paddingBottom: '6rem',
    paddingRight: '8rem',
    paddingLeft: '10rem'
  },
  headTitle: {
    fontSize: '2.5rem'
  },
  boxIntro: {
    marginTop: '3rem'
  },
  content: {
    fontSize: '1rem'
  },
  paragraph: {
    marginTop: '1.5rem',
    fontSize: '1rem'
  },
  subTitle: {
    marginTop: '1.5rem',
    fontSize: '1rem'
  },
  highlight: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginTop: '1.5rem'
  },
  linkLPB: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline'
  }
}))
