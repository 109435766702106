import React from 'react'
import { Page, View, Text } from '@react-pdf/renderer'
import { PDFFooter, InsightsCard } from '../../components'
import styles from './style'
import globalStyles from '../../style'

const insights = [
  'Governance',
  'Formal learning'
]

const InsightsPage1 = ({ stage }) => {
  return (
    <Page size='A4' orientation='landscape'>
      <View style={globalStyles.pageView}>
        <Text style={globalStyles.heading}>Actionable insights</Text>
        <Text style={[globalStyles.introText, { marginBottom: 10 }]}>Having completed the Learning Performance Benchmark, the next step of the journey is to take concrete actions that will help you and your team embed a high-performing learning culture.</Text>
        <Text style={[globalStyles.introText, { marginBottom: 10 }]}>The following recommendations are underpinned by two decades of research and data. The focus is on transitioning between stages, highlighting where priorities should be placed, what challenges to expect, and what L&D capabilities are required.</Text>
        <View style={styles.cardsContainer}>
          {insights.map((insight, index) => (
            <InsightsCard key={index} stage={stage} title={insight} />
          ))}
        </View>
        <PDFFooter pageNumber={13} />
      </View>
    </Page>
  )
}

export default InsightsPage1
