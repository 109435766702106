import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { AppToolbar, LPBLink } from '../../components'
import { useStyles } from './style'

const TermsOfSale = () => {
  const classes = useStyles()

  const ScrollToTopOnMount = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return null
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <AppToolbar />
      <Grid
        className={classes.root}
        container
        direction='column'
        justify='space-between'
        alignItems='flex-start'
      >
        <Grid item xs={8}>
          <Typography className={classes.headTitle} variant='h1'>
            Terms of Sale for Learning Performance Benchmark
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Overview
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We, Mind Tools Ltd (<strong>Mind Tools</strong>), sell
            you access to our Learning Performance Benchmark (LPB) via{' '}
            <a
              className={classes.linkExternal}
              href='https://lpbenchmark.mindtools.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://lpbenchmark.mindtools.com/
            </a>{' '}
            (our “Website”) for the duration of a Season subject to these terms
            and conditions (Terms of Sale) and the associated{' '}
            <LPBLink
              className={classes.linkLPB}
              target='_blank'
              rel='noopener noreferrer'
              to='/terms-and-conditions'
            >
              Terms of Use.
            </LPBLink>{' '}
            “You” as used in these terms means the natural person or entity that
            has agreed to be bound by these terms. “Season” as used in these
            terms shall mean a calendar year.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            By ordering access to the LPB, you agree to be bound by these terms.
            Please read them carefully. We may refuse to grant access to the LPB
            for non-compliance with any of these terms.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Please see our{' '}
            <LPBLink
              className={classes.linkLPB}
              target='_blank'
              rel='noopener noreferrer'
              to='/privacy-policy'
            >
              Privacy Notice
            </LPBLink>{' '}
            our{' '}
            <LPBLink
              className={classes.linkLPB}
              target='_blank'
              rel='noopener noreferrer'
              to='/cookies-policy'
            >
              Cookies Notice
            </LPBLink>{' '}
            to understand how we collect and process your personal information
            through our{' '}
            <a
              className={classes.linkExternal}
              href='https://lpbenchmark.mindtools.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Website
            </a>{' '}
            The terms applicable to the use of the LPB can be found in our Terms
            of Use. If there is any conflict between these Terms of Sale and the
            Terms of Use, these Terms of Sale shall prevail.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            The LPB
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Access shall be provided to the Learning Performance Benchmark
            offered by Mind Tools as set out on the Website and as updated from
            time to time. Some access may be provided to you free of charge,
            however, such free access is subject to change and is provided at
            the sole discretion of MindTools.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Your Order
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Your order is submitted when you press the pay button during the
            online purchase process. It is an offer to purchase access to the
            LPB for the duration of the Season within which the order is placed.
            Please read and check your order carefully before submitting it. If
            you need to correct any errors, you can do this before submitting it
            to us.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            By submitting your order, you agree we may process your order and
            give you access to the LPB as soon as possible. We will process your
            order on receipt, but we do not guarantee an immediate response or
            immediate access to the LPB.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            No order is accepted, and no legally binding contract is created
            between us, unless and until we give you access to the LPB.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Access to the LPB:
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Once your order is accepted, MindTools will give you access to the
            LPB ordered for the duration of the Season within which the order is
            placed, starting from the date you place your order via the Website.
            During this period, you may use the LPB and download a copy of the
            results for your organization. Upon expiry of the Season, for any
            period permitted by MindTools at its sole discretion, you may
            continue to access and download any previous results.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The LPB and any downloads must be used solely for internal business
            purposes and otherwise in accordance with our Terms of Use.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You are responsible for all hardware, software, network availability
            and internet connectivity required to access the LPB.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will use all reasonable efforts (i) to provide uninterrupted
            access to the LPB for the relevant period and to restore access as
            soon as possible in the event of an interruption to or suspension of
            access, and (ii) to ensure that it is free from defects, viruses and
            all other malicious content. We do not promise that the LPB is
            compatible with any third-party software or equipment and you
            acknowledge that there may be minor errors or bugs in it.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Price
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Prices will be shown in the currency displayed on the Website and
            are subject to change without notice.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Unless otherwise indicated, prices stated on our Website are
            exclusive of legally applicable taxes.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Please be aware that taxes may apply to your purchase depending on
            your location and if this is the case, the breakdown of such legally
            applicable taxes which you are required to pay will be shown prior
            to payment and on your confirmation email.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Before and after your purchase, we are unable to accept tax
            exemption codes or other evidence that it is not appropriate for you
            to pay tax in relation to the digital content. For the avoidance of
            doubt, this means that if you provide such evidence after you’ve
            submitted your order, we are not able to provide you a with a tax
            refund.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Payment
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We use a third-party payment processor, Recurly, to operate our
            checkout and process your payment. You acknowledge that you will be
            responsible for compliance with any third-party payment processor’s
            terms and conditions made available to you.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The following payment methods are accepted: American Express, Visa
            and Mastercard.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            All credit/debit card payments are subject to authorisation by the
            card issuer. Access to the LPB will not be given until your payment
            has been authorised
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will do all that we reasonably can to ensure that all the
            information you share when paying for access to the LPB is secure by
            providing a secure payment mechanism. However, in the absence of (i)
            negligence on our part; (ii) any failure by us to comply with this
            contract or our Privacy Notice; or (iii) breach by us of our duties
            under applicable laws, we will not be legally responsible to you for
            any loss that you may suffer if a third party gains unauthorised
            access to that information.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Permission to Use (Licence)
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            When you purchase access to the LPB and any part of the LPB is
            downloaded, you will not own the LPB or any such part. Instead we
            give you permission to use it (also known as a ‘licence’) for the
            purpose of you using it according to these terms and the Terms of
            Use.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Cancellation
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Please contact us using the details at the bottom of this page if
            you are unsatisfied with the LPB you have paid for access to, and we
            will help you. All orders for the LPB are final, non-cancelable and
            non-refundable.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Electronic Communications
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            When you place an order for access to the LPB on our Website, you
            are communicating with us electronically. We will communicate with
            you electronically by email and through our Website. You agree that
            all communications and notices that we provide to you electronically
            satisfy any legal requirement that such communications be in
            writing.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Limit On Our Responsibility To You
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Except for any legal responsibility that we cannot exclude in law
            (such as for death or personal injury) or arising under applicable
            laws relating to the protection of your personal information, we are
            not legally responsible for (i) losses that were not caused by our
            breach of these terms, or (ii) any business loss (including loss of
            profits, revenue, contracts, anticipated savings, data, goodwill or
            wasted expenditure), or (iii) that were not foreseeable to both you
            and us when the contract was formed, or (iv) that were caused by any
            event or circumstance beyond our reasonable control.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The laws of some countries do not allow some or all of the
            limitations described above. If these laws apply to you, some or all
            of the above limitations may not apply to you and you might have
            additional rights.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Complaints
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will try to resolve any complaints or disputes quickly and
            efficiently. If you are unhappy with your purchase, please contact
            us as soon as possible using the contact details below.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Disputes
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            These terms are governed by and construed in accordance with the
            laws of England. The courts of England shall have non-exclusive
            jurisdiction over any court proceedings.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Modifications to these Terms
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We reserve the right to make changes to our Website, policies,
            notices and terms, including these terms of sale at any time. You
            will be subject to the terms in force at the time you order from us.
            If any of these terms is deemed invalid, void or unenforceable that
            term will be deemed severable and will not affect the validity and
            enforceability of any remaining term.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Our contact details
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We are registered in Scotland under company number: SC 202102. Our
            registered office is at: Level 1, 50 Frederick Street, Edinburgh,
            Scotland, EH2 1EX. Our VAT number is: 665359306
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you require any further information about these terms or if you
            are not satisfied in any way with your purchase, please contact us
            by email at the following address: lpbenchmark@mindtools.com.
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default TermsOfSale
