import React, { useRef, useEffect } from 'react'
import { drawChart, drawComponentsChart, drawStageChart } from './draw'
import './chart.css'
import { useStyles } from './style'
import { useMediaQuery } from '@material-ui/core'

const LinearChart = ({ id = null, type, filters, scores, forPdf = false }) => {
  const isXSScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isMDScreen = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'))
  const isLGScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  let screenSize = (isXSScreen && 'xs') || (isMDScreen && 'md') || (isLGScreen && 'lg')
  if (forPdf) screenSize = 'lg'
  const classes = useStyles()
  const ref = useRef()
  const [chartWidth, setDimension] = React.useState(null)

  const chartData = scores ? scores.filter(data => filters.indexOf(data.type) > -1) : []
  useEffect(() => {
    if (ref.current) {
      setDimension(Math.round(ref.current.offsetWidth))
    }
    if (chartWidth && ref.current && chartData) {
      if (type === 'overall') drawChart(chartData, screenSize, ref, forPdf)
      if (type === 'components') drawComponentsChart(chartData, screenSize, ref, forPdf)
      if (type === 'stage') drawStageChart(chartData, screenSize, ref, forPdf)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, screenSize, filters])

  return (
    <div ref={ref} className={!forPdf ? classes.svgContainer : classes.svgContainerForPdf} id={id} />
  )
}

export default LinearChart
