import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: '-999px',
    width: '1px',
    height: '1px',
    top: 'auto',
    '&:focus, &:focus-visible': {
      display: 'inline-block',
      height: 'auto',
      width: 'auto',
      position: 'absolute',
      top: 50,
      left: 50,
      margin: 'auto',
      zIndex: '10000000000',
      border: 'none !important',
      outline: 'none !important'
    }

  },
  rootFocused: {
    display: 'inline-block',
    height: 'auto',
    width: 'auto',
    position: 'absolute',
    top: 50,
    left: 50,
    margin: 'auto',
    zIndex: '10000000000',
    border: 'none !important',
    outline: 'none !important'
  },
  paper: {
    padding: 20,
    margin: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  },
  headerText: {
    fontWeight: 700,
    fontSize: '1.5rem'
  },
  linkList: {
    textDecoration: 'none',
    '& li': {
      marginLeft: '-40px',
      listStyleType: 'none'
    }
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline'
  }
}))
