import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.secondary.main,
    color: '#FFF',
    padding: '16px 16px',
    borderRadius: '8px',
    textAlign: 'center',
    margin: '20px 0 40px'
  },
  leftSide: {
    marginRight: '16px'
  },
  rightSide: {
    textAlign: 'left'
  },
  textStyle: {
    margin: 0,
    paddingTop: '2px'
  }
}))
