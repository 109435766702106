const secondTechnologySection = {
  name: 'Technology, tools and access p1',
  elements: [
    {
      type: 'matrix',
      name: 'Tech Content',
      title: '2. Which of the following are you using or planning to use over the next 12 months?',
      hideNumber: true,
      columns: [
        {
          value: 'Column 1',
          text: 'We use this now'
        },
        {
          value: 'Column 2',
          text: 'We\'re just experimenting / trialling it'
        },
        {
          value: 'Column 3',
          text: 'We plan to use this in the next 12 months'
        },
        {
          value: 'Column 4',
          text: 'We do not use this'
        }
      ],
      rows: [
        'User-generated content',
        'Interactive video',
        'Open education resources/digital materials offered free at the point of use (e.g., Wikipedia, YouTube, OU, TED)',
        'Job aids (e.g., PDF checklists, infographics)',
        'Immersive learning environments (e.g., simulations and artificially created environments)',
        'Augmented reality (e.g., live situations augmented by computer-generated sensory input such as sound or video)',
        'Virtual reality (e.g., Oculus Rift)',
        'Learning Management Systems - stand-alone',
        'Learning management systems integrated with HR systems and MIS',
        'Online content platforms (e.g., LinkedIn Learning, MindTools)'
      ]
    },
    {
      type: 'checkbox',
      name: 'question24',
      visibleIf: '{Tech Content.Learning Management Systems - integrated with HR systems or MIS} anyof ["Column 1", "Column 2"] or {Tech Content.Learning Management Systems - stand-alone} anyof ["Column 1", "Column 2"]',
      title: '2a. How are you using your Learning Management System?',
      hideNumber: true,
      choices: [
        'To store, deliver and track online courses',
        'To manage face-to-face programmes',
        'To support our virtual classroom environment',
        'To integrate with external video libraries',
        'As a cloud based service',
        'To integrate with our HR systems/Management Information Systems',
        'Linked to capability and talent management',
        'To support social learning/collaboration and communication',
        'To support mobile learning',
        'To use big data/learning analytics to create more efficient and effective environments for your people'
      ]
    }
  ]
}

export default secondTechnologySection
