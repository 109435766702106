const secondInfluenceSection = {
  name: 'Strategy in practice and culture - Continued p5',
  elements: [
    {
      type: 'customrating',
      name: 'InfluenceCulture_9',
      title: '29. We have defined capabilities for every job role',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'InfluenceCulture_10',
      title: '30. We map our learning resources to our capability framework',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'InfluenceCulture_11',
      title: '31. We expect managers to take responsibility for developing the skills of their employees',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'InfluenceCulture_12',
      title: '32. Performance management is integrated into our learning strategy',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'Influence_4',
      title: '33. Our managers recognise the value of learning in the flow of work',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default secondInfluenceSection
