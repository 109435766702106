import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    padding: '24px',
    minHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}))
