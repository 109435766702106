import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#117D64',
    color: '#FFF',
    width: 'fit-content',
    padding: '16px 32px',
    borderRadius: '8px',
    position: 'absolute',
    top: '24px',
    left: 0,
    right: 0,
    marginRight: 'auto',
    marginLeft: 'auto',
    zIndex: 9999,
    textAlign: 'center'
  },
  eRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#F73718',
    color: '#FFF',
    width: 'fit-content',
    padding: '16px 32px',
    borderRadius: '8px',
    position: 'absolute',
    top: '96px',
    left: 0,
    right: 0,
    marginRight: 'auto',
    marginLeft: 'auto',
    zIndex: 9999,
    textAlign: 'center'
  },
  leftSide: {
    marginRight: '16px'
  },
  rightSide: {
    textAlign: 'left'
  },
  textStyle: {
    margin: 0
  }
}))
