import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginLeft: 8,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  }
}))
