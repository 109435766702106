const firstEmployeeGoalsSection = {
  name: 'Current goals and vision - Employee p1',
  elements: [
    {
      type: 'matrix',
      name: 'Staff_1',
      title: '2. Which of the following benefits has your learning strategy delivered to employees in the past 12 months?',
      hideNumber: true,
      customMatrixTop: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_1',
          text: 'Improved induction and onboarding processes'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_2',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_2',
          text: 'Improved learning accessibility'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_3',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_3',
          text: 'Enhanced ability to personalise learning to employee needs'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_4',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_4',
          text: 'Developed a better-qualified workforce'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_5',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_5',
          text: 'Strengthened communication and teamwork'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Staff_6',
      title: 'What are the benefits that your learning strategy is delivering to individuals and their needs?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Staff_6',
          text: 'Improved the application of learning in the workplace'
        }
      ]
    }
  ]
}

export default firstEmployeeGoalsSection
