import { scaleLinear, scaleBand, select, axisBottom, axisLeft, sum } from 'd3'

const setBarHeight = height => {
  if (height > 40) {
    return 40
  }

  return height
}

export const drawChart = (data, limit, screenSize, ref, forPdf) => {
  const margin = 60

  let chartWidth = 450 - 2 * margin
  const chartHeight = 450 - 2 * margin
  const scoreIntervals = limit === 10 ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  switch (screenSize) {
    case 'xs':
      chartWidth = 600
      break
    case 'md':
      chartWidth = 600
      break
    case 'lg':
      chartWidth = 750
      break
    default:
      break
  }

  const xScale = scaleLinear([limit, 0], [chartWidth, 0])

  const yScale = scaleBand()
    .range([0, chartHeight])
    .domain(data.map((item) => item.member))
    .padding(0.3)

  const chart = select(ref.current)
    .html('')
    .append('svg')
    .attr('class', 'lineChartSvgContent')
    .attr('viewBox', `0 0 ${chartWidth + margin * 2} ${chartHeight + margin * 2}`)
    .attr('aria-label', 'line chart')
    .attr('align', 'center')
    .append('g')
    .attr('transform', `translate(${margin}, ${margin})`)

  const tooltip = select(ref.current)
    .append('div')
    .attr('class', 'tooltip')

  const xAxis = axisBottom(xScale)
    .tickValues(scoreIntervals)
    .tickSizeOuter(0)

  chart
    .append('g')
    .attr('transform', `translate(0, ${chartHeight})`)
    .attr('class', forPdf ? 'x-axis-pdf' : 'x-axis')
    .call(xAxis)

  const yAxis = axisLeft(yScale)
    .tickSizeOuter(0)

  chart
    .append('g')
    .attr('transform', 'translate(0, 0)')
    .attr('class', forPdf ? 'y-axis-pdf' : 'y-axis')
    .call(yAxis)

  const bars = chart
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)
    .selectAll('rect')
    .data(data)
    .join('rect')
    .attr('width', data => xScale(data.score))
    .attr('height', setBarHeight(yScale.bandwidth()))
    .attr('y', data => yScale(data.member))
    .attr('x', 2.5)
    .attr('fill', d => d.color)
    .style('stroke', d => d.color)

  // calculate the average score
  const dataSum = sum(data, function (d) {
    return d.score
  })

  // drawing dashed line to represent the average score
  chart.append('line')
    .attr('x1', xScale(dataSum / data.length))
    .attr('x2', xScale(dataSum / data.length))
    .attr('y1', 0)
    .attr('y2', chartHeight)
    .attr('fill', '#253746')
    .style('stroke-dasharray', ('3, 3'))
    .style('stroke-opacity', 0.9)
    .style('stroke', '#253746')

  // label for team average score
  chart.append('text')
    .text('Team average')
    .attr('text-anchor', 'middle')
    .attr('x', xScale(dataSum / data.length))
    .attr('y', -5)
    .attr('font-family', 'Matter')
    .attr('font-size', '12px')
    .attr('font-weight', '700')
    .attr('fill', '#253746')

  const showTooltip = (e, d) => {
    return tooltip
      .style('left', `${e.pageX + 10}px`)
      .style('top', `${e.pageY}px`)
      .style('display', 'inline-block')
      .text(d.score.toFixed(2))
  }

  const hideTooltip = () => {
    return tooltip.style('display', 'none')
  }

  bars.on('mousemove', showTooltip)
  bars.on('mouseout', hideTooltip)
}
