import * as actions from './slice'
import * as userActions from '../user/slice'

export const saveSurveyResponses = {
  eventName: 'saveSurveyResponses',
  onSuccess: [{ redux: true, action: actions.setSurveyFeedback }],
  onError: [{ redux: true, action: actions.saveSurveyResponsesError }]
}

export const retrieveUserResponses = {
  eventName: 'getSurveyResponses',
  onSuccess: [{ redux: true, action: actions.setResponses }],
  onError: [{ redux: true, action: actions.setResponsesError }]
}

export const getChartData = {
  eventName: 'getScoresChartData',
  onSuccess: [{ redux: true, action: actions.setChartData }],
  onError: [{ redux: true, action: actions.setChartDataError }]
}

export const submitSurvey = {
  eventName: 'submitSurvey',
  onStart: { redux: true, action: actions.startSubmitSurvey },
  onSuccess: [
    { redux: true, action: actions.setResults }
  ],
  onError: [{ redux: true, action: actions.submitSurveyError }]
}

export const getResults = {
  eventName: 'getResults',
  onStart: { redux: true, action: actions.startGetResults },
  onSuccess: { redux: true, action: actions.setResults },
  onError: [{ redux: true, action: actions.setResultsError }]
}

export const getReportUrl = {
  eventName: 'getReportUrl',
  onSuccess: [{ redux: true, action: actions.setReportUrl }],
  onError: [{ redux: true, action: actions.setReportUrlError }]
}

export const cleanUserResults = {
  eventName: 'cleanUserResults',
  onSuccess: [
    { redux: true, action: actions.setResultsClean },
    { redux: true, action: userActions.setUser }
  ]
}

export const saveUserReport = {
  eventName: 'saveUserReport',
  onSuccess: [{ redux: true, action: actions.setUserReportUploaded }],
  onError: [{ redux: true, action: actions.setUserReportError }]
}

export const getUserReportUrl = {
  eventName: 'getUserReportUrl',
  onSuccess: [{ redux: true, action: actions.setUserReportUrl }],
  onError: [{ redux: true, action: actions.setUserReportError }]
}

export const getSignedUrlForReport = {
  eventName: 'getSignedUrlForReport',
  onSuccess: [{ redux: true, action: actions.setSignedUrlForReport }],
  onError: [{ redux: true, action: actions.setSignedUrlForReportError }]
}

export const devUpdateResults = {
  eventName: 'devUpdateResults',
  onSuccess: [{ redux: true, action: actions.setUpdateResultsSuccess }],
  onError: []
}

export const getSampleReport = {
  eventName: 'getSampleReport',
  onSuccess: [{ redux: true, action: actions.setSampleReportUrl }],
  onError: [{ redux: true, action: actions.getSampleReportError }]
}

export const saveReportDownload = {
  eventName: 'saveReportDownload',
  onSuccess: [{ redux: true, action: actions.setEmailSaved }],
  onError: [{ redux: true, action: actions.saveReportDownloadError }]
}

export const getSeasonUserCSV = {
  eventName: 'getSeasonUserCSV',
  onSuccess: [{ redux: true, action: actions.setUserCSV }],
  onError: [{ redux: true, action: actions.setUserCSVError }]
}
