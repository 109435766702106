import React from 'react'
import Typography from '@material-ui/core/Typography'

const carouselSteps = [
  {
    img: 'img/stepper-4.png',
    title: 'Take your L&D to the next level',
    data: () => (
      <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>Gain a clear picture of L&D maturity</Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>Benchmark against similar organisations</Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>Identify gaps, achievements, and growth</Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>Generate new ideas, prioritise next steps</Typography>
        </div>
      </div>
    )
  },
  {
    img: 'img/stepper-1.png',
    title: 'Handy questions before you start',
    data: () => (
      <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>What are my organisation’s L&D goals, both short- and long-term?</Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>What practices, processes and systems (including digital technologies) currently support my organisation’s L&D?</Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>How am I measuring the impact of my present L&D strategy?</Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-tick.png' alt='' width={25} height={20} />
          <Typography style={{ marginTop: 0, marginLeft: 16 }}>What is my L&D budget?</Typography>
        </div>
      </div>
    )
  },
  {
    img: 'img/stepper-2.png',
    title: 'How it works',
    data: () => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <img src='img/carousel-number-1.png' alt='Number 1 in circle' width={30} height={28} />
          <div style={{ marginLeft: 16 }}>
            <Typography variant='subtitle1' style={{ margin: 0 }}>Five-part review, exploring:</Typography>
            <ul style={{ paddingLeft: 16, marginTop: 0 }}>
              <Typography><li>current goals and vision</li></Typography>
              <Typography><li>strategy in practice and culture</li></Typography>
              <Typography><li>technology, tools and access</li></Typography>
              <Typography><li>challenges</li></Typography>
              <Typography><li>resources and impact.</li></Typography>
            </ul>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <img src='img/carousel-number-2.png' alt='Number 2 in circle' width={30} height={32} />
          <div style={{ marginLeft: 16 }}>
            <Typography variant='subtitle1' style={{ margin: 0 }}>Instant interactive dashboard, highlighting:</Typography>
            <ul style={{ paddingLeft: 16, marginTop: 0 }}>
              <Typography><li>your learning maturity score</li></Typography>
              <Typography><li>trending learning technologies</li></Typography>
              <Typography><li>significant barriers to effective learning.</li></Typography>
            </ul>
          </div>
        </div>
      </div>
    )
  },
  {
    img: 'img/stepper-3.png',
    title: 'How we use your data',
    data: () => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch' }}>
          <Typography style={{ textAlign: 'center', width: '60%' }}>Your data is stored in a GDPR-compliant UK database. It’s used exclusively for research purposes. You’ll have access to the aggregated data used to produce our reports. All information you provide is anonymous and confidential.</Typography>
        </div>
      </div>
    )
  }
]

export default carouselSteps
