import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'
import { datadogRum } from '@datadog/browser-rum'

// Store
export const user = createSlice({
  name: reducerName,
  initialState: {
    userData: {},
    menuLevel: parseInt(localStorage.getItem('menuLevel')) || 0,
    activeMenuSection: localStorage.getItem('activeMenuSection') || 'home',
    featureToggles: {},
    updateUserSuccess: false,
    subscriptionValidated: false,
    subscriptionError: false
  },
  reducers: {
    setUserData: (state, { payload }) => {
      state.userData = payload
    },
    setMenuLevel: (state, { payload }) => {
      state.menuLevel = payload
      localStorage.setItem('menuLevel', Number(payload))
    },
    setActiveMenuSection: (state, { payload }) => {
      state.activeMenuSection = payload
      localStorage.setItem('activeMenuSection', payload)
    },
    createUserDataError: () => {
      datadogRum.addTiming('create_user_data_error')
      datadogRum.addError(new Error('Error: createUserData'))
    },
    getUserDataError: () => {
      datadogRum.addTiming('get_user_data_error')
      datadogRum.addError(new Error('Error: getUserData'))
    },
    sendContactEmailError: () => {
      datadogRum.addTiming('send_email_error')
      datadogRum.addError(new Error('Error: sendContactEmail'))
    },
    updateUserDataOnRegisterError: () => {
      datadogRum.addTiming('update_on_register_error')
      datadogRum.addError(new Error('Error: updateUserDataOnRegister'))
    },
    setFeatureToggles: (state, { payload }) => {
      state.featureToggles = payload
    },
    setUpdatedUserData: (state, { payload }) => {
      state.updateUserSuccess = true
      state.userData = payload
    },
    setSubscriptionValidated: (state, { payload }) => {
      state.subscriptionValidated = payload.active
      if (!payload.active) {
        state.subscriptionError = true
      }
    },
    setSubscriptionError: (state) => {
      state.subscriptionValidated = false
      state.subscriptionError = true
    },
    clearSubscriptionError: (state) => {
      state.subscriptionError = false
    },
    updateUserFirstLoginError: () => {
      datadogRum.addTiming('update_user_first_login_error')
      datadogRum.addError(new Error('Error: updateUserFirstLoginError'))
    },
    updateUserTeamError: () => {
      datadogRum.addTiming('update_user_team_error')
      datadogRum.addError(new Error('Error: updateUserTeamError'))
    },
    setUser: (state, { payload }) => {
      state.userData = payload.user
    },
    setInvoices: (state, { payload }) => {
      state.invoices = payload
      state.invoicesError = false
    },
    setInvoicesError: (state) => {
      state.invoicesError = true
      datadogRum.addTiming('get_invoices_info_error')
      datadogRum.addError(new Error('Error: getInvoicesInfoError'))
    },
    setInvoicePDF: (state, { payload }) => {
      state.invoicePDF = payload
    },
    setInvoicePDFError: (state, { payload }) => {
      state.invoicePDFError = true
      console.log('getInvoicePDFError', payload)
      datadogRum.addTiming('get_invoice_pdf_error')
      datadogRum.addError(new Error('Error: getInvoicePDFError'))
    },
    clearInvoicePDF: (state) => {
      state.invoicePDF = ''
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setUserData,
  setMenuLevel,
  setActiveMenuSection,
  createUserDataError,
  getUserDataError,
  sendContactEmailError,
  updateUserDataOnRegisterError,
  setFeatureToggles,
  setUpdatedUserData,
  setSubscriptionValidated,
  setSubscriptionError,
  clearSubscriptionError,
  updateUserFirstLoginError,
  updateUserTeamError,
  setUser,
  setInvoices,
  setInvoicesError,
  setInvoicePDF,
  setInvoicePDFError,
  clearInvoicePDF
} = user.actions

// Reducer
export default user.reducer
