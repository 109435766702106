import {
  select,
  scaleLinear,
  arc
} from 'd3'

export const drawChart = (data, ref, screenSize = 'lg') => {
  const margin = 5
  let chartWidth
  let arcWidthScaleFactor
  switch (screenSize) {
    case 'xs':
      arcWidthScaleFactor = 0.2
      chartWidth = 350
      break
    case 'md':
      arcWidthScaleFactor = 0.5
      chartWidth = 350
      break
    case 'lg':
      arcWidthScaleFactor = 0.8
      chartWidth = 250
      break

    default:
      break
  }
  const chartHeight = chartWidth + 2 * margin
  const textData = [data[0].value, 'out of 100']
  const translateCorrection = [7, 20]
  const getInnerRadius = index => {
    return arcMinRadius + (numArcs - (index + 1)) * (arcWidth + arcPadding)
  }

  const getOuterRadius = index => {
    return getInnerRadius(index) + arcWidth
  }

  const chart = select(ref.current)
    .html('')
    .append('svg')
    .attr('viewBox', `0 0 ${chartWidth} ${chartHeight}`)
    .attr('align', 'center')
    .attr('class', 'cardSvgContent')
    .attr('preserveAspectRatio', 'xMinYMin meet')
    .attr('aria-label', 'score chart')
    .append('g')
    .attr('transform', `translate(${(chartWidth / 2) - translateCorrection[0]}, ${(chartHeight / 2 + 2 * margin) - translateCorrection[1]})`)

  const tooltip = select('body')
    .append('div')
    .attr('class', 'tooltip')

  const PI = Math.PI
  const arcMinRadius = 75
  const arcPadding = 1
  const numArcs = data.length
  const radius = Math.min(chartWidth, chartHeight) / 2
  const arcWidth = arcWidthScaleFactor * (radius - arcMinRadius - numArcs * arcPadding) / numArcs
  const scale = scaleLinear([0, 100], [0, 2 * PI])

  const arcMap = arc()
    .innerRadius((_, i) => getInnerRadius(i))
    .outerRadius((_, i) => getOuterRadius(i))
    .startAngle(0)
    .endAngle((d) => scale(d))
    .cornerRadius((_, i) => (getOuterRadius(i) - getInnerRadius(i)) / 2)

  const arcs = chart
    .append('g')
    .attr('class', 'small-circular')
    .selectAll('path')
    .data(data)
    .enter()
    .append('path')
    .attr('class', 'arc')
    .style('fill', d => d.color)

  arcs.transition()
    .duration(1000)
    .attrTween('d', (d, i) => {
      return time => {
        const numberTime = parseFloat(time.toFixed(2))
        const numberValue = parseFloat(d.value)
        return arcMap(numberValue * numberTime, i)
      }
    })

  const showTooltip = (e, d) => {
    return tooltip
      .style('left', `${e.pageX + 10}px`)
      .style('top', `${e.pageY}px`)
      .style('display', 'inline-block')
      .text(d.value)
  }

  const hideTooltip = () => {
    return tooltip.style('display', 'none')
  }

  arcs.on('mousemove', showTooltip)
  arcs.on('mouseout', hideTooltip)

  chart
    .selectAll('text')
    .data(textData)
    .join('text')
    .attr('transform', (_, i) => i === 0 ? 'translate(-30, -10)' : 'translate(-23, 15)')
    .call(text => text.append('tspan'))
    .text(d => d)
    .attr('font-family', 'Matter')
    .attr('font-size', (_, i) => i === 0 ? 32 : 12)
    .attr('font-weight', (_, i) => i === 0 ? 'bold' : 'normal')
    .attr('fill', 'black')
}
