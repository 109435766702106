import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { datadogRum } from '@datadog/browser-rum'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './reducers/store'
import { BrowserRouter } from 'react-router-dom'
import './libs/i18n'
import './index.css'
import * as DomainHelpers from './helpers/domain'
import packageJson from '../package.json'

if (DomainHelpers.basedURL().includes('ewbenchmark.dev') ||
  DomainHelpers.basedURL().includes('ewbenchmark.live') ||
  DomainHelpers.basedURL().includes('mindtools.com')) {
  datadogRum.init({
    applicationId: 'bf5edf2a-ee21-4f31-898d-8410808ad8f2',
    clientToken: 'pubdde41a26c7b271dcedee236ae04f4707',
    site: 'datadoghq.eu',
    service: 'benchmark',
    env: `${DomainHelpers.basedURL()}`,
    version: `v${packageJson.version}`,
    trackInteractions: true
  })
  datadogRum.startSessionReplayRecording()
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
