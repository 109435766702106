import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  loginText: {
    marginTop: 24,
    fontSize: '16px'
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '24px',
    padding: 24,
    '&:hover': {
      cursor: 'pointer',
      background: '#EEEFF1'
    }
  },
  startIcon: {
    width: '48px',
    height: '48px',
    border: '2px solid #006FB7',
    borderRadius: '50%',
    padding: '10px',
    margin: 'auto 0'
  },
  optionText: {
    flex: 1,
    padding: '0 24px'
  },
  arrow: {
    margin: 'auto 0'
  }
}))
