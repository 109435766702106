import React from 'react'
import { useStyles } from './style'
import { Paper, Typography } from '@material-ui/core'
import { CircularChart } from '../../components'

const CircularScoreCard = ({ title, score, maxScore, industry, isTeam }) => {
  const classes = useStyles()
  const currentSeason = score[0].values[0].date
  return (
    <Paper
      variant='outlined'
      className={classes.root}
      data-test='card-yourScore'
    >
      <Typography variant='h3'>
        {
          isTeam && title
            ? `${currentSeason} ${title} team aggregated score`
            : isTeam
              ? `Your ${currentSeason} team score`
              : title
                ? `Your ${currentSeason} ${title} score`
                : `Your ${currentSeason} score`
        }
      </Typography>
      <CircularChart
        currentSeason={currentSeason}
        scores={score}
        maxScore={maxScore}
        industry={industry}
        isTeam={isTeam}
      />
    </Paper>
  )
}

export default CircularScoreCard
