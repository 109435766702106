const secondGeneralSection = {
  name: 'Challenges p2',
  elements: [
    {
      type: 'matrix',
      name: 'Barrier',
      title: '2. In the past 12 months, how concerned were you about the following challenges?',
      hideNumber: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Not at all concerned'
        },
        {
          value: 'Column 2',
          text: 'Somewhat concerned'
        },
        {
          value: 'Column 3',
          text: 'Extremely concerned'
        }
      ],
      rows: [
        'Leaders have traditional expectations about L&D which are difficult to change',
        'Overwhelmed and underequipped',
        'Organisational culture does not support social learning',
        'Issues of user safety, identity, and trust',
        'No opportunities to experiment',
        'Lack of investment in future-proofing our L&D strategy'
      ]
    }
  ]
}

export default secondGeneralSection
