const fourthResourcesSection = {
  name: 'Vision, resources and impact - Continued p4',
  elements: [
    {
      type: 'comment',
      name: 'Goal Progress',
      title: '4. In the past 12 months, what goal (if any) have you made the most progress towards, and what was the most important factor in its success?',
      hideNumber: true
    },
    {
      type: 'multipletext',
      name: 'Priorities',
      title: '5. What are your top 3 priority objectives for L&D in the next 12 months?',
      hideNumber: true,
      items: [
        {
          name: 'Priority_1',
          title: 'Priority No. 1'
        },
        {
          name: 'Priority_2',
          title: 'Priority No. 2'
        },
        {
          name: 'Priority_3',
          title: 'Priority No. 3'
        }
      ]
    }
  ]
}

export default fourthResourcesSection
