const fourthGoalsSection = {
  name: 'Vision, resources and impact - Continued p6',
  elements: [
    {
      type: 'customslider',
      name: 'text7',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '10. Our ability to roll out new IT applications has improved by:'
    },
    {
      type: 'customslider',
      name: 'text8',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '11. Our learning strategy has increased organisational revenue by:'
    },
    {
      type: 'customslider',
      name: 'text9',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '12. Our learning strategy has increased organisational productivity by:'
    },
    {
      type: 'customslider',
      name: 'text10',
      visibleIf: '{Role} anyof ["Business leader / senior manager (not L&D/HR)", "Senior manager - HR", "Senior manager - L&D overall", "L&D strategy and management", "Organisation Development"]',
      hideNumber: true,
      title: '13. The quality of our learning programmes has improved by:'
    }
  ]
}

export default fourthGoalsSection
