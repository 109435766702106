import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { surveySlice, userSlice, teamSlice } from '../../reducers'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useNavigate, useLocation } from 'react-router-dom'
import { AppToolbar, CleanResultsDialog } from '../../components'
import { Grid, Typography, Button } from '@material-ui/core'
import { useStyles } from './style'

const ResultsCleanPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const search = new URLSearchParams(useLocation().search)

  const [isDialogOpen, setDialogOpen] = React.useState(false)

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)
  const resultsClean = useSelector(surveySlice.selectors.selectResultsClean)
  const userData = useSelector(userSlice.selectors.selectUserData)

  const [cleanUserResults] = useEvent([surveySlice.eventBus.cleanUserResults])
  const [updateUserTeam] = useEvent([userSlice.eventBus.updateUserTeam])
  const [retrieveUserResponses] = useEvent([surveySlice.eventBus.retrieveUserResponses])
  const [getResults] = useEvent([surveySlice.eventBus.getResults])
  const [getUserData] = useEvent([userSlice.eventBus.getUserData])
  const [getTeam] = useEvent([teamSlice.eventBus.getTeam])

  const teamId = search.get('teamId') || ''

  if (teamId.length === 0) {
    navigate('/not-found')
  }

  const handleHome = () => {
    navigate('/')
  }

  const handleTeams = () => {
    dispatch(userSlice.actions.setMenuLevel(1))
    dispatch(userSlice.actions.setActiveMenuSection('teams'))
    navigate('/teams')
  }

  const openCleanDialog = () => {
    setDialogOpen(true)
  }

  const closeCleanDialog = () => {
    setDialogOpen(false)
  }

  const cleanResults = () => {
    cleanUserResults.trigger({ teamId })
  }

  useEventsOnViewLoad(() => {
    if (teamId.length && teamId !== 'clean') {
      updateUserTeam.trigger({ teamId })
    }
  }, [updateUserTeam, teamId])

  useEffect(() => {
    if (resultsClean) {
      getResults.trigger()
      retrieveUserResponses.trigger({ step: 'none' })
      getUserData.trigger()
      getTeam.trigger()
      if (!userData.premium === 'team') {
        navigate('/')
      } else {
        dispatch(userSlice.actions.setMenuLevel(1))
        dispatch(userSlice.actions.setActiveMenuSection('teams'))
        navigate('/teams')
      }
    }
  }, [userData, resultsClean, navigate, getResults, retrieveUserResponses, getUserData, getTeam, dispatch])

  return (
    <div className={classes.root}>
      <AppToolbar
        isMobileScreen={isMDScreen || isXSScreen}
        hideHamburger
      />

      <Grid container>

        <div className={isLGScreen ? classes.main : classes.mainMD} id='main'>
          <Grid container spacing={6}>
            <Grid item lg={6} md={8}>
              <Typography variant='h1' data-test='resultsCleanHeader'>
                We’ve noticed you have already completed the review
              </Typography>
              <Typography>
                As part of the LPB data set, you can only have one set of results per season. Therefore, you can either:
              </Typography>
              <Typography>
                <ul>
                  <li>
                    Overwrite your existing results and complete the review again (NOTE: this cannot be undone)
                  </li>
                </ul>
                <span className={classes.paddedText}>
                  OR
                </span>
                <ul>
                  <li>
                    Allow your existing results to be added into your team aggregate
                  </li>
                </ul>
              </Typography>
              <div className={classes.buttons}>
                <Button
                  onClick={openCleanDialog}
                  data-test='btn-overwriteResults'
                  className={`${classes.button} Clean_Results_Overwrite`}
                  variant='contained'
                  color='secondary'
                >
                  Overwrite my results
                </Button>
                <Button
                  onClick={userData.premium === 'team' ? handleTeams : handleHome}
                  data-test='btn-existingResults'
                  className={`${classes.button} Clean_Results_Existing`}
                  variant='outlined'
                  color='secondary'
                >
                  Use my existing results
                </Button>
              </div>
            </Grid>
            <Grid style={{ display: !isLGScreen ? 'none' : '' }} lg={1} />
            <Grid item lg={5} md={4}>
              <div className={classes.rightColumn}>
                <img src='/img/results-clean.png' alt='' className={classes.image} />
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <CleanResultsDialog
        open={isDialogOpen}
        handleClose={closeCleanDialog}
        handleClean={cleanResults}
        cleaning={cleanUserResults.isWorking}
      />
    </div>
  )
}

export default ResultsCleanPage
