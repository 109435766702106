import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  description: {
    marginBottom: 40
  },
  helper: {
    fontSize: 12,
    marginTop: 8
  },
  upgradeBox: {
    marginTop: 60,
    marginBottom: 21,
    background: '#EEEFF1',
    padding: '26px 24px'
  },
  paper: {
    padding: 24,
    marginTop: 40
  },
  paperRow: {
    display: 'flex',
    padding: '16px 0'
  },
  emphasis: {
    fontSize: 16,
    fontWeight: 700
  },
  hr: {
    borderColor: 'rgba(37, 55, 70, 0.12)'
  },
  li1: {
    marginBottom: 8
  },
  li2: {
    minWidth: '36px'
  },
  faqBox: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 64
  },
  loadText: {
    marginBottom: 40,
    textAlign: 'center'
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '260px',
    background: '#EEEFF1'
  },
  progress: {
    width: '40%',
    margin: '40px 0'
  },
  loadFooter: {
    marginTop: '290px'
  }
}))
