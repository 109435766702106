import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(_theme => ({
  rootLoading: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadText: {
    textAlign: 'center'
  },
  loadFooter: {
    position: 'absolute',
    bottom: '16px'
  }
}))
