// This dictionary contains 2 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { surveyLocalization } from "survey-core";

export var latvianSurveyStrings = {
  pagePrevText: "Atpakaļ",
  pageNextText: "Tālāk",
  completeText: "Pabeigt",
  previewText: "Priekšskatījums",
  editText: "Rediģēt",
  startSurveyText: "Sākt",
  otherItemText: "Cits (lūdzu, aprakstiet!)",
  noneItemText: "Nav",
  selectAllItemText: "Izvēlēties visus",
  progressText: "{0}. lapa no {1}",
  indexText: "{0} no {1}",
  panelDynamicProgressText: "Ierakstīt {0} no {1}",
  questionsProgressText: "Atbildēts uz {0} / {1} jautājumiem",
  emptySurvey: "Nav neviena jautājuma.",
  completingSurvey: "Pateicamies Jums par anketas aizpildīšanu!",
  completingSurveyBefore: "Mūsu ieraksti liecina, ka Jūs jau esat aizpildījis šo aptauju.",
  loadingSurvey: "Ielāde no servera...",
  placeholder: "Izvēlēties...",
  ratingOptionsCaption: "Nospiediet šeit, lai novērtētu...",
  value: "value",
  requiredError: "Lūdzu, atbildiet uz jautājumu!",
  requiredErrorInPanel: "Lūdzu, atbildiet uz vismaz vienu jautājumu.",
  requiredInAllRowsError: "Lūdzu, atbildiet uz jautājumiem visās rindās.",
  numericError: "Atbildei ir jābūt skaitlim.",
  minError: "Vērtība nedrīkst būt mazāka par {0}",
  maxError: "Vērtība nedrīkst būt lielāka par {0}",
  textMinLength: "Lūdzu, ievadiet vismaz {0} simbolus.",
  textMaxLength: "Lūdzu, ievadiet mazāk nekā {0} rakstzīmes.",
  textMinMaxLength: "Lūdzu, ievadiet vairāk nekā {0} rakstzīmes un mazāk nekā {1} rakstzīmes.",
  minRowCountError: "Lūdzu, aizpildiet vismaz {0} rindas.",
  minSelectError: "Lūdzu, izvēlieties vismaz {0} variantu.",
  maxSelectError: "Lūdzu, izvēlieties ne vairak par {0} variantiem.",
  numericMinMax: "'{0}' jābūt vienādam vai lielākam nekā {1}, un vienādam vai mazākam, nekā {2}",
  numericMin: "'{0}' jābūt vienādam vai lielākam {1}",
  numericMax: "'{0}' jābūt vienādam vai lielākam {1}",
  invalidEmail: "Lūdzu, ievadiet pareizu e-pasta adresi!",
  invalidExpression: "Izteicienam: {0} jāatgriež “true”.",
  urlRequestError: "Pieprasījumā tika atgriezta kļūda “{0}”. {1}",
  urlGetChoicesError: "Pieprasījums atgrieza tukšus datus vai rekvizīts “path” ir nepareizs",
  exceedMaxSize: "Faila lielums nedrīkst pārsniegt {0}.",
  otherRequiredError: "Lūdzu, ievadiet datus laukā 'Cits'",
  uploadingFile: "Jūsu fails tiek augšupielādēts. Lūdzu, uzgaidiet dažas sekundes un mēģiniet vēlreiz.",
  loadingFile: "Notiek ielāde ...",
  chooseFile: "Izvēlieties failus ...",
  noFileChosen: "Nav izvēlēts neviens fails",
  fileDragAreaPlaceholder: "Lai pievienotu, ievelciet failu šeit vai arī klikšķiniet uz zemāk redzamās pogas",
  confirmDelete: "Vai vēlaties izdzēst ierakstu?",
  keyDuplicationError: "Šai vērtībai jābūt unikālai.",
  addColumn: "Pievienot kolonnu",
  addRow: "Pievienot rindu",
  removeRow: "Noņemt",
  emptyRowsText: "Nav rindu.",
  addPanel: "Pievieno jaunu",
  removePanel: "Noņemt",
  choices_Item: "vienums",
  matrix_column: "Sleja",
  matrix_row: "Rinda",
  multipletext_itemname: "teksts",
  savingData: "Rezultāti tiek saglabāti serverī ...",
  savingDataError: "Radās kļūda, un mēs nevarējām saglabāt rezultātus.",
  savingDataSuccess: "Rezultāti tika veiksmīgi saglabāti!",
  saveAgainButton: "Mēģiniet vēlreiz",
  timerMin: "min",
  timerSec: "sek",
  timerSpentAll: "Šajā lapā esat iztērējis {0} un kopā {1}.",
  timerSpentPage: "Šajā lapā esat iztērējis {0}.",
  timerSpentSurvey: "Kopā esat iztērējis {0}.",
  timerLimitAll: "Šajā lapā esat iztērējis {0} no {1} un kopā {2} no {3}.",
  timerLimitPage: "Šajā lapā esat iztērējis {0} no {1}.",
  timerLimitSurvey: "Kopā esat iztērējis {0} no {1}.",
  clearCaption: "Iztīrīt",
  signaturePlaceHolder: "Parakstieties šeit",
  chooseFileCaption: "Izvēlēties failu",
  removeFileCaption: "Noņemiet šo failu",
  booleanCheckedLabel: "Jā",
  booleanUncheckedLabel: "Nē",
  confirmRemoveFile: "Vai tiešām vēlaties noņemt šo failu: {0}?",
  confirmRemoveAllFiles: "Vai tiešām vēlaties noņemt visus failus?",
  questionTitlePatternText: "Jautājuma nosaukums",
  modalCancelButtonText: "Atcelt",
  modalApplyButtonText: "Pielietot",
  filterStringPlaceholder: "Ierakstiet, lai meklētu...",
  // emptyMessage: "No data to display",
  noEntriesText: "Vēl nav neviena ieraksta.\nNoklikšķiniet uz zemāk esošās pogas, lai pievienotu jaunu ierakstu.",
  // more: "More"
};

surveyLocalization.locales["lv"] = latvianSurveyStrings;
surveyLocalization.localeNames["lv"] = "latviešu";
