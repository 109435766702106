const challengesFinalSection = {
  name: 'Challenges final',
  elements: [
    {
      type: 'comment',
      name: 'question28',
      title: '6. Are there any other barriers that you are facing that are not covered above?',
      hideNumber: true
    },
    {
      type: 'html',
      name: 'Challenge_reflect_intro',
      hideNumber: true,
      html: '<b>7. At the beginning, you said the primary challenge you are trying to solve with your L&D strategy in the next 12 months is:</b><br><br>"{Main_challenge}".'
    },
    {
      type: 'radiogroup',
      hideNumber: true,
      name: 'Challenge_reflect',
      title: 'Having had time to reflect, is this still your primary challenge?',
      choices: [
        'Yes',
        'No'
      ]
    },
    {
      type: 'comment',
      name: 'Challenge_reflect_reason',
      title: '7a. Why not?',
      hideNumber: true,
      visibleIf: '{Challenge_reflect} = "No"'
    }
  ]
}

export default challengesFinalSection
