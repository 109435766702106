import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { TimeEstimate } from '../index'
import { useStyles } from './style'

const SurveySectionCover = ({ section, saveExitBtnId, handleSaveAndExit, startBtnId, startSection, isLGScreen, hideNav, ovdHideNav, handleContentHover, isXLScreen }) => {
  const classes = useStyles()

  return (
    <div
      className={isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? `${classes.main} ${isXLScreen && classes.mainXL}` : `${classes.mainMD} ${isXLScreen && classes.mainXLCollapsed}`}
      onMouseOver={handleContentHover}
      id='survey-cover-main'
    >
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Typography variant='h1' className={classes.sectionTitle}>{section.title}</Typography>
          <TimeEstimate time={section.time} />
          <Typography className={classes.introText}>{section.description}</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <div className={classes.img}>
            <img src={section.img} alt={`${section.title} cover`} />
          </div>
        </Grid>
        {section.tips && (
          <Grid item xs={10} md={8}>
            <div className={classes.tipsBox}>
              <Typography variant='subtitle1' color='inherit'>Handy questions before you start</Typography>
              <Typography color='inherit'>
                <ul className={classes.bulletList}>
                  {section.tips.map((tip, idx) => (
                    <li key={idx}>{tip}</li>
                  ))}
                </ul>
              </Typography>
            </div>
          </Grid>
        )}
        {section.tips && <Grid item xs={1} md={3} />}
        <Grid item xs={12} className={classes.buttonsBox}>
          <Button id={saveExitBtnId} variant='outlined' className={classes.saveExitButton} onClick={handleSaveAndExit}>Save and exit</Button>
          <Button id={startBtnId} variant='contained' className={classes.startButton} color='secondary' onClick={startSection}>Start</Button>
        </Grid>
        <Grid item xs={4} />
        {section.quote && (
          <Grid item xs={1} className={classes.quoteTextBox}>
            <img src='/img/survey-quote.png' width={30} height={20} alt='quote' />
          </Grid>
        )}
        {section.quote && (
          <Grid item xs={7}>
            <Typography className={classes.quoteText} variant='body1' color='secondary'>{section.quote.text}</Typography>
            <Typography className={classes.quoteAuthor} variant='body2'>{section.quote.author}</Typography>
            <Typography className={classes.quoteRole} variant='body2'>{section.quote.role}</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default SurveySectionCover
