import { Grid, Paper, Typography, Button } from '@material-ui/core'
import { useStyles } from './style'
import { userSlice } from '../../../reducers'
import { useDispatch } from 'react-redux'
import React from 'react'
import { SmallCircularChart } from '../..'
import { useNavigate } from 'react-router-dom'

const CurrentScoreCard = ({ latestResults, stagesCopy, userStage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleClick = () => {
    dispatch(userSlice.actions.setActiveMenuSection('you'))
    dispatch(userSlice.actions.setMenuLevel(1))
    navigate('/dashboard')
  }
  const classes = useStyles()
  return (
    <Paper className={classes.homePaper}>
      <Typography data-test='homePageCard2' variant='h2' className={classes.cardTitle}>
        Your current score
      </Typography>
      <Grid container className={classes.homePaperContent}>
        <Grid item xs={8}>
          <Typography variant='subtitle1'>
            {stagesCopy[userStage].head}
          </Typography>
          <ul>
            {stagesCopy[userStage].points.map((point, idx) => (
              <li key={idx}>
                <Typography>
                  {point}
                </Typography>
              </li>
            )
            )}
          </ul>
        </Grid>
        <Grid item xs={4} className={classes.chartContainer}>
          <SmallCircularChart
            score={latestResults ? latestResults.scores.score.toFixed(2) : '0.0'}
          />
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <Button
          className={classes.footerButton}
          data-test='lnk-secondCard'
          variant='text'
          onClick={handleClick}
        >
          View your results
        </Button>
      </div>
    </Paper>
  )
}

export default CurrentScoreCard
