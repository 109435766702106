import React from 'react'
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link
} from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Auth from '@aws-amplify/auth'
import {
  RegisterHeader,
  ForgotPasswordForm,
  RegisterFooter
} from '../../components'
import { datadogRum } from '@datadog/browser-rum'
import { loginSlice } from '../../reducers'
import { ReactComponent as ForgotPasswordSVG } from './forgotPassword.svg'
import { useStyles } from './style'

const ForgotPassword = props => {
  const [isDialogOpen, setDialogOpen] = React.useState(false)
  const forgotPasswordUsername = useSelector(
    loginSlice.selectors.selectForgotPasswordUsername
  )

  const classes = useStyles()
  const navigate = useNavigate()
  const dispatcher = useDispatch()

  const handleDialogClose = () => {
    setDialogOpen(false)
    navigate('/login')
  }

  const handleResendCode = async () => {
    try {
      await Auth.forgotPassword(forgotPasswordUsername)
    } catch (e) {
      datadogRum.addError(e)
      console.log(e)
    }
  }

  const handleDialogContinue = () => {
    setDialogOpen(false)
    navigate('/change-password')
  }

  const handleDialogOpen = userEmail => {
    dispatcher(loginSlice.actions.setForgotPasswordUsername(userEmail))
    setDialogOpen(true)
  }

  return (
    <>
      <Grid className={classes.root} container spacing={0}>
        <Grid container item lg={7} xs={12} spacing={0}>
          <Grid item xs={12}>
            <RegisterHeader />
          </Grid>
          <Grid item xs={12}>
            <ForgotPasswordForm dialogOpen={handleDialogOpen} />
          </Grid>
        </Grid>
        <Grid container className={classes.imageGrid} item lg={5} xs={12} spacing={0}>
          <ForgotPasswordSVG alt='' />
        </Grid>
        <Grid container item lg={7} xs={12} spacing={0}>
          <Grid item xs={12}>
            <RegisterFooter />
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={isDialogOpen}>
        <DialogContent>
          <DialogContentText multiline data-test='forgotPasswordDialog'>
            If this email exists, we will send you a code to reset your password. If you don’t receive an email, you will need to create an account. Remember to check your spam and junk folders.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link
            component='button'
            onClick={handleResendCode}
            style={{ flex: 1, textAlign: 'start', margin: 'auto 0', color: '#0071BD' }}
            data-test='lnk-resendCode-forgotPageDialog'
          >
            Resend code
          </Link>
          <Button
            className={classes.secondaryButton}
            data-test='btn-cancel-forgotDialog'
            onClick={handleDialogClose}
            variant='outlined'
          >
            Cancel
          </Button>
          <Button
            className={classes.primaryButton}
            onClick={handleDialogContinue}
            data-test='btn-continue-forgotDialog'
            variant='contained'
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ForgotPassword
