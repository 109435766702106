const thirdResourcesSection = {
  name: 'Vision, resources and impact - Continued p3',
  elements: [
    {
      type: 'matrix',
      name: 'Benefit_1',
      hideNumber: true,
      customMatrixTop: true,
      title: '3. Which of the following benefits has your organisation achieved in the last 12 months?',
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Benefit_1',
          text: 'Improved the management and administration of learning at work'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Benefit_2',
      title: 'What are the benefits your organisation is achieving relating to learning and development efficiency?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Benefit_2',
          text: 'Enhanced the way we gather and analyse data on learning impact'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Benefit_3',
      title: 'What are the benefits your organisation is achieving relating to learning and development efficiency?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Benefit_3',
          text: 'Improved the quality of learning delivered (formal and informal)'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Online_learn',
      title: 'What are the benefits your organisation is achieving relating to learning and development efficiency?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Online_learn',
          text: 'Improved online learning'
        }
      ]
    },
    {
      type: 'matrix',
      name: 'Benefit_9',
      title: 'What are the benefits your organisation is achieving relating to learning and development efficiency?',
      titleLocation: 'hidden',
      hideColumnHeaders: true,
      columns: [
        {
          value: 'Column 1',
          text: 'Achieved'
        },
        {
          value: 'Column 2',
          text: 'Not achieved but in progress'
        },
        {
          value: 'Column 3',
          text: 'Not achieved at all'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        {
          value: 'Benefit_9',
          text: 'Improved face-to-face learning'
        }
      ]
    }
  ]
}

export default thirdResourcesSection
