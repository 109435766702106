import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './style'

const LPHeader = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleSignUp = () => {
    navigate('/register')
  }

  const handleLogin = () => {
    navigate('/login')
  }

  return (
    <Grid item xs={12}>
      <div className={classes.topBar}>
        <div className={classes.logoContainer} onClick={handleLogin}>
          <img
            tabIndex={0}
            src='img/mindtools-lpb-logo_55.png'
            alt='MindTools for Business Home'
            className={classes.image}
            data-test='btn-headerLogo'
          />
        </div>
        <div className={classes.topBtnsContainer}>
          <Button
            disableElevation
            variant='contained'
            color='secondary'
            onClick={handleSignUp}
          >
            Sign up
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.topBtn}
            onClick={handleLogin}
          >
            Log in
          </Button>
        </div>
      </div>
    </Grid>
  )
}

export default LPHeader
