const parseTrendsResponses = (surveyResponses) => {
  const skillsResponses = surveyResponses && surveyResponses.LNDSkills
    ? {
        ...surveyResponses.LNDSkills
      }
    : surveyResponses
      ? {
          'Classroom / F2F learning / training delivery': surveyResponses.LNDSkills_1 || 0,
          'Learning management / administration': surveyResponses.LNDSkills_15 || 0,
          'Facilitation and assessment': surveyResponses.LNDSkills_7 || 0,
          'Programme evaluation': surveyResponses.LNDSkills_8 || 0,
          'Coaching and mentoring': surveyResponses.LNDSkills_14 || 0
        }
      : {}

  const techResponses = surveyResponses && surveyResponses['Tech Collaboration']
    ? {
        ...surveyResponses['Tech Collaboration'],
        ...surveyResponses['Tech Content'],
        ...surveyResponses['Tech Mix']
      }
    : surveyResponses
      ? {
          'Virtual meetings (e.g. Skype, Google Hangouts, Zoom)': surveyResponses.TechCollab_8 || 0,
          'Job aids (e.g. pdf checklists, infographics)': surveyResponses.TechContent_4 || 0,
          'Webinars (e.g. GoToWebinar, Webex, Livestream, Adobe Connect)': surveyResponses.TechCollab_1 || 0,
          'Communication tools (chat, IM, SMS, newsletter, forum)': surveyResponses.TechCollab_2 || 0,
          'Mobile devices (e.g. smartphones, tablets)': surveyResponses.TechMix_3 || 0
        }
      : {}

  const barrierResponses = surveyResponses && surveyResponses.Barrier
    ? {
        ...surveyResponses.Barrier,
        ...surveyResponses['Barrier Content'],
        ...surveyResponses['Barrier Tech']
      }
    : surveyResponses
      ? {
          'Reluctance by managers to make time for learning': surveyResponses.Barrier_8 || 0,
          'L&D are overwhelmed and under-equipped': surveyResponses.Barrier_13 || 0,
          'Leaders have traditional expectations of L&D that are difficult to challenge': surveyResponses.Barrier_12 || 0,
          'Lack of investment to future-proof L&D approaches': surveyResponses.ContentBarrier_2 || 0,
          'Cost of set-up, development and maintenance of tech solutions': surveyResponses.ContentBarrier_1 || 0
        }
      : {}

  return {
    skillsResponses,
    techResponses,
    barrierResponses
  }
}

export {
  parseTrendsResponses
}
