import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { surveySlice, userSlice, teamSlice } from '../../reducers'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AppToolbar,
  NavigationDrawer,
  ReportCard,
  ProgressCard,
  TeamsCard,
  CurrentScoreCard,
  HomePageHeader,
  HomePageLoading,
  HomePageLoadResults,
  HomePageSpinner,
  TeamsCardNotify
} from '../../components'
import { Grid, IconButton } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { useSession } from '@emerald-works/react-auth'
import { useStyles } from './style'
import { getStageByScore, stagesCopy } from '../../helpers/utils'

const retrieveDescription = type => {
  switch (type) {
    case 'new':
      return 'As this is your first time here, why not start by completing your review? Or if you like, check out the benefits of upgrading to a team account.'
    case 'new-season':
      return 'It`s that time of the year again. Set aside a little time to review the last 12 months of your L&D progress. Or if you feel like, feel free to check out the benefits of upgrading to a team account. '
    case 'partial':
      return 'You still have a few questions left to complete before you can access your personalised results. You can go ahead and pick up where you left off. Or if you feel like, feel free to check out the benefits of upgrading to a team account.'
    default:
      return 'Welcome back. It`s good to see you again. Seeing as you have completed your review for this year, why not check out the benefits of upgrading to a team account.'
  }
}

const retrieveDescriptionTeams = (type, teamAdmin, teamResults) => {
  if (teamAdmin) {
    // admin is  the same for results/no results
    switch (type) {
      case 'new':
        return 'As you\'re new here, we recommend you complete your own review first. Alternatively, add your team so you can all complete your reviews at the same time!'
      case 'new-season':
        return 'It’s that time of the year again! Set aside a little time to review the last 12 months of your L&D progress. Generate fresh insights, and discover actionable solutions for enhancing your entire learning strategy.'
      case 'partial':
        return 'You still have a few questions left to complete before you can access your personalised results. You can go ahead and pick up where you left off.'
      default:
        return 'Welcome back! It\'s good to see you again.'
    }
  } else if (!teamResults) {
    // no teamResults + team member
    switch (type) {
      case 'new':
        return 'As this is your first time here, why not start by completing your review? Or if you like, feel free to check out our latest research report.'
      case 'new-season':
        return 'It’s that time of the year again! Set aside a little time to review the last 12 months of your L&D progress. Generate fresh insights, and discover actionable solutions for you to discuss with your team.'
      case 'partial':
        return 'You still have a few questions left to complete before you can access your personalised results. You can go ahead and pick up where you left off.'
      default:
        return 'Welcome back! It’s good to see you again. We\'re just waiting for your team admin to close the review, then you can check out how aligned you all are.'
    }
  } else {
    // teamResults + team member
    switch (type) {
      case 'new':
        return 'Your admin has already closed your team review, but you can still complete the LPB if you\'d like to get your own personalised score. Alternatively, view your team members’ aggregated results.'
      case 'new-season':
        return 'Your admin has already closed your team review, but you can still complete the LPB to get your own personalised score. Alternatively, view your team members’ aggregated results.'
      case 'partial':
        return 'Your admin has already closed your team review, but you can still complete the LPB if you\'d like to get your own personalised score. Alternatively, view your team members’ aggregated results.'
      default:
        return 'Welcome back! It\'s good to see you again'
    }
  }
}

const HomePage = () => {
  const classes = useStyles()
  const session = useSession()
  const search = new URLSearchParams(useLocation().search)
  const history = useNavigate()
  const dispatcher = useDispatch()

  const [updateUserTeam] = useEvent([userSlice.eventBus.updateUserTeam])
  const [retrieveUserResponses] = useEvent([surveySlice.eventBus.retrieveUserResponses])
  const [getResults] = useEvent([surveySlice.eventBus.getResults])
  const [getFeatureToggles] = useEvent([userSlice.eventBus.getFeatureToggles])
  const [getUserData] = useEvent([userSlice.eventBus.getUserData])
  const [getReportUrl] = useEvent([surveySlice.eventBus.getReportUrl])
  const [getTeam] = useEvent([teamSlice.eventBus.getTeam])

  const userData = useSelector(userSlice.selectors.selectUserData)
  const teamData = useSelector(teamSlice.selectors.selectTeam)
  const latestResults = useSelector(surveySlice.selectors.selectLatestResults)
  const resultsLoading = useSelector(surveySlice.selectors.selectResultsLoading)
  const reportUrl = useSelector(surveySlice.selectors.selectReportUrl)

  const { user } = session
  const userStage = latestResults ? getStageByScore(latestResults.scores.score) : 1

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)
  const isXLScreen = isLGScreen

  const [showNavBar, setShowNavBar] = React.useState(isLGScreen)
  const [showFloatBtn, setShowFloatBtn] = React.useState(false)
  const [hideNav, setHideNav] = React.useState(isXLScreen)
  const [ovdHideNav, setOvdHideNav] = React.useState(isXLScreen)

  const teamId = search.get('teamId') || ''
  const userFirstLogin = search.get('isFirstLogin') || false

  const openNavBar = () => {
    setShowNavBar(true)
    dispatcher(userSlice.actions.setActiveMenuSection('home'))
    dispatcher(userSlice.actions.setMenuLevel(0))
  }
  const closeNavBar = () => {
    setShowNavBar(false)
  }

  const handleNavDrawerHover = () => {
    if (!hideNav) {
      setShowFloatBtn(true)
    } else {
      setOvdHideNav(true)
    }
  }

  const handleContentHover = () => {
    setShowFloatBtn(false)
    setOvdHideNav(false)
  }

  const toggleHideNav = () => {
    setHideNav(!hideNav)
    setShowFloatBtn(true)
  }
  const hasPreviousResults = latestResults && latestResults.season === 2022
  const isTeams = userData?.teams?.length || userData?.premium === 'team'
  const teamsInfo = isTeams
    ? {
        teamAdmin: !!userData?.premium && userData.premium === 'team',
        teamAdminPending: !!userData?.premium && userData.premium === 'pending',
        teamResults: teamData && teamData.status === 'closed',
        hasTeam: !!userData?.teams?.length,
        teamName: teamData && teamData.name
      }
    : false

  const userName = user?.attributes.name.split(' ')[0]
  const userReviewNotStarted = userData?.reviewNotStarted
  const userReviewCompleted = userData?.reviewCompleted
  const userReviewNewSeason = userData?.reviewNewSeason
  const pageState = userReviewNotStarted && !userReviewNewSeason
    ? 'new'
    : userReviewNewSeason && userReviewNotStarted && !userReviewCompleted
      ? 'new-season'
      : userReviewCompleted
        ? 'recurrent'
        : 'partial'
  const pageDescription = isTeams ? retrieveDescriptionTeams(pageState, teamsInfo.teamAdmin, teamsInfo.teamResults) : retrieveDescription(pageState)

  useEffect(() => {
    if (isLGScreen) openNavBar()
    if (isMDScreen) setShowNavBar(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLGScreen, isMDScreen])

  useEventsOnViewLoad(() => {
    getResults.trigger()
    retrieveUserResponses.trigger({ step: 'none' })
    getUserData.trigger()
    getFeatureToggles.trigger()
    getReportUrl.trigger()
    getTeam.trigger()
    if (teamId.length) {
      updateUserTeam.trigger({ teamId })
      search.delete('teamId')
      history({ search: search.toString() })
    }
  }, [getFeatureToggles, getResults, retrieveUserResponses, getUserData, getReportUrl, getTeam, updateUserTeam])

  const HandleUserHomePageCard = () => {
    if (teamsInfo) {
      if (!teamsInfo.teamAdmin && userFirstLogin) {
        return <TeamsCardNotify teamsInfo={teamsInfo} />
      }
      return <TeamsCard teamsInfo={teamsInfo} />
    }
    return <ReportCard reportUrl={reportUrl} />
  }

  // eslint-disable-next-line react/jsx-closing-tag-location
  return resultsLoading
    ? (
      <HomePageSpinner />
      )
    : (
      <div className={classes.root}>
        <AppToolbar
          isMobileScreen={isMDScreen || isXSScreen}
          navBarOpen={showNavBar}
          openNavBar={openNavBar}
          closeNavBar={closeNavBar}
        />
        <Grid container>
          <Grid container item lg={2}>

            <NavigationDrawer
              isLoading={resultsLoading}
              isLGScreen={isLGScreen}
              showNavBar={showNavBar}
              closeNavBar={closeNavBar}
              hideNav={hideNav}
              ovdHideNav={ovdHideNav}
              onMouseOver={handleNavDrawerHover}
            />
            {((isLGScreen && showFloatBtn) || (isLGScreen && hideNav)) && !isXLScreen && (
              <IconButton
                aria-label='toggle navigation menu'
                className={isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? classes.collapseBtn : classes.collapseBtnHide}
                onClick={toggleHideNav}
              >
                {
                    !hideNav
                      ? (
                        <KeyboardArrowLeft
                          className={classes.collapseIcon}
                        />
                        )
                      : (
                        <KeyboardArrowRight
                          className={classes.collapseIcon}
                        />
                        )
                  }
              </IconButton>
            )}
          </Grid>
          <Grid container>
            <div
              className={isXLScreen ? `${classes.mainMD} ${classes.mainXL}` : isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? classes.main : classes.mainMD}
              onMouseOver={handleContentHover}
              id='main'
            >
              <Grid container spacing={3} className={classes.mainGrid}>
                {
                    resultsLoading
                      ? (
                        <HomePageLoading />
                        )
                      : (
                        <HomePageHeader
                          userName={userName}
                          pageDescription={pageDescription}
                          pageState={pageState}
                          hasPreviousResults={hasPreviousResults}
                          teamsInfo={teamsInfo}
                          reportUrl={reportUrl}
                        />
                        )
                  }
                <Grid item md={1} lg={1} />
                <Grid item xs={12} md={4} lg={4}>
                  <div className={classes.imgContainer}>
                    <img
                      className={classes.img}
                      src='/img/home.png'
                      alt=''
                    />
                  </div>
                </Grid>
                <Grid item style={{ display: !isLGScreen ? 'none' : '' }} lg={1} />
                <Grid item md={12} lg={6} xl={6}>
                  {
                      resultsLoading
                        ? (
                          <HomePageLoadResults />
                          )
                        : pageState !== 'recurrent' && !latestResults
                          ? (
                            <ProgressCard />
                            )
                          : (
                            <CurrentScoreCard
                              stagesCopy={stagesCopy}
                              userStage={userStage}
                              latestResults={latestResults}
                            />
                            )
                    }
                </Grid>
                <Grid item md={12} lg={6} xl={6}>
                  <HandleUserHomePageCard />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      )
}

export default HomePage
