import React from 'react'
import { useStyles } from './style'
import { Link } from 'react-router-dom'

const LPBLink = ({ to, children, ...props }) => {
  const classes = useStyles()

  return (
    <Link to={to} className={classes.root} {...props}>
      {children}
    </Link>
  )
}

export default LPBLink
