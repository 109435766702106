import React from 'react'
import {
  Dialog,
  Typography,
  Button
} from '@material-ui/core'
import { useStyles } from './style'

const FinalDialog = ({ open, handleClose, handleFinish }) => {
  const classes = useStyles()

  const dialogClose = () => {
    handleClose()
  }

  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <Typography variant='h3'>You haven’t answered every question</Typography>
        <Typography className={classes.text}>You can return to the review and enter the missing information.</Typography>
        <Typography className={classes.text}>If you’ve given all the answers you’re able to, you can continue and submit your results. But note, you won’t be able to change your answers after this point.</Typography>
        <div className={classes.buttons}>
          <Button variant='outlined' color='secondary' onClick={dialogClose}>Return to review</Button>
          <Button className={classes.rightButton} variant='contained' color='secondary' onClick={handleFinish}>Continue to results</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default FinalDialog
