import React from 'react'
import { Page } from '@react-pdf/renderer'
import { KeyComponents } from '../../components'

const EnvironmentPage = ({ scores, behaviours, season }) => {
  const questions = ['Do you understand the determinants—both internal—and externalaffecting the success of your learning strategy?', 'Are you taking active measures to address these factors?']
  const title = 'Environment, talent and culture'
  const introText = 'Another 3 key indicators of a high-impact learning culture are talent (e.g. L&D infused in all vertical and horizontal moves, including onboarding), work culture (e.g. empowering managers to support employees), and the organisational environment (e.g. competition, financial situation). Understanding them is a prerequisite for maintaining healthy and productive partnerships with multiple stakeholders.'
  const userScore = scores[0].values[0].score

  return (
    <Page size='A4' orientation='landscape' wrap={false}>
      <KeyComponents score={userScore} pageNumber={7} title={title} introText={introText} component='influence' keyQuestions={questions} keyBehaviours={behaviours} season={season} />
    </Page>
  )
}

export default EnvironmentPage
