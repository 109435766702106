const secondEnableSection = {
  name: 'Strategy in practice and culture - Continued p11',
  elements: [
    {
      type: 'customrating',
      name: 'EnableDesign_1',
      title: '41. We apply storytelling techniques in our instructional design',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableDesign_3',
      title: '42. We use video, audio, images and animation in our learning programmes',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableDesign_4',
      title: '43. We use highly interactive methods (e.g., games and simulations) in our learning solutions',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableDesign_5',
      title: '44. We blend our use of several different learning approaches',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableDesign_6',
      title: '45. We include activities that help employees practise for desired outcomes',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableDesign_7',
      title: '46. Where appropriate, we provide microlearning (i.e., under 5 minutes)',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableDesign_8',
      title: '47. We develop challenging stretch tasks to embed learning in behaviour',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default secondEnableSection
