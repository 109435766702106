import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { TeamGettingStartedCard } from '../../components'
import { steps } from '../../pages/team-create/mocks'
import { useStyles } from './style'

const HowItWorksComponent = ({ handleGetStarted }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant='h1' className={classes.title} data-test='howItWorksHeader'>
          How it works
        </Typography>
        <Grid container spacing={6}>
          {steps.length > 0 && steps.map(step => (
            <Grid key={step.step} item lg={3} md={6}>
              <TeamGettingStartedCard
                step={step.step}
                header={step.header}
                description={step.description}
                handleGetStarted={step.hasButton ? handleGetStarted : null}
              />
            </Grid>
          ))}
          <Grid item lg={9} md={4} />
          <Grid item lg={3} md={4}>
            <img src='img/how-it-works.png' className={classes.img} alt='How it works' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HowItWorksComponent
