import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

// Store
export const dashboard = createSlice({
  name: reducerName,
  initialState: {
    page: localStorage.getItem('dashboardPageState') || 'dashboard',
    innerPage: localStorage.getItem('dashboardInnerPageState') || '',
    pdfDownloading: false,
    reportImagesLoaded: false
  },
  reducers: {
    setPage: (state, { payload }) => {
      state.page = payload
      localStorage.setItem('dashboardPageState', payload)
    },
    setInnerPage: (state, { payload }) => {
      state.innerPage = payload
      localStorage.setItem('dashboardInnerPageState', payload)
    },
    setPDFDownloading: (state, { payload }) => {
      state.pdfDownloading = payload
    },
    setReportImagesLoaded: (state, { payload }) => {
      state.reportImagesLoaded = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setPage,
  setInnerPage,
  setPDFDownloading,
  setReportImagesLoaded
} = dashboard.actions

// Reducer
export default dashboard.reducer
