import React from 'react'
import { Page, View, Text, Link } from '@react-pdf/renderer'
import { PDFFooter } from '../../components'
import styles from './style'
import globalStyles from '../../style'

const readings = [
  { title: 'Professionalising learning and development', link: 12 },
  { title: 'How to build and change habits in the workplace', link: 8 },
  { title: 'Driving performance and productivity', link: 4 },
  { title: 'L&D’s relationship with data', link: 9 },
  { title: 'The transformation journey', link: 14 },
  { title: 'Challenging perceptions of learners', link: 2 },
  { title: 'Bridging the divide', link: 1 },
  { title: 'Who moved my skills?', link: 16 },
  { title: 'Driving the new learning organisation', link: 5 },
  { title: 'Risk in the C-suite', link: 13 },
  { title: 'Going beyond ticking the box', link: 7 },
  { title: 'The evolution of 70:20:10', link: 15 },
  { title: 'Back to the future: why tomorrow’s workforce needs a learning culture', link: 6 },
  { title: 'Driving leadership capability', link: 3 },
  { title: 'Making an impact: how L&D leaders can demonstrate value', link: 11 }
]

const ReadingPage = ({ readingLinks }) => {
  return (
    <Page size='A4' orientation='landscape' wrap={false}>
      <View style={globalStyles.pageView}>
        <Text style={globalStyles.heading}>Recommended reading</Text>
        <Text style={globalStyles.introText}>Below is a list of our research resources for improving your maturity score. These reports have been compiled by our Research & Insights team. They include annual benchmark reports on learning trends, plus in-depth topical reports providing insights and practical advice for making an impact.</Text>
        <View style={styles.linksBox}>
          {readings.map((reading, index) => (
            <Link key={index} style={[globalStyles.body, styles.link]} href={`${readingLinks[reading.link]}`} target='_blank' rel='noreferrer'>{reading.title}</Link>
          ))}
        </View>
        <PDFFooter pageNumber={17} />
      </View>

    </Page>
  )
}

export default ReadingPage
