import React from 'react'
import { useStyles } from './style'
import { Paper, Typography, Button, Icon, IconButton } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const trends = ['Technology', 'Challenges', 'In-house skillsets']

const DashboardSummaryTrends = ({
  setDashboardPage,
  setInnerDashboardPage,
  isLoading
}) => {
  const classes = useStyles()

  const TechnologyIcon = () => (
    <Icon className={classes.trendIcon}>
      <img src='/img/trends-1.svg' alt='' />
    </Icon>
  )

  const ChallengeIcon = () => (
    <Icon className={classes.trendIcon}>
      <img src='/img/trends-2.svg' alt='' />
    </Icon>
  )

  const SkillIcon = () => (
    <Icon className={classes.trendIcon}>
      <img src='/img/trends-3.svg' alt='' />
    </Icon>
  )

  const handleClick = inner => {
    setDashboardPage('trends')
    setInnerDashboardPage(inner)
  }

  return (
    <Paper className={classes.paperRoot}>
      <Typography variant='h3'>Overall participant trends</Typography>
      <div className={classes.trendsBox}>
        {trends.map((trend, idx) => {
          if (idx === 0) {
            return isLoading
              ? (
                <Skeleton animation='wave' width='33%' height='170px' />
                )
              : (
                <div key={idx} className={classes.trendBox}>
                  <IconButton
                    aria-label='technology icon button'
                    classes={{ root: classes.hoverIconButton }}
                    onClick={() => handleClick('Technology')}
                  >
                    <TechnologyIcon />
                  </IconButton>
                  <Button
                    aria-label='technology button'
                    onClick={() => handleClick('Technology')}
                    endIcon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
                    data-test='toTechnology'
                  >
                    <Button variant='text'>{trend}</Button>
                  </Button>
                </div>
                )
          }
          if (idx === 1) {
            return isLoading
              ? (
                <Skeleton style={{ margin: '0 12px' }} animation='wave' width='33%' />
                )
              : (
                <div key={idx} className={classes.trendBox}>
                  <IconButton
                    aria-label='challenges icon button'
                    classes={{ root: classes.hoverIconButton }}
                    onClick={() => handleClick('Challenges')}
                  >
                    <ChallengeIcon />
                  </IconButton>
                  <Button
                    aria-label='challenges button'
                    onClick={() => handleClick('Challenges')}
                    endIcon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
                    data-test='toChallenges'
                  >
                    <Button variant='text'>{trend}</Button>
                  </Button>
                </div>
                )
          }
          return isLoading
            ? (
              <Skeleton animation='wave' width='33%' />
              )
            : (
              <div key={idx} className={classes.trendBox}>
                <IconButton
                  aria-label='skills icon button'
                  classes={{ root: classes.hoverIconButton }}
                  onClick={() => handleClick('Skills')}
                >
                  <SkillIcon />
                </IconButton>
                <Button
                  aria-label='skills button'
                  onClick={() => handleClick('Skills')}
                  endIcon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
                  data-test='toSkills'
                >
                  <Button variant='text'>{trend}</Button>
                </Button>
              </div>
              )
        })}
      </div>
    </Paper>
  )
}

export default DashboardSummaryTrends
