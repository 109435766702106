import { StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '30%'
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '60%'
  }
})

export default styles
