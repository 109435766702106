// A list of usefull functions to handle color transformation, gradients, etc.

const buildLinearGradient = ({ start, end }, angle = '180deg') => `linear-gradient(${angle}, ${start}, ${end})`

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : hex
}

export {
  buildLinearGradient,
  hexToRgb
}
