const firstEnableSection = {
  name: 'Strategy in practice and culture - Continued p11.1',
  elements: [
    {
      type: 'matrix',
      name: 'LNDSkills',
      title: '40. What skills do you consider a priority for your learning and development professionals to ensure a modernised learning strategy?',
      hideNumber: true,
      columns: [
        {
          value: 'Column 1',
          text: 'We already have this skill in-house'
        },
        {
          value: 'Column 2',
          text: 'We need to develop this skill now'
        },
        {
          value: 'Column 3',
          text: 'We need to develop this skill in the future'
        },
        {
          value: 'Column 4',
          text: 'Not a priority'
        }
      ],
      rows: [
        'In-person classroom delivery',
        'Strategy and governance',
        'Stakeholder engagement',
        'Instructional design',
        'Crafting learning journeys/blended solutions',
        'Supporting ongoing workplace performance (e.g., curating assets)',
        'Facilitating social and collaborative learning',
        'Understanding learner behaviour',
        'Virtual classroom / webinar delivery',
        'Digital content development',
        'Performance consulting',
        'Business acumen (e.g., organisational awareness, finance, IT, governance)',
        'Marketing and communications (e.g., nudges, newsletters)',
        'Coaching and mentoring',
        'Learning management / administration',
        'Analytics/data management',
        'Evaluating impact',
        'Technology / infrastructure',
        'Project management',
        'Leveraging L&D expertise',
        'Knowledge management',
        'Negotiation, persuasion, and influence',
        'Learning experience design',
        'Community engagement',
        'Research capabilities (quantitative and qualitative)'
      ]
    }
  ]
}

export default firstEnableSection
