import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useStyles } from './style'
import { useSession } from '@emerald-works/react-auth'
import Auth from '@aws-amplify/auth'
import { datadogRum } from '@datadog/browser-rum'

const PasswordDialog = ({
  open,
  handleClose,
  formRegister,
  formWatch,
  formReset,
  showPasswordAlert
}) => {
  const classes = useStyles()
  const { user } = useSession()
  const [page, setPage] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [formError, setFormError] = React.useState({})
  const [showPassword, setShowPassword] = React.useState(false)

  const oldPassword = formWatch('currentPassword')
  const newPassword = formWatch('password')
  const currentConfirmPassword = formWatch('confirmPassword')

  const handleResendCode = async () => {
    setIsLoading(true)
    try {
      await Auth.forgotPassword(user.username)
    } catch (e) {
      datadogRum.addError(e)
      console.log('Error sending forgot password code: ', e)
    }
    setIsLoading(false)
  }

  const handleForgotPassword = async () => {
    formReset({
      currentPassword: '',
      password: '',
      confirmPassword: ''
    })
    setIsLoading(true)
    try {
      await Auth.forgotPassword(user.username)
      setPage('forgot')
    } catch (e) {
      datadogRum.addError(e)
      console.log('Error sending forgot password code: ', e)
    }
    setIsLoading(false)
  }

  const handleShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  const dialogClose = () => {
    setFormError({})
    setPage('')
    handleClose()
  }

  const dialogSubmit = async () => {
    if (newPassword === currentConfirmPassword) {
      setIsLoading(true)
      if (page === 'forgot') {
        try {
          await Auth.forgotPasswordSubmit(
            user.username,
            oldPassword,
            newPassword
          )
          showPasswordAlert(true)
          dialogClose()
        } catch (e) {
          datadogRum.addError(e)
          console.log('forgot password error: ', e)
          setFormError({ error: true, message: e.message })
        }
      } else {
        try {
          await Auth.changePassword(user, oldPassword, newPassword)
          showPasswordAlert(true)
          dialogClose()
        } catch (e) {
          datadogRum.addError(e)
          console.log('Change password error: ', e)
          setFormError({ error: true, message: e.message })
        }
      }
      setIsLoading(false)
    } else {
      setFormError({ error: true, message: 'Passwords must match!' })
    }
  }

  const dialogTitle = page === 'forgot' ? 'Forgot password' : 'Change password'
  const Title = ({ children }) => <Typography variant='h3' className={classes.dialogTitle}>{children}</Typography>
  const typeTextFieldPassword = showPassword ? 'text' : 'password'

  return (
    <Dialog open={open}>
      <DialogTitle data-test={`modal-${dialogTitle.substring(0, 6)}`}>
        <Title>
          {dialogTitle}
        </Title>
      </DialogTitle>
      <DialogContent>
        {page === 'forgot' && (
          <Typography variant='body2'>
            {' '}
            A verification code has been sent to your email, use it to change
            your password
          </Typography>
        )}
        {page === 'forgot'
          ? (
            <TextField
              variant='outlined'
              margin='normal'
              label='Verification code'
              type='text'
              name='verificationCode'
              inputRef={formRegister}
              fullWidth
              data-test='confirmationCodeField'
            />
            )
          : (
            <TextField
              variant='outlined'
              margin='normal'
              label='Current password'
              type={typeTextFieldPassword}
              name='currentPassword'
              inputRef={formRegister}
              fullWidth
              data-test='currentPasswordField'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      data-test='showPassword'
                      tabIndex='-1'
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            )}
        <TextField
          variant='outlined'
          margin='normal'
          label='New password'
          type={showPassword ? 'text' : 'password'}
          name='password'
          inputRef={formRegister}
          helperText='8+ characters, with a mix of lower and upper case letters, numbers and symbols.'
          fullWidth
          data-test='passwordField'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  data-test='showPassword'
                  tabIndex='-1'
                  onClick={handleShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          variant='outlined'
          margin='normal'
          label='Confirm new password'
          type={showPassword ? 'text' : 'password'}
          name='confirmPassword'
          inputRef={formRegister}
          error={formError.error}
          helperText={formError.message ? formError.message : null}
          fullWidth
          data-test='confirmPasswordField'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  data-test='showPassword'
                  tabIndex='-1'
                  onClick={handleShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        {
          page === 'forgot'
            ? (
              <Typography
                onClick={handleResendCode}
                className={classes.link}
                data-test='lnk-toChangePassword'
              >
                Resend code
              </Typography>
              )
            : (
              <Typography
                onClick={handleForgotPassword}
                className={classes.link}
                data-test='lnk-forgot-accountDialog'
              >
                Forgotten your password?
              </Typography>
              )
        }
        <Button
          className={classes.secondaryButton}
          onClick={dialogClose}
          variant='outlined'
          data-test='btn-cancel-accountDialog'
        >
          Cancel
        </Button>
        <Button
          className={classes.primaryButton}
          onClick={dialogSubmit}
          variant='contained'
          startIcon={
            isLoading ? <CircularProgress size={14} color='inherit' /> : null
          }
          data-test='btn-submit-accountDialog'
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PasswordDialog
