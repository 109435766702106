// This dictionary contains 49 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { surveyLocalization } from "survey-core";

export var traditionalChineseSurveyStrings = {
  pagePrevText: "上一頁",
  pageNextText: "下一頁",
  completeText: "提交問卷",
  // previewText: "Preview",
  // editText: "Edit",
  // startSurveyText: "Start",
  otherItemText: "填寫其他答案",
  // noneItemText: "None",
  // selectAllItemText: "Select All",
  progressText: "第 {0} 頁, 共 {1} 頁",
  // indexText: "{0} of {1}",
  // panelDynamicProgressText: "{0} of {1}",
  // questionsProgressText: "Answered {0}/{1} questions",
  emptySurvey: "問卷中沒有問題或頁面",
  completingSurvey: "感謝您的參與!",
  // completingSurveyBefore: "Our records show that you have already completed this survey.",
  loadingSurvey: "問卷載入中...",
  placeholder: "請選擇...",
  // ratingOptionsCaption: "Tap to rate here...",
  // value: "value",
  requiredError: "請填寫此問題",
  // requiredErrorInPanel: "Response required: answer at least one question.",
  requiredInAllRowsError: "請填寫所有行中問題",
  numericError: "答案必須是個數字",
  // minError: "The value should not be less than {0}",
  // maxError: "The value should not be greater than {0}",
  textMinLength: "答案長度至少 {0} 個字元",
  textMaxLength: "答案長度不能超過 {0} 個字元",
  textMinMaxLength: "答案長度必須在 {0} - {1} 個字元之間",
  minRowCountError: "最少需要填寫 {0} 行答案",
  minSelectError: "最少需要選擇 {0} 項答案",
  maxSelectError: "最多只能選擇 {0} 項答案",
  numericMinMax: "答案 '{0}' 必須大於等於 {1} 且小於等於 {2}",
  numericMin: "答案 '{0}' 必須大於等於 {1}",
  numericMax: "答案 '{0}' 必須小於等於 {1}",
  invalidEmail: "請輸入有效的 Email 地址",
  // invalidExpression: "The expression: {0} should return 'true'.",
  urlRequestError: "載入選項時發生錯誤 '{0}': {1}",
  urlGetChoicesError: "未能載入有效的選項或請求參數路徑有誤",
  exceedMaxSize: "文件大小不能超過 {0}",
  otherRequiredError: "請完成其他問題",
  uploadingFile: "文件上傳中... 請耐心等待幾秒後重試",
  // loadingFile: "Loading...",
  // chooseFile: "Choose file(s)...",
  // noFileChosen: "No file chosen",
  // fileDragAreaPlaceholder: "Drop a file here or click the button below to load the file.",
  // confirmDelete: "Do you want to delete the record?",
  // keyDuplicationError: "This value should be unique.",
  // addColumn: "Add Column",
  addRow: "添加答案",
  removeRow: "刪除答案",
  // emptyRowsText: "There are no rows.",
  // addPanel: "Add new",
  // removePanel: "Remove",
  choices_Item: "選項",
  matrix_column: "列",
  matrix_row: "行",
  // multipletext_itemname: "text",
  savingData: "正在將結果保存到服務器...",
  savingDataError: "在保存結果過程中發生了錯誤，結果未能保存",
  savingDataSuccess: "結果保存成功!",
  saveAgainButton: "請重試",
  // timerMin: "min",
  // timerSec: "sec",
  // timerSpentAll: "You have spent {0} on this page and {1} in total.",
  // timerSpentPage: "You have spent {0} on this page.",
  // timerSpentSurvey: "You have spent {0} in total.",
  // timerLimitAll: "You have spent {0} of {1} on this page and {2} of {3} in total.",
  // timerLimitPage: "You have spent {0} of {1} on this page.",
  // timerLimitSurvey: "You have spent {0} of {1} in total.",
  // clearCaption: "Clear",
  // signaturePlaceHolder: "Sign here",
  // chooseFileCaption: "Choose file",
  // removeFileCaption: "Remove this file",
  // booleanCheckedLabel: "Yes",
  // booleanUncheckedLabel: "No",
  // confirmRemoveFile: "Are you sure that you want to remove this file: {0}?",
  // confirmRemoveAllFiles: "Are you sure that you want to remove all files?",
  // questionTitlePatternText: "Question Title",
  // modalCancelButtonText: "Cancel",
  // modalApplyButtonText: "Apply",
  // filterStringPlaceholder: "Type to search...",
  // emptyMessage: "No data to display",
  // noEntriesText: "There are no entries yet.\nClick the button below to add a new entry.",
  // more: "More"
};

surveyLocalization.locales["zh-tw"] = traditionalChineseSurveyStrings;
surveyLocalization.localeNames["zh-tw"] = "繁體中文";
