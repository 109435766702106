import { StyleSheet, Font } from '@react-pdf/renderer'
import Matter from '../../../../fonts/Matter-Regular.ttf'
import MatterBold from '../../../../fonts/Matter-Bold.ttf'

Font.register({ family: 'Matter', src: Matter })
Font.register({ family: 'MatterBold', src: MatterBold })

const styles = StyleSheet.create({
  text: {
    fontSize: 12,
    fontFamily: 'Matter',
    padding: 5,
    textAlign: 'center',
    textOverflow: 'break-word',
    hyphens: 'none'
  },
  bold: {
    fontSize: 12,
    fontFamily: 'MatterBold',
    padding: 5,
    textAlign: 'center',
    hyphens: 'none'
  },
  firstGreyBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#E9EAEB',
    padding: 5,
    marginRight: 5,
    borderRadius: 10
  },
  greyBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#E9EAEB',
    padding: 5,
    marginHorizontal: 5,
    borderRadius: 10
  },
  lastGreyBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#E9EAEB',
    padding: 5,
    marginLeft: 5,
    borderRadius: 10
  }
})

export default styles
