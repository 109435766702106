import name from './name'

const selectSliceData = state => state[name]
const selectTeamInformation = state => selectSliceData(state).teamInformation
const selectTeam = state => selectSliceData(state).team
const selectMembers = state => selectSliceData(state).members
const selectStatus = state => selectSliceData(state).status
const selectTeamId = state => selectSliceData(state).teamId
const selectTeamChartData = state => selectSliceData(state).teamChartData
const selectTeamChartDataError = state => selectSliceData(state).teamChartDataError
// LPB-1510
const selectTeamInvitationStatus = state => selectSliceData(state).teamInvitationStatus
const selectTeamInvitationStatusError = state => selectSliceData(state).teamInvitationStatusError

export {
  selectTeamInformation,
  selectTeam,
  selectMembers,
  selectStatus,
  selectTeamId,
  selectTeamChartData,
  selectTeamChartDataError,
  // LPB-1510
  selectTeamInvitationStatus,
  selectTeamInvitationStatusError
}
