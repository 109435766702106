const firstDeliverySection = {
  name: 'Technology, tools and access p3',
  elements: [
    {
      type: 'matrixdropdown',
      name: 'Blend',
      title: '4. What percentage of your organisation’s learning falls into each of the following categories? How likely is this to change over the next 2 years? (Make sure the percentage adds up to 100%).',
      hideNumber: true,
      validatePercentage: 'true',
      columns: [
        {
          name: 'Column 1',
          title: 'Percentage',
          cellType: 'text',
          defaultValue: 'indeterminate',
          inputType: 'number'

        },
        {
          name: 'Column 2',
          title: 'In the next 2 years, we expect this percentage to',
          cellType: 'dropdown',
          choices: [
            'Increase',
            'Stay the same',
            'Decrease'
          ]
        }
      ],
      choices: [
        1,
        2,
        3,
        4,
        5
      ],
      rows: [
        {
          value: 'Row 1',
          text: 'What percentage of your learning is entirely face-to-face?'
        },
        {
          value: 'Row 2',
          text: 'What percentage is a blend of face-to-face and online learning?'
        },
        {
          value: 'Row 3',
          text: 'What percentage is entirely online?'
        }
      ]
    }
  ]
}

export default firstDeliverySection
