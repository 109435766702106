import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '24px 0'
  },
  link: {
    fontSize: '12px',
    color: theme.palette.secondary.main,
    fontWeight: 400,
    cursor: 'pointer',
    margin: '12px 0'
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '8px'
  },
  subDescription: {
    margin: '24px 0'
  },
  primaryButton: {
    marginLeft: '1rem',
    backgroundColor: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s'
    }
  },
  secondaryButton: {
    color: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s',
      color: '#FFFFFF'
    }
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px'
  }
}))
