import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { useStyles } from './style'

const DashboardTrendPanel = ({ tab, trends, season, caption, isTeam }) => {
  const classes = useStyles()
  const headers = [
    'Rank',
    'Trending technology',
    isTeam ? 'Percentage who agreed you\'re using in-house' : 'You\'re using in-house',
    'Overall benchmark',
    `Change from ${season - 1}`
  ]

  const HandleIconStatements = ({ change }) => (
    change > 0
      ? <ArrowUpwardIcon className={classes.scorePositive} />
      : <ArrowDownwardIcon className={classes.scoreNegative} />
  )

  const TableCellSkills = ({ tab, idx, header }) => (
    tab === 'Skills'
      ? <TableCell key={idx}>Top skillsets</TableCell>
      : <TableCell key={idx}>{header}</TableCell>
  )

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label={`${tab} trends table`}>
        <caption>{caption}</caption>
        <TableHead>
          <TableRow>
            {headers.map((header, idx) => {
              if (header === 'Trending technology') {
                return tab === 'Challenges'
                  ? <TableCell key={idx}>Top challenges</TableCell>
                  : <TableCellSkills tab={tab} idx={idx} header={header} />
              }
              if (idx === 1 && tab === 'Challenges') {
                return <TableCell key={idx}>Top challenges</TableCell>
              }
              if (idx === 1 && tab === 'Skills') {
                return <TableCell key={idx}>Top skillsets</TableCell>
              }
              if (idx === 2 && tab === 'Challenges' && !isTeam) {
                return (
                  <TableCell className={classes.smallCell} key={idx}>
                    You've identified this challenge
                  </TableCell>
                )
              }
              if (idx === 2 && tab === 'Skills' && !isTeam) {
                return (
                  <TableCell className={classes.smallCell} key={idx}>
                    You have this skill in-house
                  </TableCell>
                )
              }
              return (
                <TableCell className={classes.smallCell} key={idx}>
                  {header}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {trends.map((trend, idx) => (
            <TableRow key={`${trend.url}-${idx}`}>
              <TableCell>{idx + 1}</TableCell>
              <TableCell component='th' scope='row'>
                {trend.text}
              </TableCell>
              <TableCell align='left'>{isTeam ? trend.used : trend.used ? 'Yes' : 'No'}</TableCell>
              <TableCell align='left'>{trend.percentage}%</TableCell>
              <TableCell align='left'>
                {
                  trend.change !== 0
                    ? (
                      <span className={classes.scoreBox}>
                        <Typography variant='body1'>
                          {Math.abs(trend.change)}%
                        </Typography>
                        <HandleIconStatements change={trend.change} />
                      </span>
                      )
                    : (
                      <span className={classes.scoreBox}>
                        <Typography variant='body1'>0%</Typography>
                      </span>
                      )
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DashboardTrendPanel
