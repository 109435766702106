import React from 'react'
import { PercentagesChart } from '../../components'
import { Paper, Typography } from '@material-ui/core'
import { useStyles } from './style'

const DashboardMaturityPercentagesCard = ({ season }) => {
  const classes = useStyles()

  return (
    <Paper variant='outlined' className={classes.root} data-test='card-stage'>
      <Typography variant='h3'>Overall participants by stage</Typography>
      <PercentagesChart currentSeason={season} />
    </Paper>
  )
}

export default DashboardMaturityPercentagesCard
