import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '8rem',
    paddingBottom: '6rem',
    paddingRight: '8rem',
    paddingLeft: '10rem'
  },
  headTitle: {
    fontSize: '2.5rem'
  },
  headContent: {
    fontSize: '1.3rem'
  },
  box: {
    marginTop: '1.5rem'
  },
  boxIntro: {
    marginTop: '3rem'
  },
  content: {
    fontSize: '1rem'
  },
  paragraph: {
    marginTop: '1.5rem',
    fontSize: '1rem'
  },
  italic: {
    fontSize: '1rem',
    marginTop: '1.5rem',
    fontStyle: 'italic'
  },
  subTitle: {
    marginTop: '1.5rem',
    fontSize: '1rem'
  },
  highlight: {
    marginTop: '1.5rem',
    fontSize: '1rem',
    fontWeight: 700
  },
  linkExternal: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline'
  },
  linkLPB: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline'
  },
  linkLPBCookies: {
    color: theme.palette.secondary.main,
    fontSize: '1.25rem',
    fontWeight: 700,
    textDecoration: 'underline'
  }
}))
