import React from 'react'
import { Button } from '@material-ui/core'
import { useStyles } from './style'

const SurveyNavButtons = ({ finalPage, handleBackClick, disableBackButton, getSectionNextStep, handleSubmit, saveExitBtnId, handleSaveAndExit }) => {
  const classes = useStyles()

  return (
    <div className={classes.divButtons}>
      <Button variant='outlined' color='secondary' className={classes.navBackButton} onClick={handleBackClick} disabled={disableBackButton}>Back</Button>
      {
        !finalPage
          ? <Button id='btn-continue' className={classes.primaryButton} variant='contained' onClick={getSectionNextStep}>Continue</Button>
          : <Button id='btn-submit-review' className={classes.primaryButton} variant='contained' onClick={handleSubmit}>Submit</Button>
      }
      <Button id={saveExitBtnId} className={classes.navExitButton} onClick={handleSaveAndExit}>Save and exit</Button>
    </div>
  )
}

export default SurveyNavButtons
