import React from 'react'
import { useEvent } from '@emerald-works/react-event-bus-client'
import {
  Dialog,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { teamSlice, userSlice } from '../../reducers'
import { useStyles } from './style'

const TeamUpdateProfileDialog = ({
  open,
  handleClose,
  teamId
}) => {
  const classes = useStyles()

  const [teamInformationError, setTeamInformationError] = React.useState('')

  const [saveTeamInformation] = useEvent([teamSlice.eventBus.saveTeamInformation])
  const [getTeam] = useEvent([teamSlice.eventBus.getTeam])
  const [getUserData] = useEvent([userSlice.eventBus.getUserData])

  const { register, watch, control } = useForm({
    defaultValues: {
      team: '',
      organisation: '',
      size: '',
      region: '',
      spread: '',
      industry: ''
    }
  })

  const handleCreateTeamInformation = () => {
    const formData = watch()
    const organisationName = formData.organisation
    const organisationSize = formData.size
    const organisationRegion = formData.region
    const organisationSpread = formData.spread
    const organisationIndustry = formData.industry
    const organisationHashId = teamId

    const nameError = !organisationName || organisationName.length === 0
    const sizeError = !organisationSize || organisationSize.length === 0
    const regionError = !organisationRegion || organisationRegion.length === 0
    const spreadError = !organisationSpread || organisationSpread.length === 0
    const industryError = !organisationIndustry || organisationIndustry.length === 0

    if (nameError || sizeError || regionError || spreadError || industryError) {
      setTeamInformationError({ nameError, sizeError, regionError, spreadError, industryError })
    } else {
      const teamInfoData = {
        name: organisationName,
        size: organisationSize,
        region: organisationRegion,
        spread: organisationSpread,
        industry: organisationIndustry,
        hashId: organisationHashId
      }

      saveTeamInformation.trigger({ type: 'information', data: teamInfoData })
      getUserData.trigger()
      getTeam.trigger()
      handleClose()
    }
  }

  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <Typography variant='h3' className={classes.header}>Add team information</Typography>
        <form>
          <FormControl className={classes.formControl} fullWidth>
            <FormLabel className={classes.formLabel}>What is your organisation?</FormLabel>
            <TextField
              variant='outlined'
              name='organisation'
              type='text'
              inputRef={register}
              error={teamInformationError.nameError}
              helperText={teamInformationError.nameError && 'You must provide your organisation name'}
              inputPros={{ 'aria-label': 'Organisation name' }}
              data-test='organisationNameField'
            />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <FormLabel className={classes.formLabel} id='organisation-size-radio-group-label'>What is the size of your organisation?</FormLabel>
            <RadioGroup
              aria-labelledby='organisation-size-radio-group-label'
              name='size'
              inputRef={register}
            >
              <FormControlLabel value='1 - 9' control={<Radio inputRef={register} />} label='1-9' />
              <FormControlLabel value='10 - 249' control={<Radio inputRef={register} />} label='10-249' />
              <FormControlLabel value='250 - 499' control={<Radio inputRef={register} />} label='250-499' />
              <FormControlLabel value='500 - 999' control={<Radio inputRef={register} />} label='500-999' />
              <FormControlLabel value='1000 - 4999' control={<Radio inputRef={register} />} label='1000-4999' />
              <FormControlLabel value='5000 - 19999' control={<Radio inputRef={register} />} label='5000-19999' />
              <FormControlLabel value='20000+' control={<Radio inputRef={register} />} label='20000+' />
            </RadioGroup>
            {teamInformationError && teamInformationError.sizeError && (
              <FormHelperText style={{ color: 'red' }}>You need to provide an organisation size</FormHelperText>
            )}
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <FormLabel className={classes.formLabel} id='organisation-region-radio-group-label'>Where`s your organisation head office?</FormLabel>
            <RadioGroup
              aria-labelledby='organisation-region-radio-group-label'
              name='region'
            >
              <FormControlLabel value='UK' control={<Radio inputRef={register} />} label='UK' />
              <FormControlLabel value='Other European countries' control={<Radio inputRef={register} />} label='Other european countries' />
              <FormControlLabel value='USA/Canada' control={<Radio inputRef={register} />} label='USA/Canada' />
              <FormControlLabel value='Australia/New Zealand' control={<Radio inputRef={register} />} label='Australia/New Zealand' />
              <FormControlLabel value='Other Asia Pacific' control={<Radio inputRef={register} />} label='Other Asia Pacific' />
              <FormControlLabel value='Middle East/Africa/India' control={<Radio inputRef={register} />} label='Middle East/Africa/India' />
              <FormControlLabel value='South/Central America' control={<Radio inputRef={register} />} label='South/Central America' />
            </RadioGroup>
            {teamInformationError && teamInformationError.regionError && (
              <FormHelperText style={{ color: 'red' }}>You need to provide an organisation region</FormHelperText>
            )}
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <FormLabel className={classes.formLabel} id='organisation-spread-radio-group-label'>Where`s your organisation head office?</FormLabel>
            <RadioGroup
              aria-labelledby='organisation-spread-radio-group-label'
              name='spread'
            >
              <FormControlLabel value='Single site' control={<Radio inputRef={register} />} label='Single site' />
              <FormControlLabel value='Multiple locations - one nation' control={<Radio inputRef={register} />} label='Multiple locations - one nation' />
              <FormControlLabel value='Mostly single nation with some global distribution' control={<Radio inputRef={register} />} label='Mostly single nation with some global distribution' />
              <FormControlLabel value='Multinational - highly distributed with multiple locations across the globe' control={<Radio inputRef={register} />} label='Multinational - highly distributed with multiple locations across the globe' />
            </RadioGroup>
            {teamInformationError && teamInformationError.spreadError && (
              <FormHelperText style={{ color: 'red' }}>You need to provide an organisation spread</FormHelperText>
            )}
          </FormControl>
          <FormControl variant='outlined' className={classes.formControl} fullWidth>
            <FormLabel
              id='organisation-industry-select'
              className={classes.formLabel}
            >
              In which industry is your organisation`s primary business?
            </FormLabel>
            <Controller
              control={control}
              name='industry'
              render={({ onChange, value, ref }) => (
                <Select
                  labelId='organisation-industry-select'
                  inputRef={ref}
                  value={value}
                  onChange={onChange}
                >
                  <MenuItem value='Central government'>Central government</MenuItem>
                  <MenuItem value='Local government'>Local government</MenuItem>
                  <MenuItem value='IT and Telecoms'>IT and Telecoms'</MenuItem>
                  <MenuItem value='Professional services, law and accountancy'>Professional services, law and accountancy</MenuItem>
                  <MenuItem value='Finance, banking and insurance'>Finance, banking and insurance</MenuItem>
                  <MenuItem value='Health'>Health</MenuItem>
                  <MenuItem value='Social care/housing association'>Social care/housing association</MenuItem>
                  <MenuItem value='Other charity/voluntary sector'>Other charity/voluntary sector</MenuItem>
                  <MenuItem value='Retail'>Retail</MenuItem>
                  <MenuItem value='Engineering'>Engineering</MenuItem>
                  <MenuItem value='Manufacturing'>Manufacturing</MenuItem>
                  <MenuItem value='Pharmaceutical'>Pharmaceutical</MenuItem>
                  <MenuItem value='Transport'>Transport</MenuItem>
                  <MenuItem value='Utilities'>Utilities</MenuItem>
                  <MenuItem value='Hospitality'>Hospitality</MenuItem>
                  <MenuItem value='Education (HE, FE)'>Education (HE, FE)</MenuItem>
                  <MenuItem value='Commercial training/e-learning supply'>Commercial training/e-learning supply</MenuItem>
                  <MenuItem value='Consulting'>Consulting</MenuItem>
                  <MenuItem value='Art, media and design'>Art, media and design</MenuItem>
                  <MenuItem value='Other'>Other</MenuItem>
                </Select>
              )}
            />
            {teamInformationError && teamInformationError.industryError && (
              <FormHelperText style={{ color: 'red' }}>You need to provide an organisation industry</FormHelperText>
            )}
          </FormControl>
        </form>
        <div className={classes.buttons}>
          <Button className='Team_Edit_Modal_Cancel' variant='outlined' color='secondary' onClick={handleClose}>Cancel</Button>
          <Button
            className={`${classes.rightButton} Team_Update_Profile_Send`}
            variant='contained'
            color='secondary'
            onClick={handleCreateTeamInformation}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default TeamUpdateProfileDialog
