import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { useStyles } from './style'

const AccountUpgradeBanner = ({ handleClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.upgradeBannerBox}>
      <img src='/img/team-homepage-1.png' width='100px' height='100px' alt='Team upgrade banner' />
      <Typography variant='h2' style={{ textAlign: 'center' }}>Why not upgrade to a teams account?</Typography>
      <Typography variant='body1' style={{ textAlign: 'center', marginBottom: '24px' }}>Benchmark with your colleagues and get a 360º view of your L&D.</Typography>
      <Button variant='contained' color='secondary' onClick={handleClick}>Learn more</Button>
    </div>
  )
}

export default AccountUpgradeBanner
