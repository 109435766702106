import * as colors from '@material-ui/core/colors'

// This is the base palette that will be used to build the theme.
// `palette` param option will either override or add values to
// the palette property of theme object that is inject for the entire application.

// Add new properties as needed and all application components will have the
// new proprity available in the `theme.palette` object

// For more information about each property and what it means:
// https://material-ui.com/customization/palette/

const basePallete = {
  primary: {
    light: '#EFEEF3',
    main: '#343337',
    dark: '#141317'
  },
  secondary: {
    light: '#20B1F7',
    main: '#006FB7',
    dark: '#024E95'
  },
  error: {
    light: colors.red[300],
    main: colors.red[500],
    dark: colors.red[700]
  },
  warning: {
    light: colors.orange[300],
    main: colors.orange[500],
    dark: colors.orange[700]
  },
  info: {
    light: colors.blue[300],
    main: colors.blue[500],
    dark: colors.blue[700]
  },
  success: {
    light: colors.green[300],
    main: colors.green[500],
    dark: colors.green[700]
  },
  type: 'light',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  // The colors used to style the text.
  text: {
    // The most important text.
    primary: '#343337',
    // Secondary text.
    secondary: 'rgba(0, 0, 0, 0.54)',
    // Disabled text have even lower visual prominence.
    disabled: 'rgba(0, 0, 0, 0.38)',
    // Text hints.
    hint: 'rgba(0, 0, 0, 0.38)',
    // Custom color
    customColor: '#76ff03'
  },
  // The color used to divide different elements.
  divider: 'rgba(0, 0, 0, 0.12)',
  // The background colors used to style the surfaces.
  // Consistency between these values is important.
  background: {
    paper: colors.common.white,
    default: '#FFFFFF'
  },
  // The colors used to style the action elements.
  action: {
    // The color of an active action like an icon button.
    active: 'rgba(0, 0, 0, 0.54)',
    // The color of an hovered action.
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    // The color of a selected action.
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    // The color of a disabled action.
    disabled: 'rgba(0, 0, 0, 0.26)',
    // The background color of a disabled action.
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12
  }
}

export default basePallete
