import React from 'react'
import { Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useStyles } from './style'

const AccountSubscriptionTabComponent = ({ isPremium }) => {
  const classes = useStyles()

  const currentSubscriptionInfo = isPremium
    ? {
        subscription: 'Teams',
        cost: '£3,500 one-off payment',
        benefits: [
          '1 admin and up to 19 team members under one account',
          'Combined average maturity score for the whole team',
          'See anonymised colleague`s scores simultaneously'
        ]
      }
    : {
        subscription: 'Individual',
        cost: 'Free',
        benefits: [
          'Complete the review every season for free',
          'Personalised results with next steps to improve your L&D maturity',
          'Download PDF to share with stakeholders'
        ]
      }

  return (
    <span>
      <Typography variant='h2' className={classes.description}>
        Current subscription
      </Typography>
      <div className={classes.subscriptionTab}>
        <div className={classes.subscription}>
          <div className={classes.subscriptionBox}>
            <Typography variant='subtitle1'>Subscription</Typography>
            <Typography>{currentSubscriptionInfo.subscription}</Typography>
          </div>
          <div className={classes.subscriptionBox} style={{ marginLeft: '24px' }}>
            <Typography variant='subtitle1'>Cost</Typography>
            <Typography>{currentSubscriptionInfo.cost}</Typography>
          </div>
        </div>
        <Typography variant='subtitle1'>Subscription benefits</Typography>
        <List dense>
          {currentSubscriptionInfo.benefits.map((benefit, idx) => (
            <ListItem key={idx} className={classes.li}>
              <ListItemIcon className={classes.liIcon}>
                <CheckIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                <Typography>{benefit}</Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </div>
    </span>
  )
}

export default AccountSubscriptionTabComponent
