import { StyleSheet } from '@react-pdf/renderer'
const styles = StyleSheet.create({
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '20%'
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
    width: '70%'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
    width: '25%'
  }
})
export default styles
