const thirdEnableSection = {
  name: 'Strategy in practice and culture - Continued p7',
  elements: [
    {
      type: 'customrating',
      name: 'EnableLND_6',
      title: '48. We consider courses as only one of many options for building skills and performance',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableLND_8',
      title: '49. Our approach is shaped by models that support learning direct in the flow of work (e.g., 70:20:10)',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableCollab_1',
      title: '50. We encourage learners to solve problems together',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableCollab_2',
      title: '51. We actively encourage employees to collaborate in building knowledge resources',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableCollab_3',
      title: '52. Employees know how to work together to productively connect and share knowledge',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableCollab_6',
      title: '53. Our learning strategy ensures that employees can learn collaboratively',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    },
    {
      type: 'customrating',
      name: 'EnableCollab_5',
      title: '54. Managers encourage and make time for social and informal learning',
      hideNumber: true,
      titleLocation: 'left',
      rateMax: 9,
      minRateDescription: 'Strongly Disagree',
      maxRateDescription: 'Strongly Agree'
    }
  ]
}

export default thirdEnableSection
