import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './style'

const LPFaqRow = () => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.lightContainer}>
        <Grid item xs={12}>
          <Typography variant='h2' style={{ textAlign: 'center', marginBottom: 12, marginTop: 64 }}>FAQs</Typography>
          <div className={classes.accordionContainer}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='lp-faq1-content'
                id='lp-faq1-header'
              >
                <Typography variant='subtitle1'>What does the review cover?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>We first ask for demographic information like organisation size, geographic spread and industry. This enable us to build your personalised results, and means we can accurately benchmark your L&D strategy against organisations similar to you.</Typography>
                  <Typography>It's then divided into four parts:</Typography>
                  <ol>
                    <li>vision, resources and impact</li>
                    <li>strategy in practice and culture</li>
                    <li>technology, tools and access</li>
                    <li>challenges preventing you from getting the most out of your L&D strategy.</li>
                  </ol>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='lp-faq2-content'
                id='lp-faq2-header'
              >
                <Typography variant='subtitle1'>How long does the review take to complete?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Roughly 60 minutes. However, you can save your progress and complete it later.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='lp-faq3-content'
                id='lp-faq3-header'
              >
                <Typography variant='subtitle1'>What data can I expect to see in my results?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>Your dashboard clearly displays your learning maturity score, learning maturity stage, and overall benchmark participant trends. It also breaks down your six key components:</Typography>
                  <ol>
                    <li>strategic and business alignment</li>
                    <li>employee experience</li>
                    <li>environment, talent and culture</li>
                    <li>L&D team capabilities</li>
                    <li>organisational engagement</li>
                    <li>learning impact</li>
                  </ol>
                  <Typography>Each page includes interactive graphs with the ability to filter by industry, region, and organisation size, as well as links to further reading and insights into how to progress to higher maturity.</Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='lp-faq4-content'
                id='lp-faq4-header'
              >
                <Typography variant='subtitle1'>Who am I benchmarked against?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>You will be benchmarked against all the participants who completed the LPB the previous year. This is then broken down into data from your selected industry, region and organisation size.</Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LPFaqRow
