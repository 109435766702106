import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import styles from './style'

const QuickTakeawaysCard = ({ type, score }) => {
  let takeawayText = ''
  let boxStyle = styles.greyBox
  switch (type) {
    case 'overall':
      takeawayText = 'the average benchmark for this year'
      boxStyle = styles.firstGreyBox
      break
    case 'industry':
      takeawayText = 'the average for organisations in your industry'

      break
    case 'size':
      takeawayText = 'the average for your organisation size'
      break
    case 'stage':
      takeawayText = 'points to move up to the next stage of organisational learning maturity'
      boxStyle = styles.lastGreyBox
      break
    default:
      break
  }
  let scoreText = ''
  if (score > 0) {
    scoreText = `${score} points above`
    if (type === 'stage') {
      scoreText = `${score} more`
    }
  } else {
    if (type === 'stage') {
      scoreText = `${Math.abs(score)} more`
    } else {
      scoreText = `${Math.abs(score)} points below`
    }
  }
  return (
    <View style={boxStyle}>
      <Text style={styles.text}>{type === 'stage' ? 'You need' : 'You are'} <Text style={styles.bold}>{scoreText}</Text> {takeawayText}</Text>
    </View>
  )
}

export default QuickTakeawaysCard
