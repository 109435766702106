import { Button, Grid, Typography } from '@material-ui/core'
import { useStyles } from './style'
import { useNavigate } from 'react-router-dom'
import { userSlice } from '../../reducers'
import { useDispatch } from 'react-redux'
import React from 'react'

const HomePageHeader = ({ userName, pageDescription, pageState, hasPreviousResults, teamsInfo, reportUrl }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatcher = useDispatch()

  const handleDownloadReport = () => {
    startReportDownload()
  }

  const startReportDownload = () => {
    setTimeout(() => {
      if (reportUrl !== undefined) {
        window.open(reportUrl)
      } else {
        startReportDownload()
      }
    }, 500)
  }

  const handleMenuChange = (page) => {
    if (page !== 'teams' && page !== 'team-create' && page !== 'upgrade' && page !== 'team-dashboard') {
      dispatcher(userSlice.actions.setMenuLevel(1))
      dispatcher(userSlice.actions.setActiveMenuSection('you'))
    }
    if (page === 'team-create' || page === 'upgrade') {
      dispatcher(userSlice.actions.setMenuLevel(0))
      dispatcher(userSlice.actions.setActiveMenuSection('teams'))
    }
    if (page === 'teams' || page === 'team-dashboard') {
      dispatcher(userSlice.actions.setMenuLevel(1))
      dispatcher(userSlice.actions.setActiveMenuSection('teams'))
    }
  }

  const handleClick = (page) => {
    navigate(`/${page}`)
    handleMenuChange(page)
  }

  return (
    <Grid item xs={12} md={6}>
      <Typography data-test='appHeader' variant='h1'>
        {' '}
        Hi {userName}!
      </Typography>
      <Typography className={classes.introText}>
        {pageDescription}
      </Typography>
      <div className={classes.btnContainer}>
        {pageState === 'new' && (
          <Button
            onClick={() => handleClick('review')}
            data-test='btn-startReview'
            className={classes.button}
            variant='contained'
            color='secondary'
          >
            Start your review
          </Button>
        )}
        {pageState === 'new-season' && (
          <Button
            onClick={() => handleClick('review')}
            data-test='btn-startNewReview'
            className={classes.button}
            variant='contained'
            color='secondary'
          >
            Start new review
          </Button>
        )}
        {pageState === 'partial' && (
          <Button
            onClick={() => handleClick('review')}
            data-test='btn-finishReview'
            className={classes.button}
            variant='contained'
            color='secondary'
          >
            Continue your review
          </Button>
        )}
        {pageState === 'recurrent' && (
          <Button
            onClick={() => handleClick('dashboard')}
            data-test='btn-viewResults'
            className={classes.button}
            variant='contained'
            color='primary'
          >
            View your results
          </Button>
        )}
        {pageState === 'new-season' && hasPreviousResults && (
          <Button
            onClick={() => handleClick('dashboard')}
            data-test='btn-viewPreviousResults'
            className={classes.button}
            variant='contained'
            color='primary'
          >
            View previous results
          </Button>
        )}
        {!teamsInfo && (
          <Button
            onClick={() => handleClick('upgrade')}
            data-test='btn-upgradeTeam'
            className={`${classes.buttonOutlined} Home_Explore_CTA`}
            variant='outlined'
            color='primary'
          >
            Explore team benefits
          </Button>
        )}
        {teamsInfo && teamsInfo.teamResults && (
          <Button
            onClick={() => handleClick('team-dashboard')}
            data-test='btn-viewTeam'
            className={`${classes.buttonOutlined} Team_Results_CTA`}
            variant='outlined'
            color='primary'
          >
            View your team results
          </Button>
        )}
        {teamsInfo && teamsInfo.teamAdminPending && (
          <Button
            onClick={() => handleClick('upgrade')}
            data-test='btn-resubscribeTeam'
            className={classes.buttonOutlined}
            variant='outlined'
            color='primary'
          >
            Rebuy premium
          </Button>
        )}
        {teamsInfo && teamsInfo.teamAdmin && !teamsInfo.teamResults && (
          <Button
            onClick={() => !teamsInfo.hasTeam ? handleClick('team-create') : handleClick('teams')}
            data-test='btn-viewTeam'
            className={classes.buttonOutlined}
            variant='outlined'
            color='primary'
          >
            {!teamsInfo.hasTeam ? 'Add my team' : 'View your team space'}
          </Button>
        )}
        {teamsInfo && !teamsInfo.teamAdmin && !teamsInfo.teamResults && pageState === 'new' && (
          <Button
            onClick={handleDownloadReport}
            data-test='btn-downloadResearchReport'
            className={classes.buttonOutlined}
            variant='outlined'
            color='primary'
          >
            Download research report
          </Button>
        )}

      </div>
    </Grid>
  )
}

export default HomePageHeader
