import { StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  takeawaysBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px',
    height: '100px',
    width: '100%'
  },
  graphsContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '45%',
    justifyContent: 'space-between',
    marginTop: 5
  },
  circularChart: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    justifyContent: 'center'
  },
  lineChart: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    justifyContent: 'center'
  }
})

export default styles
