import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    position: 'relative',
    padding: '24px',
    marginBottom: '40px'
  },
  cardHeader: {
    marginTop: 0
  },
  cardCollapsedHeader: {
    margin: 0,
    color: 'rgba(37, 55, 70, 0.38)'
  },
  cardCompletedHeader: {
    margin: 0
  },
  cardDescription: {
    marginBottom: '24px'
  },
  icon: {
    position: 'absolute',
    top: '24px',
    right: '24px',
    fill: '#7BC340',
    height: '40px',
    fontSize: '32px'
  }
}))
