const config = {
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
  }
}

export default config
