import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  navBackButton: {
    marginRight: '16px',
    color: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s',
      color: '#FFFFFF'
    }
  },
  navExitButton: {
    display: 'flex',
    float: 'right',
    fontSize: '14px',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: '#E9ECED',
      transition: 'background-color 0.2s'
    }
  },
  primaryButton: {
    backgroundColor: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s'
    }
  },
  divButtons: {
    paddingTop: '3rem'
  }
}))
