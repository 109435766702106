import React from 'react'
import {
  Dialog,
  Typography,
  Button
} from '@material-ui/core'
import { useStyles } from './style'

const RemoveTeamMemberDialog = ({
  open,
  reviewStatus,
  handleClose,
  handleRemove,
  member
}) => {
  const classes = useStyles()

  const handleRemoveClick = () => {
    handleRemove(member.email)
  }

  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <Typography variant='h3' className={classes.header}>Remove a team member</Typography>
        <Typography className={classes.text}>By personally removing {member.name} ({member.email}), they will no longer have access to the team results</Typography>
        {reviewStatus === 'open'
          ? (
            <Typography className={classes.text}>As the review is still open, their data will not be added to the team aggregate.</Typography>
            )
          : (
            <Typography className={classes.text}>As you've closed the review, their data will remain in the team aggregate</Typography>
            )}
        <Typography>Are you sure you want to remove them?</Typography>
        <div className={classes.buttons}>
          <Button variant='outlined' color='secondary' onClick={handleClose}>No</Button>
          <Button className={classes.rightButton} variant='contained' color='secondary' onClick={handleRemoveClick}>Yes, remove</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default RemoveTeamMemberDialog
