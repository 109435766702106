import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import Grid from '@material-ui/core/Grid'
import { useMediaQuery } from '@material-ui/core'
import {
  RegisterHeader,
  RegisterCarousel,
  RegisterForm,
  RegisterFooter,
  VerifyEmail,
  PageAlert,
  RegisterOptions
} from '../../components'
import { useStyles } from './style'
import { registerSlice, loginSlice } from '../../reducers'

const Register = () => {
  const classes = useStyles()
  const dispatcher = useDispatch()
  const history = useNavigate()
  const search = new URLSearchParams(useLocation().search)
  const isRegistered = useSelector(registerSlice.selectors.selectIsRegistered)
  const showRegisterAlert = useSelector(loginSlice.selectors.selectShowRegisterAlert)
  const registerType = useSelector(loginSlice.selectors.selectRegisterType)
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const teamId = search.get('teamId') || ''
  const userEmail = search.get('userEmail')?.replace(' ', '+') || ''
  const searchRegisterType = search.get('type') || ''

  useEventsOnViewLoad(() => {
    if (searchRegisterType.length) {
      search.delete('type')
      history({ search: search.toString() })
      dispatcher(loginSlice.actions.setRegisterType(searchRegisterType))
    }
  })

  if (showRegisterAlert) {
    setTimeout(() => {
      dispatcher(loginSlice.actions.setShowRegisterAlert(false))
    }, 7000)
  }

  const handleClickRegisterTeams = () => {
    dispatcher(loginSlice.actions.setRegisterType('teams'))
  }

  const handleClickRegisterIndividual = () => {
    dispatcher(loginSlice.actions.setRegisterType('indivividual'))
  }

  const handleGoBack = () => {
    dispatcher(loginSlice.actions.setRegisterType(''))
  }

  const LeftSide = () => {
    return (
      <>
        <Grid item xs={12}>
          <RegisterHeader />
        </Grid>
        <Grid item xs={12}>
          {isRegistered
            ? (
              <VerifyEmail />
              )
            : registerType === ''
              ? (
                <RegisterOptions
                  handleRegisterTeams={handleClickRegisterTeams}
                  handleRegisterIndividual={handleClickRegisterIndividual}
                />
                )
              : (
                <RegisterForm teamId={teamId} userEmail={userEmail} handleBack={handleGoBack} type={registerType} />
                )}
        </Grid>

        <Grid item xs={12}>
          <RegisterFooter />
        </Grid>
        {showRegisterAlert && (
          <PageAlert text='A verification email was sent to the address provided.' />
        )}
      </>
    )
  }

  const RightSide = () => {
    return (
      <Grid item xs={12}>
        {isRegistered
          ? (
            <Grid container justify='center' alignItems='center' className={classes.imageGrid}>
              <Grid item xs={12}>
                <img src='img/verify-email.png' alt='' />
              </Grid>
            </Grid>
            )
          : (
            <RegisterCarousel type={registerType} />
            )}
      </Grid>
    )
  }

  return (
    <>
      <Grid className={classes.root} container spacing={0}>
        <Grid container item lg={7} xl={7} md={12} spacing={0}>
          <LeftSide />
        </Grid>
        {!matches &&
          <Grid container item lg={5} xl={5} md={12} spacing={0}>
            <RightSide />
          </Grid>}

      </Grid>
    </>
  )
}

export default Register
