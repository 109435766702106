import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    height: '100%'
  },
  svgContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '100%',
    verticalAlign: 'middle',
    overflow: 'hidden'
  },
  svgContainerForPdf: {
    display: 'inline-block',
    position: 'relative',
    width: '1000px',
    height: '600px',
    verticalAlign: 'middle',
    overflow: 'hidden'
  }
}))
