import React from 'react'
import * as Survey from 'survey-react'
import { CustomRating } from '../../../../components/survey-rating'

const CustomRatingWidget = {
  name: 'customrating',
  title: 'Custom Rating',
  iconName: '',
  htmlTemplate: '<div></div>',
  widgetIsLoaded: function () {
    return true
  },
  isFit: function (question) {
    return question.getType() === 'customrating'
  },
  init: function (question, element) {},
  activatedByChanged: function (activatedBy) {
    // get properties from rating widget
    Survey.JsonObject.metaData.addClass('customrating', [], null, 'rating')
    Survey.JsonObject.metaData.addProperties('customrating', [
      { name: 'rateMinDescription', default: 'Strongly disagree' },
      { name: 'rateMaxDescription', default: 'Strongly agree' },
      { name: 'rateMax', default: 9 }
    ])
  },
  isDefaultRender: false,
  // eslint-disable-next-line react/display-name
  render: function (question) {
    return (
      <CustomRating
        onValueChanged={(data) => console.log(data)}
        defaultVal={question.value || null}
        rateMinDescription={question.rateMinDescription}
        rateMaxDescription={question.rateMaxDescription}
        rateMax={question.rateMax}
      />
    )
  },

  afterRender: function (question, element) {
    const buttons = Array.from(element.getElementsByTagName('button'))
    buttons.forEach(button => {
      button.onclick = (e) => {
        question.value = Number(e.target.innerText)
      }
    })
    const onValueChangedCallback = function () {

    }
    question.onValueChangedCallback = onValueChangedCallback
    onValueChangedCallback()
  }
}

export default CustomRatingWidget
