import React from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import { useStyles } from './style'

const LPCompletersRow = () => {
  const classes = useStyles()
  const industryData = [
    {
      name: 'Finance, banking and insurance',
      width: '22px',
      percentage: '10%'
    },
    {
      name: 'Health',
      width: '18px',
      percentage: '9%'
    },
    {
      name: 'IT and telecoms',
      width: '18px',
      percentage: '9%'
    },
    {
      name: 'Education (HE, FE)',
      width: '14px',
      percentage: '8%'
    },
    {
      name: 'Professional services, law and accountancy',
      width: '14px',
      percentage: '8%'
    }
  ]

  const regionData = [
    {
      name: 'UK',
      width: '122px',
      percentage: '43%',
      type: 'inner'
    },
    {
      name: 'Other European countries',
      width: '37px',
      percentage: '13%',
      type: 'inner'
    },
    {
      name: 'USA/Canada',
      width: '48px',
      percentage: '17%',
      type: 'inner'
    },
    {
      name: 'Australia/New Zealand',
      width: '37px',
      percentage: '13%',
      type: 'inner'
    },
    {
      name: 'Other Asia Pacific',
      width: '17px',
      percentage: '6%',
      type: 'outer'
    },
    {
      name: 'Middle East/Africa/India',
      width: '23px',
      percentage: '8%',
      type: 'outer'
    },
    {
      name: 'South/Central America',
      width: '3px',
      percentage: '1%',
      type: 'outer'
    }
  ]

  const sizeData = [
    {
      name: '10-249',
      width: '120px',
      percentage: '29%'
    },
    {
      name: '250-4,999',
      width: '199px',
      percentage: '48%'
    },
    {
      name: '5,000-20,000+',
      width: '96px',
      percentage: '23%'
    }
  ]

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.lightContainer}>
        <Grid item xs={12}>
          <Typography variant='h2' style={{ textAlign: 'center', marginBottom: 12, marginTop: 64 }}>Who’s completing the LPB?</Typography>
          <Typography style={{ fontSize: 20, textAlign: 'center', margin: '0 auto 64px', maxWidth: 650 }}>The LPB will help you no matter your industry, region or organisation size. In fact, here's a breakdown of last year's participants:</Typography>
        </Grid>
        <Grid item lg={4} md={6}>
          <Paper variant='outlined' className={classes.paper}>
            <Typography variant='h3' style={{ textAlign: 'center', marginBottom: 8 }}>Participants based on industry</Typography>
            <Typography style={{ textAlign: 'center', marginBottom: 18 }}> Our data is split into 20 industries. Here are our largest 5 samples:</Typography>
            {industryData.map((value, idx) => (
              <div key={idx} className={classes.completerResultContainer}>
                <Typography style={{ width: '75%' }}>{value.name}</Typography>
                <span style={{ marginLeft: 24, display: 'flex', width: '25%' }}>
                  <div style={{ background: '#FF8200', width: value.width, height: '22px' }} />
                  <Typography variant='subtitle2' style={{ marginLeft: 4 }}>{value.percentage}</Typography>
                </span>
              </div>
            ))}
          </Paper>
        </Grid>
        <Grid item lg={4} md={6}>
          <Paper variant='outlined' className={classes.paper}>
            <Typography variant='h3' style={{ textAlign: 'center', marginBottom: 18 }}>Participants based on region</Typography>
            {regionData.map((value, idx) => {
              return value.type === 'inner'
                ? (
                  <div key={idx} className={classes.completerResultContainer}>
                    <Typography style={{ width: '60%' }}>{value.name}</Typography>
                    <span style={{ marginLeft: 24, display: 'flex', width: '40%' }}>
                      <div style={{ background: '#006FB7', width: value.width, height: '22px' }}>
                        <Typography variant='subtitle2' style={{ marginLeft: 4, color: 'white' }}>{value.percentage}</Typography>
                      </div>
                    </span>
                  </div>
                  )
                : (
                  <div key={idx} className={classes.completerResultContainer}>
                    <Typography style={{ width: '60%' }}>{value.name}</Typography>
                    <span style={{ marginLeft: 24, display: 'flex', width: '40%' }}>
                      <div style={{ background: '#006FB7', width: value.width, height: '22px' }} />
                      <Typography variant='subtitle2' style={{ marginLeft: 4 }}>{value.percentage}</Typography>
                    </span>
                  </div>
                  )
            })}
          </Paper>
        </Grid>
        <Grid item lg={4} md={6} style={{ margin: '0 auto' }}>
          <Paper variant='outlined' style={{ padding: '24px 24px 8px' }}>
            <Typography variant='h3' style={{ textAlign: 'center', marginBottom: 24 }}>Participants based on organisation size</Typography>
            {sizeData.map((value, idx) => (
              <div key={idx} className={classes.completerResultContainer}>
                <Typography style={{ width: '35%' }}>{value.name}</Typography>
                <span style={{ marginLeft: 24, display: 'flex', width: '65%' }}>
                  <div style={{ background: '#00A9E0', width: value.width, height: '22px' }}>
                    <Typography variant='subtitle2' style={{ marginLeft: 4, color: 'white' }}>{value.percentage}</Typography>
                  </div>
                </span>
              </div>
            ))}
          </Paper>
          <Typography style={{ marginTop: '48px' }}>Note: The percentages refer to the number of L&D leaders in each industry, region and organisation size based on our 2022 data.</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LPCompletersRow
