import React from 'react'
import {
  FormControl,
  TextField,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { MailtoLink } from '../../components'
import { useForm } from 'react-hook-form'
import { useStyles } from './style'
import { useSession } from '@emerald-works/react-auth'
import { useNavigate } from 'react-router-dom'
import Auth from '@aws-amplify/auth'
import { datadogRum } from '@datadog/browser-rum'

const AccountForm = ({
  openDialog,
  showUserAlert
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(false)
  const { user } = useSession()

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      name: user.attributes.name,
      email: user.attributes.email
    }
  })

  const currentName = watch('name')
  let usernameError = ''
  const onSubmit = async () => {
    setIsLoading(true)

    if (currentName.length && currentName !== user.attributes.name) {
      try {
        await Auth.updateUserAttributes(user, { name: currentName })
        showUserAlert(true)
        user.attributes.name = currentName
      } catch (e) {
        datadogRum.addError(e)
        console.log('Update user error: ', e)
        usernameError = e.message
      }
    }

    setIsLoading(false)
  }

  const handleCancel = () => {
    navigate('/')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <FormControl fullWidth>
        <TextField
          margin='normal'
          label='Full name'
          variant='outlined'
          name='name'
          type='text'
          helperText={usernameError.length ? usernameError : null}
          inputRef={register}
          inputProps={{ 'aria-label': 'Full name' }}
          data-test='nameField'
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          disabled
          margin='normal'
          label='Organisation Email'
          variant='outlined'
          name='email'
          type='email'
          inputRef={register}
          inputProps={{ 'aria-label': 'Organisational email' }}
          data-test='emailField'
        />
      </FormControl>
      <Typography variant='h3' className={classes.subTitle}>
        Password
      </Typography>
      <Typography
        onClick={openDialog}
        className={classes.link}
        data-test='btn-changePassword'
        tabindex={0}
      >
        Change password
      </Typography>
      <Typography variant='h3' className={classes.subTitle}>
        Delete your account
      </Typography>
      <Typography variant='body1'>
        If you would like to permanently delete your Learning Performance Benchmark account,{' '}
        no problem—just email us at{' '}
        <MailtoLink data-test='supportEmail' email='lpbenchmark@mindtools.com'>
          lpbenchmark@mindtools.com
        </MailtoLink>
        .
      </Typography>
      <Typography className={classes.subDescription} variant='body1'>
        By deleting your account, you will no longer be able to access your{' '}
        results and your longitudinal data will be lost.
      </Typography>
      <div>
        <Button
          className={classes.secondaryButton}
          onClick={handleCancel}
          variant='outlined'
          data-test='btn-cancel-accountPage'
        >
          Cancel
        </Button>
        <Button
          disabled={currentName === user.attributes.name}
          startIcon={
            isLoading ? <CircularProgress size={14} color='inherit' /> : null
          }
          className={classes.primaryButton}
          type='submit'
          variant='contained'
          data-test='btn-save'
        >
          Save
        </Button>
      </div>
    </form>
  )
}

export default AccountForm
