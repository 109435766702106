import React from 'react'
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useStyles } from './style'

const BillingHistoryTable = ({ invoices, handleInvoiceClick, isDownloading }) => {
  const classes = useStyles()
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const parseInvoiceDate = date => {
    const invoiceMonth = new Date(date).getMonth()
    const invoiceYear = new Date(date).getFullYear()

    return `${months[invoiceMonth]} ${invoiceYear}`
  }

  const parseDate = date => {
    const invoiceDay = date.split('-')[2].split('T')[0]
    const invoiceMonth = date.split('-')[1]
    const invoiceYear = date.split('-')[0]

    return `${invoiceDay}/${invoiceMonth}/${invoiceYear}`
  }

  return (
    <TableContainer>
      <Table aria-label='Billing history'>
        <TableHead>
          <TableRow>
            <TableCell align='left'>Date</TableCell>
            <TableCell align='left'>Invoice</TableCell>
            <TableCell align='left'>Status</TableCell>
            <TableCell align='left'>Amount</TableCell>
            <TableCell align='left'>Plan</TableCell>
            <TableCell align='left'>
              {
                isDownloading
                  ? <CircularProgress size={16} color='secondary' />
                  : 'Download'
              }
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice, idx) => (
            <TableRow key={idx}>
              <TableCell align='left'>{parseDate(invoice.date)}</TableCell>
              <TableCell align='left'>{parseInvoiceDate(invoice.date)}</TableCell>
              <TableCell style={{ textTransform: 'capitalize' }} align='left'>{invoice.status}</TableCell>
              <TableCell align='left'>£3,500</TableCell>
              <TableCell align='left'>Teams</TableCell>
              <TableCell onClick={() => { handleInvoiceClick(invoice.id) }} className={classes.tableDownload} align='left'>Invoice {parseInvoiceDate(invoice.date)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BillingHistoryTable
