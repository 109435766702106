import React from 'react'
import { Typography } from '@material-ui/core'
import { AccountForm } from '../../components'
import { useStyles } from './style'

const AccountGeneralTabComponent = ({ handleDialogOpen, setShowUserAlert }) => {
  const classes = useStyles()

  return (
    <span>
      <Typography variant='h2' className={classes.description}>
        Personal details
      </Typography>
      <AccountForm
        openDialog={handleDialogOpen}
        showUserAlert={setShowUserAlert}
      />
    </span>
  )
}

export default AccountGeneralTabComponent
