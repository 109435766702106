import React from 'react'
import { Page, View, Image, Text } from '@react-pdf/renderer'
import styles from './style'

const CoverPage = ({ currentSeason }) => (
  <Page size='A4' style={styles.page} orientation='landscape'>
    <View style={styles.full}>
      <Image src='https://dev-lpb-report.s3.eu-west-2.amazonaws.com/background.png' style={styles.pageBackground} />
      <Image src='https://dev-lpb-report.s3.eu-west-2.amazonaws.com/LPB-logo.png' style={styles.logo} />
      <View style={styles.textContainer}>
        <Text style={styles.title}>{`Your ${currentSeason} \n Score`}</Text>
        <Text style={styles.subtitle}>Learning Performance Benchmark</Text>
      </View>

    </View>
  </Page>
)

export default CoverPage
