import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { useStyles } from './style'

const DashboardMaturityArea = ({ stage, stageInfo }) => {
  const classes = useStyles()
  const { title, descriptions, tips, imgLink } = stageInfo
  const tip = tips[stage - 1]
  const description = descriptions[stage - 1]

  return (
    <div className={classes.root}>
      <Accordion classes={{ root: classes.accordionRoot }}>
        <AccordionSummary
          classes={{ content: classes.accordionContent }}
          expandIcon={<ExpandMoreIcon />}
          data-test={`btn-${title
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/&/g, '')}`}
        >
          <div className={classes.imgBox}>
            <img
              src={imgLink}
              alt=''
              width='100%'
              height='100%'
            />
          </div>
          <Typography className={classes.titleText} variant='h3'>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body1' className={classes.description}>
            {description}
          </Typography>
          <div className={classes.description}>
            <Typography
              variant='subtitle1'
              data-test={`progressToStage-${title
                .toLowerCase()
                .replace(/ /g, '-')
                .replace(/&/g, '')}`}
            >
              {stage < 4 ? `How to progress to stage ${stage + 1}:` : 'Where to focus next:'}
            </Typography>
            <Typography variant='body1'>{tip}</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default DashboardMaturityArea
