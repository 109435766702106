import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  slider: sliderStyle,
  sliderInput: sliderInputStyle,
  sliderContainer: containerStyle

}))

const containerStyle = {
  display: 'flex',
  overflow: 'none',
  flexDirection: 'row',
  maxWidth: '100%',
  minHeight: '70px',
  fontFamily: 'Matter',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.4px',
  boxSizing: 'border-box'

}

const sliderStyle = {
  '& .MuiSlider-rail': {
    opacity: 1,
    height: 16,
    boxShadow: 'inset 0px 2px 2px #D2D1D6',
    color: 'rgba(37, 55, 70, 0.12)',
    borderRadius: '10px'
  },
  '& .MuiSlider-track': {
    color: '#B1C9E8',
    height: 17,
    boxShadow: 'none',
    borderRadius: '10px'
  },
  '& .MuiSlider-mark': {
    display: 'none'
  },
  '& .MuiSlider-markLabel': {
    marginTop: '8px'
  },
  '& .MuiSlider-thumb': {
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    marginLeft: '-12px',
    backgroundColor: '#006FB7',
    '&:focus-visible': {
      boxShadow: '0 0 0 8px rgba(0, 111, 183, 0.16)'
    },
    '&:hover': {
      boxShadow: 'none'
    },
    '&:before': {
      display: 'none'
    }
  }
}

const sliderInputStyle = {
  border: '1px solid rgba(37, 55, 70, 0.8)',
  boxSizing: 'border-box',
  borderRadius: '4px',
  width: '60px',
  height: '40px',
  marginRight: '25px',
  marginTop: '2px',
  justifyContent: 'center',
  justifyItems: 'center'
}
