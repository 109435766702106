import React from 'react'
import { useStyles } from './style'
import Info from '@material-ui/icons/Info'

const SurveyBoxAlert = ({ text }) => {
  const { root, leftSide, rightSide, textStyle } = useStyles()

  return (
    <div className={root}>
      <span className={leftSide}>
        <Info />
      </span>
      <span className={rightSide}>
        <p className={textStyle}>{text}</p>
      </span>
    </div>
  )
}

export default SurveyBoxAlert
