import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    backgroundColor: '#EEEFF1',
    height: '100%'
  },
  carouselStep: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  stepTitle: {
    textAlign: 'center',
    maxWidth: '60%'
  }
}))
