import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: '14px',
    maxWidth: '480px',
    padding: '8px'
  },
  arrow: {
    left: '5px !important',
    color: '#253746'
  },
  text: {
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  }
}))
