import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './style'

const LPFirstRow = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleGetStarted = () => {
    navigate('/register')
  }

  return (
    <Grid item xs={12}>
      <Grid container spaing={3} className={classes.container}>
        <Grid item md={6}>
          <div className={classes.gapsContainer}>
            <Typography variant='h1' className={classes.heading1}>Uncover gaps, identify what to prioritise, and discover next steps</Typography>
            <Typography className={classes.introText}>The LPB is an independent and confidential comparison tool empowering L&D professionals with the ability to benchmark against similar organisations and improve their learning impact.</Typography>
            <Button disableElevation variant='contained' color='secondary' style={{ width: 'fit-content', marginTop: 24 }} onClick={handleGetStarted}>Get started</Button>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className={classes.gapsImg}>
            <img src='/img/lp-gaps.png' alt='Dashboard gaps example' className={classes.image} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' className={classes.gapsFooter}>Since 2003, over 10,000 organisations worldwide have improved their L&D strategy after having used the LPB</Typography>
          <div className={classes.logosRow}>
            <img src='/img/lp-logos-1.png' alt='Help for heroes logo' />
            <img src='/img/lp-logos-2.png' alt='Help for heroes logo' />
            <img src='/img/lp-logos-3.png' alt='Help for heroes logo' />
            <img src='/img/lp-logos-4.png' alt='Help for heroes logo' />
            <img src='/img/lp-logos-5.png' alt='Help for heroes logo' />
            <img src='/img/lp-logos-6.png' alt='Help for heroes logo' />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LPFirstRow
