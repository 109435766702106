import React, { useEffect } from 'react'
import { PageAlert } from '../../components'
import { useConnectionContext } from '@emerald-works/react-event-bus-client'
import constants from '@emerald-works/constants'

const ConnectionAlert = () => {
  const context = useConnectionContext()
  const [isClosed, setIsClosed] = React.useState(false)
  const status = context?.connectionStatus

  useEffect(() => {
    console.log('check connection status', status)
    if (status === constants.CONNECTION_SIGNAL_STATUS_CLOSED) {
      console.log('setClosed')
      // setIsClosed(true)
    }
    if (status === constants.CONNECTION_SIGNAL_STATUS_GREEN) {
      console.log('setNotClosed')
      setIsClosed(false)
    }
  }, [status])

  useEffect(() => {
    if (isClosed) {
      setTimeout(() => {
        context.reconnect()
      }, 3000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosed])

  return isClosed && (
    <PageAlert text='Reconnect' error />
  )
}

export default ConnectionAlert
