const IFrameControl = () => {
  const hotjarIFrame = document.getElementById('_hjRemoteVarsFrame')
  if (hotjarIFrame) {
    hotjarIFrame.setAttribute('aria-hidden', 'true')
  }
  const feedbackContainer = document.getElementsByClassName('_hj_feedback_container')
  if (feedbackContainer[0]) {
    feedbackContainer[0].setAttribute('aria-hidden', 'true')
  }
  return null
}

export default IFrameControl
