import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  root: {
    background: '#EEEFF1',
    height: '100vh'
  },
  collapseBtnHide: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '1px',
    borderRadius: '50%',
    padding: '2px',
    backgroundColor: '#006fb7',
    '&:hover': {
      backgroundColor: '#024e95'
    },
    transition: 'left 0.25s ease-in-out'
  },
  collapseBtn: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '282px',
    backgroundColor: '#006fb7',
    borderRadius: '50%',
    padding: '2px',
    '&:hover': {
      backgroundColor: '#024e95'
    },
    transition: 'left 0.25s ease-in-out'
  },
  collapseIcon: {
    '&.MuiSvgIcon-root': {
      fill: 'white'
    }
  },
  rootLoading: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadFooter: {
    position: 'absolute',
    bottom: '16px'
  },
  main: {
    transition: 'margin-left 0.25s ease-in-out',
    padding: '48px',
    marginTop: '56px',
    background: '#EEEFF1',
    marginLeft: '296px'
  },
  mainMD: {
    padding: '24px 48px',
    transition: 'margin-left 0.25s ease-in-out',
    marginTop: '64px',
    background: '#EEEFF1',
    marginLeft: 0
  },
  mainXL: {
    marginLeft: '72px'
  },
  img: {
    width: '414px',
    '@media (min-width: 1200px) and (max-width: 1600px)': {
      marginTop: 'calc(2715px/13 - 500vw /39)'
    }
  },
  imgHover: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '90%',
    height: 'auto',
    cursor: 'pointer'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  btnContainer: {
    margin: '40px 0'
  },
  introText: {
    fontSize: 20,
    width: '75%'
  },
  button: {
    marginRight: '16px',
    backgroundColor: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s'
    }
  },
  secondaryButton: {
    color: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s',
      color: '#FFFFFF'
    }
  },
  homePaper: {
    padding: '16px 24px',
    minHeight: '400px'
  },
  homePaperImage: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '80%',
    height: 'auto',
    cursor: 'pointer'
  },
  homePaperContent: {
  },
  footer: {
    paddingTop: '1.25rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    textTransform: 'uppercase'
  },
  footerButton: {
    cursor: 'pointer'
  }
}))
