import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, Paper, Typography, Button } from '@material-ui/core'

import { useStyles } from './style'
import { userSlice } from '../../../reducers'

const TeamsCardNotify = ({ teamsInfo }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { teamAdmin, teamResults, hasTeam, teamName } = teamsInfo

  const cardSubTitle = `You’re a member of team ${teamName}.`
  const cardDescription = `Your team admin needs to close the review before you can see your team's aggregated results. 
  Once they're available, you'll be able to click through to see how aligned your team are, and what areas need working on.`

  const handleClick = () => {
    if (teamResults) {
      dispatch(userSlice.actions.setActiveMenuSection('teams'))
      dispatch(userSlice.actions.setMenuLevel(1))
      navigate('/team-dashboard')
      return
    }

    if (!hasTeam) {
      dispatch(userSlice.actions.setActiveMenuSection('teams'))
      dispatch(userSlice.actions.setMenuLevel(0))
      navigate('/team-create')
      return
    }

    if (teamAdmin) {
      dispatch(userSlice.actions.setActiveMenuSection('teams'))
      dispatch(userSlice.actions.setMenuLevel(1))
      navigate('/teams')
    }
  }

  return (
    <Paper className={classes.homePaper}>
      <Typography variant='h2' className={classes.cardTitle}>
        Your team
      </Typography>
      <Grid container className={classes.homePaperContent}>
        <img className={classes.cardIcon} src='/img/team-icon-star.png' alt='Star' />
        <Grid item xs={7} xl={8} className={classes.cardSubtitle}>
          <Typography>
            {cardSubTitle}
          </Typography>
        </Grid>
        <Grid item xs={7} xl={8} className={classes.cardDescription}>
          <Typography>
            {cardDescription}
          </Typography>
        </Grid>
        <Grid item xs={5} xl={4}>
          <img className={classes.imgHover} src='/img/team-homepage-2.png' alt='Grahps' />
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <Button
          disabled={!teamResults}
          className={classes.footerButton}
          data-test='lnk-teamsCard'
          variant='text'
          onClick={handleClick}
        >
          View your team results
        </Button>
      </div>
    </Paper>
  )
}

export default TeamsCardNotify
