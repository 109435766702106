import {
  scaleLinear,
  select,
  line,
  axisBottom,
  axisLeft
} from 'd3'

const getYears = (currentYear) => {
  const yearLength = currentYear - 2018
  const years = [2018]

  for (let i = 0; i < yearLength; i++) {
    years.push(2018 + i + 1)
  }

  years.sort((a, b) => (a - b))
  return years
}

const getYearScale = years => {
  return years[years.length - 1] + 0.10
}

export const drawChart = (data, screenSize, ref, forPdf) => {
  const margin = 40

  let chartWidth = 450 - 2 * margin
  const chartHeight = 400

  switch (screenSize) {
    case 'xs':
      chartWidth = 700
      break
    case 'md':
      chartWidth = 700
      break
    case 'lg':
      chartWidth = 950
      break
    default:
      break
  }

  const currentYear = data[0].values[0].date
  const years = getYears(currentYear)
  const currentYearScale = getYearScale(years)

  const xScale = scaleLinear([2017.90, currentYearScale], [0, chartWidth])
  const yScale = scaleLinear([0, 100], [chartHeight, 0])

  const chart = select(ref.current)
    .html('')
    .append('svg')
    .attr('class', 'lineChartSvgContent')
    .attr('viewBox', `0 0 ${chartWidth + margin} ${chartHeight + margin * 2}`)
    .attr('aria-label', 'line chart')
    .attr('align', 'center')
    .append('g')
    .attr('transform', `translate(${margin}, ${margin})`)

  const tooltip = select('body')
    .append('div')
    .attr('class', 'tooltip')

  const chartLine = line()
    .x(d => xScale(d.date))
    .y(d => yScale(d.score))

  const xAxis = axisBottom(xScale)
    .tickValues(years.map(d => d))
    .tickFormat(d => d.toString())
    .tickSizeOuter(0)

  chart
    .append('g')
    .attr('transform', `translate(0, ${chartHeight})`)
    .attr('class', forPdf ? 'x-axis-pdf' : 'x-axis')
    .call(xAxis)

  const yAxis = axisLeft(yScale)
    .tickValues([20, 40, 60, 80, 100])
    .tickSizeOuter(0)

  chart
    .append('g')
    .attr('transform', 'translate(0, 0)')
    .attr('class', forPdf ? 'y-axis-pdf' : 'y-axis')
    .call(yAxis)

  const lineGroup = chart.append('g').attr('class', 'line-group')
  lineGroup
    .selectAll('.line')
    .data(data)
    .enter()
    .append('g')
    .append('path')
    .attr('class', 'line')
    .attr('d', d => chartLine(d.values))
    .attr('stroke', d => d.color)
    .attr('stroke-width', 2)
    .attr('fill', 'none')

  const scatter = lineGroup
    .selectAll('.scatter')
    .data(data)
    .enter()
    .append('g')
    .style('fill', d => d.color)
    .selectAll('circle')
    .data(d => d.values)
    .enter()
    .append('g')
    .append('circle')
    .attr('class', 'scatter')
    .attr('cx', d => xScale(d.date))
    .attr('cy', d => yScale(d.score))
    .attr('r', 4)

  const showTooltip = (e, d) => {
    return tooltip
      .style('left', `${e.pageX + 10}px`)
      .style('top', `${e.pageY}px`)
      .style('display', 'inline-block')
      .text(d.score.toFixed(2))
  }

  const hideTooltip = () => {
    return tooltip.style('display', 'none')
  }

  scatter.on('mousemove', showTooltip)
  scatter.on('mouseout', hideTooltip)
}

export const drawComponentsChart = (data, screenSize, ref, forPdf) => {
  const margin = 40

  let chartWidth = 450 - 2 * margin
  const chartHeight = 400

  switch (screenSize) {
    case 'xs':
      chartWidth = 700
      break
    case 'md':
      chartWidth = 630
      break
    case 'lg':
      chartWidth = 700
      break
    default:
      break
  }

  const currentYear = data[0].values[0].date
  const years = getYears(currentYear)
  const currentYearScale = getYearScale(years)

  const xScale = scaleLinear([2017.90, currentYearScale], [0, chartWidth])
  const yScale = scaleLinear([0, 9], [chartHeight, 0])

  const chart = select(ref.current)
    .html('')
    .append('svg')
    .attr('viewBox', `0 0 ${chartWidth + 2 * margin} ${chartHeight + margin * 2}`)
    .attr('align', 'center')
    .attr('class', 'lineChartSvgContent')
    .attr('aria-label', 'line chart for key components')
    .append('g')
    .attr('transform', `translate(${margin}, ${margin})`)

  const tooltip = select('body')
    .append('div')
    .attr('class', 'tooltip')

  const chartLine = line()
    .x(d => xScale(d.date))
    .y(d => yScale(d.score))

  const xAxis = axisBottom(xScale)
    .tickValues(years.map(d => d))
    .tickFormat(d => d.toString())
    .tickSizeOuter(0)

  chart
    .append('g')
    .attr('class', forPdf ? 'x-axis-pdf' : 'x-axis')
    .attr('transform', `translate(0, ${chartHeight})`)
    .call(xAxis)

  const yAxis = axisLeft(yScale)
    .tickValues([1, 2, 3, 4, 5, 6, 7, 8, 9])
    .tickSizeOuter(0)

  chart
    .append('g')
    .attr('class', forPdf ? 'y-axis-pdf' : 'y-axis')
    .attr('transform', 'translate(0, 0)')
    .call(yAxis)

  const lineGroup = chart.append('g').attr('class', 'line-group')
  lineGroup
    .selectAll('.line')
    .data(data)
    .enter()
    .append('g')
    .append('path')
    .attr('class', 'line')
    .attr('d', d => chartLine(d.values))
    .attr('stroke', d => d.color)
    .attr('stroke-width', 2)
    .attr('fill', 'none')

  const scatter = lineGroup
    .selectAll('.scatter')
    .data(data)
    .enter()
    .append('g')
    .style('fill', d => d.color)
    .selectAll('circle')
    .data(d => d.values)
    .enter()
    .append('g')
    .append('circle')
    .attr('class', 'scatter')
    .attr('cx', d => xScale(d.date))
    .attr('cy', d => yScale(d.score))
    .attr('r', 4)

  const showTooltipComponent = (e, d) => {
    return tooltip
      .style('left', `${e.pageX + 10}px`)
      .style('top', `${e.pageY}px`)
      .style('display', 'inline-block')
      .text(d.score.toFixed(2))
  }

  const hideTooltipComponent = () => {
    return tooltip.style('display', 'none')
  }

  scatter.on('mousemove', showTooltipComponent)
  scatter.on('mouseout', hideTooltipComponent)
}

export const drawStageChart = (data, screenSize, ref, forPdf) => {
  const margin = 40

  let chartWidth = 450 - 2 * margin
  const chartHeight = 400

  switch (screenSize) {
    case 'xs':
      chartWidth = 700
      break
    case 'md':
      chartWidth = 650
      break
    case 'lg':
      chartWidth = 800
      break
    default:
      break
  }
  const currentYear = data[0].values[0].date
  const years = getYears(currentYear)
  const currentYearScale = getYearScale(years)

  const xScale = scaleLinear([2017.70, currentYearScale], [0, chartWidth])
  const yScale = scaleLinear([0, 100], [chartHeight, 0])

  const chart = select(ref.current)
    .html('')
    .append('svg')
    .attr('viewBox', `0 0 ${chartWidth} ${chartHeight + margin * 2}`)
    .attr('align', 'center')
    .attr('class', 'lineChartSvgContent')
    .attr('aria-label', 'line chart with maturity stages')
    .append('g')
    .attr('transform', `translate(0, ${margin})`)

  const tooltip = select('body')
    .append('div')
    .attr('class', 'tooltip')

  const chartLine = line()
    .x(d => xScale(d.date))
    .y(d => yScale(d.score))

  const xAxis = axisBottom(xScale)
    .tickValues(years.map(d => d))
    .tickFormat(d => d.toString())
    .tickSizeOuter(0)

  chart
    .append('g')
    .attr('class', forPdf ? 'x-axis-pdf' : 'x-axis')
    .attr('transform', `translate(0, ${chartHeight})`)
    .call(xAxis)

  const yAxis = axisLeft(yScale)
    .tickValues([25, 56, 68, 87])
    .tickFormat(t => {
      if (t <= 49) return 'Stage 1'
      else if (t >= 50 && t <= 62) return 'Stage 2'
      else if (t >= 63 && t <= 72) return 'Stage 3'
      else return 'Stage 4'
    })
    .tickSize(0)
    .tickSizeOuter(0)

  const secondaryAxis = axisLeft(yScale)
    .tickValues([50, 63, 73])
    .tickSize(-chartWidth)
    .tickSizeOuter(0)

  chart
    .append('g')
    .attr('class', forPdf ? 'y-axis-stage-pdf' : 'y-axis-stage')
    .attr('transform', 'translate(50, 0)')
    .call(yAxis)

  chart
    .append('g')
    .attr('class', forPdf ? 'y-axis-secondary-pdf' : 'y-axis-secondary')
    .attr('transform', 'translate(15, 0)')
    .attr('stroke-dasharray', (4, 2))
    .call(secondaryAxis)

  const lineGroup = chart.append('g').attr('class', 'line-group')
  lineGroup
    .selectAll('.line')
    .data(data)
    .enter()
    .append('g')
    .append('path')
    .attr('class', 'line')
    .attr('d', d => chartLine(d.values))
    .attr('stroke', d => d.color)
    .attr('stroke-width', 2)
    .attr('fill', 'none')

  const scatter = lineGroup
    .selectAll('.scatter')
    .data(data)
    .enter()
    .append('g')
    .style('fill', d => d.color)
    .selectAll('circle')
    .data(d => d.values)
    .enter()
    .append('g')
    .append('circle')
    .attr('class', 'scatter')
    .attr('cx', d => xScale(d.date))
    .attr('cy', d => yScale(d.score))
    .attr('r', 4)

  const showTooltipStage = (e, d) => {
    return tooltip
      .style('left', `${e.pageX + 10}px`)
      .style('top', `${e.pageY}px`)
      .style('display', 'inline-block')
      .text(d.score.toFixed(2))
  }

  const hideTooltipStage = () => {
    return tooltip.style('display', 'none')
  }

  scatter.on('mousemove', showTooltipStage)
  scatter.on('mouseout', hideTooltipStage)
}
