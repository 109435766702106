import React from 'react'
import { Page, View, Text, Image } from '@react-pdf/renderer'
import { PDFFooter } from '../../components'
import globalStyles from '../../style'

const TrendsPage2 = () => {
  const challengesDataURL = localStorage.getItem('challengesTrendsData')
  const skillsDataURL = localStorage.getItem('skillsTrendsData')

  return (
    <Page size='A4' orientation='landscape'>
      <View style={[globalStyles.pageView, { display: 'flex', flexDirection: 'column' }]}>
        <View style={{ display: 'flex', flexDirection: 'row', height: '45%', width: '100%', borderBottom: '1px solid rgb(200,200,200)' }}>
          <View style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
            <Text style={globalStyles.subheading}>Challenges</Text>
            <Text style={[globalStyles.body, { marginRight: 10 }]}>Every organisation faces obstacles to getting the most out of their L&D strategy. Here’s a list of the most common challenges:</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
            {!challengesDataURL ? <Text>table placeholder</Text> : <Image src={challengesDataURL} style={{ objectFit: 'contain', marginTop: 5 }} />}
          </View>
        </View>
        {/* row 2 */}
        <View style={{ display: 'flex', flexDirection: 'row', height: '45%', width: '100%', marignTop: 10 }}>
          <View style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
            <Text style={globalStyles.subheading}>In-house skill sets</Text>
            <Text style={[globalStyles.body, { marginRight: 10 }]}>Here are the top L&D in-house capabilities present in organisations. Note that this does not mean these are the most effective capabilities, but simply highlights what expertise the average L&D team has in-house.</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
            {!skillsDataURL ? <Text>table placeholder</Text> : <Image src={skillsDataURL} style={{ objectFit: 'contain', marginTop: 5 }} />}
          </View>
        </View>
        <PDFFooter pageNumber={16} />
      </View>
    </Page>
  )
}

export default TrendsPage2
