
import { createTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { createPalette } from './palette'
import { createTypography } from './typography'

const createTheme = (options = {}) => {
  const {
    palette: paletteInput = {},
    typography: typographyInput = {},
    ...others
  } = options

  const palette = createPalette(paletteInput)
  const typography = createTypography(typographyInput)

  const theme = createMuiTheme({
    palette,
    typography,
    ...others,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    },
    overrides: {
      MuiButton: {
        root: {
          fontSize: 14
        },
        contained: {
          color: '#FFF',
          fontWeight: 'bold',
          fontSize: 14
        },
        outlined: {
          fontWeight: 'bold',
          border: '2px solid !important'
        }
      },
      MuiOutlinedInput: {
        notchedOutline: {
          '&:focus': {
            borderColor: '#0F0 !important'
          }
        }
      },
      MuiMobileStepper: {
        dotActive: {
          backgroundColor: palette.secondary.main
        }
      },
      MuiDialogContent: {
        root: {
          padding: 0,
          marginBottom: 24
        }
      },
      MuiDialogActions: {
        root: {
          padding: 0
        }
      },
      MuiDialogContentText: {
        root: {
          color: '#343337'
        }
      },
      MuiDialog: {
        paper: {
          padding: 24
        }
      },
      MuiDialogTitle: {
        root: {
          padding: '1rem 0'
        }
      },
      MuiStepIcon: {
        root: {
          color: '#e0e0e0',
          '&.Mui-error': {
            color: '#e0e0e0'
          },
          '&.Mui-completed': {
            color: palette.primary.main
          }
        },
        active: {
          color: `${palette.secondary.main} !important`
        },
        text: {
          fill: '#fff'
        }
      },
      MuiTabs: {
        indicator: {
          height: '4px'
        }
      },
      MuiTab: {
        wrapped: {
          fontSize: '14px',
          fontWeight: 400,
          textTransform: 'none',
          '&.Mui-selected': {
            fontWeight: '700 !important',
            color: palette.secondary.main
          }
        }
      },
      MuiTableCell: {
        root: {
          fontSize: '16px'
        },
        head: {
          fontWeight: 700
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#253746'
        }
      },
      MuiFormHelperText: {
        root: {
          color: '#253746'
        }
      },
      MuiChip: {
        root: {
          color: 'rgba(37, 55, 70, 0.38)'
        }
      }
    }
  })

  // make font-size of Typography elements responsive:
  // https://material-ui.com/customization/typography/#responsive-font-sizes
  return responsiveFontSizes(theme)
}

export { createTheme }
