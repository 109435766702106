import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  homePaper: {
    padding: '16px 24px',
    minHeight: '380px'
  },
  homePaperContent: {
    minHeight: '230px'
  },
  footer: {
    paddingTop: '1.25rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    textTransform: 'uppercase'
  },
  footerButton: {
    cursor: 'pointer'
  },
  chartContainer: {
    minHeight: '200px',
    '@media (max-width: 1200px)': {
      minHeight: '300px'
    }
  },
  cardTitle: {
    marginTop: 0
  }
}))
