import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { MailtoLink } from '../../components'
import { useStyles } from './style'

const LPFooter = () => {
  const classes = useStyles()
  const twitterLink = 'https://twitter.com/MTInsights'

  const handleTwitter = () => {
    window.open(twitterLink)
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.footerContainer}>
        <Grid item xs={6}>
          <Typography>
            © 2023 Mind Tools Ltd. All rights reserved. Having trouble? Email us at{' '}<MailtoLink data-test='supportEmail' email='lpbenchmark@mindtools.com'>lpbenchmark@mindtools.com</MailtoLink>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.footerRight}>
            <div className={classes.footerSocial}>
              <Typography>Follow us:</Typography>
              <span style={{ cursor: 'pointer' }} onClick={handleTwitter}>
                <img style={{ marginLeft: 8 }} src='/img/social.png' alt='twitter' />
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LPFooter
