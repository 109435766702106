const finalSection = {
  name: 'Finishing Page Reflection',
  elements: [
    {
      type: 'html',
      name: 'Goal_reflect_intro',
      html: '<b>1. At the beginning, you said the goal you would like to achieve by completing the Learning Performance Benchmark is:</b><br><br>"{Goal}".',
      hideNumber: true
    },
    {
      type: 'radiogroup',
      hideNumber: true,
      name: 'Goal_reflect',
      title: 'Have you achieved this goal ?',
      choices: [
        'Yes',
        'No'
      ]
    },
    {
      type: 'comment',
      name: 'Goal_reflect_reason',
      hideNumber: true,
      title: '1a. Why not?',
      visibleIf: '{Goal_reflect} = "No"'
    },
    {
      type: 'radiogroup',
      name: 'Usefulness',
      title: '2. Has the Learning Performance Benchmark helped you reflect on your organisation`s L&D strategy?',
      hideNumber: true,
      choices: [
        {
          value: 'item1',
          text: 'Yes'
        },
        {
          value: 'item2',
          text: 'No'
        }
      ]
    },
    {
      type: 'comment',
      name: 'Feedback',
      title: '3. The Learning Performance Benchmark is constantly evolving and adapting. Do you have any hints, tips or lessons for us or future participants?',
      hideNumber: true
    }
  ]
}

export default finalSection
