// This dictionary contains 4 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { surveyLocalization } from "survey-core";

export var malaySurveyStrings = {
  pagePrevText: "Sebelumnya",
  pageNextText: "Seterusnya",
  completeText: "Selesai",
  previewText: "Pratonton",
  editText: "Edit",
  startSurveyText: "Mula",
  otherItemText: "Lain (terangkan)",
  noneItemText: "Tiada",
  selectAllItemText: "Pilih Semua",
  progressText: "Halaman {0} daripada {1}",
  // indexText: "{0} of {1}",
  panelDynamicProgressText: "Rekod {0} daripada {1}",
  questionsProgressText: "{0}/{1} soalan telah dijawab",
  emptySurvey: "Tiada halaman atau soalan boleh dilihat dalam tinjauan.",
  completingSurvey: "Terima kasih kerana melengkapkan tinjauan!",
  completingSurveyBefore: "Rekod kami menunjukkan yang anda telah melengkapkan tinjauan ini.",
  loadingSurvey: "Memuatkan Tinjauan...",
  placeholder: "Pilih...",
  // ratingOptionsCaption: "Tap to rate here...",
  value: "nilai",
  requiredError: "Respons diperlukan.",
  requiredErrorInPanel: "Respons diperlukan: jawab sekurang-kurangnya satu soalan.",
  requiredInAllRowsError: "Respons diperlukan: jawab soalan dalam semua baris.",
  numericError: "Nilai mestilah numerik.",
  minError: "Nilai tidak boleh kurang daripada {0}",
  maxError: "Nilai tidak boleh lebih besar daripada {0}",
  textMinLength: "Sila masukkan sekurang-kurangnya {0} aksara.",
  textMaxLength: "Sila masukkan tidak lebih daripada {0} aksara.",
  textMinMaxLength: "Sila masukkan sekurang-kurangnya {0} dan tidak lebih daripada {1} aksara.",
  minRowCountError: "Sila isikan sekurang-kurangnya {0} baris.",
  minSelectError: "Sila pilih sekurang-kurangnya {0} varian.",
  maxSelectError: "Sila pilih tidak lebih daripada {0} varian.",
  numericMinMax: "'{0}' mestilah sekurang-kurangnya {1} dan paling banyak {2}",
  numericMin: "'{0}' mestilah sekurang-kurangnya {1}",
  numericMax: "'{0}' mestilah paling banyak {1}",
  invalidEmail: "Sila masukkan alamat e-mel yang sah.",
  invalidExpression: "Ekspresi: {0} hendaklah mengembalikan nilai 'benar'.",
  urlRequestError: "Permintaan mengembalikan ralat '{0}'. {1}",
  urlGetChoicesError: "Permintaan mengembalikan data kosong atau ciri 'laluan' salah",
  exceedMaxSize: "Saiz fail hendaklah tidak melebihi {0}.",
  otherRequiredError: "Respons diperlukan: masukkan nilai lain.",
  uploadingFile: "Fail anda sedang dimuat naik. Sila tunggu beberapa saat dan cuba lagi.",
  loadingFile: "Memuat...",
  chooseFile: "Pilih fail...",
  noFileChosen: "Tiada fail dipilih",
  fileDragAreaPlaceholder: "Letakkan fail di sini atau klik butang di bawah untuk memuatkan fail.",
  confirmDelete: "Adakah anda ingin memadamkan rekod?",
  keyDuplicationError: "Nilai ini hendaklah unik.",
  addColumn: "Tambahkan lajur",
  addRow: "Tambahkan baris",
  removeRow: "Alih keluar",
  emptyRowsText: "Tiada baris.",
  addPanel: "Tambah baharu",
  removePanel: "Alih keluar",
  choices_Item: "item",
  matrix_column: "Lajur",
  matrix_row: "Baris",
  multipletext_itemname: "teks",
  savingData: "Keputusan sedang disimpan pada pelayan...",
  savingDataError: "Ralat berlaku dan kami tidak dapat menyimpan keputusan.",
  savingDataSuccess: "Keputusan berjaya disimpan!",
  saveAgainButton: "Cuba lagi",
  timerMin: "min",
  timerSec: "saat",
  timerSpentAll: "Anda telah meluangkan {0} pada halaman ini dan {1} secara keseluruhan.",
  timerSpentPage: "Anda telah meluangkan {0} pada halaman ini.",
  timerSpentSurvey: "Anda telah meluangkan {0} secara keseluruhan.",
  timerLimitAll: "Anda telah meluangkan {0} daripada {1} pada halaman ini dan {2} daripada {3} secara keseluruhan.",
  timerLimitPage: "Anda telah meluangkan {0} daripada {1} pada halaman ini.",
  timerLimitSurvey: "Anda telah meluangkan {0} daripada {1} secara keseluruhan.",
  clearCaption: "Kosongkan",
  signaturePlaceHolder: "Tandatangan di sini",
  chooseFileCaption: "Pilih fail",
  removeFileCaption: "Alih keluar fail ini",
  booleanCheckedLabel: "Ya",
  booleanUncheckedLabel: "Tidak",
  confirmRemoveFile: "Anda pasti ingin mengalih keluar fail ini: {0}?",
  confirmRemoveAllFiles: "Anda pasti ingin mengalih keluar semua fail?",
  questionTitlePatternText: "Tajuk Soalan",
  modalCancelButtonText: "Batal",
  modalApplyButtonText: "Guna",
  filterStringPlaceholder: "Taip untuk membuat carian...",
  // emptyMessage: "No data to display",
  noEntriesText: "Belum ada entri.\nKlik butang di bawah untuk menambahkan entri.",
  // more: "More"
};

surveyLocalization.locales["ms"] = malaySurveyStrings;
surveyLocalization.localeNames["ms"] = "melayu";
